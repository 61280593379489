import React, { useEffect, useState, useMemo } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import DownloadIcon from "@mui/icons-material/Download";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { SelectSimpleObject } from "../EquipmentList/Components";
import SelectSimpleInbuilt from "../../components/SelectSimpleInbuilt";
import DatePickerInbuild from "../../components/DatePickerInbuild";
import { PATHS, dateFormat } from "../../../constants";
import { ModalSelectColumnsServer } from "../../components/Modals/ModalSelectColumnsServer";
import CheckboxSimple from "../../components/CheckboxSimple";
import {
  useGetUserGridSettingQuery,
  useLazyGetUserGridSettingQuery,
} from "../../../middleware/usersApi";
import {
  useLazyGetActsTableQuery,
  useToSignActMutation,
  useToSignDefectActMutation,
} from "../../../middleware/documentationApi";
import { useAction } from "../../../hooks/actions";
import { useAppSelector } from "../../../hooks/redux";
import settingsicon from "../../../image/icons/settingsButtonWhite.png"
import FASDialog from "./FASDialog";
import TableBasic from "../../components/TableBasic";
import FormItem from "../../components/FormItem";
import { SearchInbuilt } from "../../components/SearchInbuilt";
import { DEFAULT_PARAMS, formFields } from "./form-config";

export const ClickableNameCell = ({ item }) => {
  const { name, action } = item || {};

  return (
    <Typography
      className="table-row-cell-link"
      onClick={action ? action : () => {}}
    >
      {name || ""}
    </Typography>
  );
};

export const SimpleTextCell = ({ item, type }) => {
  const content = type === "date" ? dayjs(item).format(dateFormat) : item;

  return <Typography>{content}</Typography>;
};

export const SignatureSignFieldSell = ({ item, type }) => {
  const { t } = useTranslation();
  const [
    toSignAct,
    {
      isLoading: isLoadingSignAct,
      isSuccess: isSuccessSignAct,
      error: errorSignAct,
    },
  ] = useToSignActMutation();
  const { setIsErrorSubmit, setIsSuccessSubmit } = useAction();
  const { isSigned, usersSignatures, actId, actType, ableToSign } = item || {};
  const error = errorSignAct;
  const isSuccess = isSuccessSignAct;
  const isLoading = isLoadingSignAct;
  const handleSignAct = () => {
    toSignAct({ actId, actType });
  };

  useEffect(() => {
    setIsErrorSubmit(error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    setIsSuccessSubmit(isSuccess);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);
  console.log("usersSignatures", actType, Array.isArray(usersSignatures));
  return (
    <Box className="signature_field">
      {!isSigned && ableToSign && Array.isArray(usersSignatures) && (
        <Button
          sx={{ padding: "0px" }}
          className="signature_field-btn"
          onClick={handleSignAct}
          disabled={isLoading}
        >
          {t("button:signing")}
          {isLoading && (
            <CircularProgress
              sx={{
                "&.MuiCircularProgress-root": {
                  color: "var(--main)",
                  marginLeft: "15px",
                },
              }}
              style={{ height: "15px", width: "15px" }}
            />
          )}
        </Button>
      )}
      {!!usersSignatures?.length && (
        <Box className="signature_field-content">
          <Typography className="signature_field-content-title">{`${t(
            "signature:document_signed"
          )}:`}</Typography>
          {usersSignatures.map((fio, i) => (
            <Typography key={i}>{fio}</Typography>
          ))}
        </Box>
      )}
    </Box>
  );
};
export const SignatureDefectSignFieldSell = ({ item, type }) => {
  const { t } = useTranslation();
  const [
    toSignDefectAct,
    {
      isLoading: isLoadingSignDefectAct,
      isSuccess: isSuccessSignDefectAct,
      error: errorSignDefectAct,
    },
  ] = useToSignDefectActMutation();
  const { setIsErrorSubmit, setIsSuccessSubmit } = useAction();
  const { isSigned, usersSignatures, actId, actType, ableToSign } = item || {};
  const error = errorSignDefectAct;
  const isSuccess = isSuccessSignDefectAct;
  const isLoading = isLoadingSignDefectAct;
  const handleSignAct = () => {
    toSignDefectAct({ actId, actType });
  };

  useEffect(() => {
    setIsErrorSubmit(error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    setIsSuccessSubmit(isSuccess);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);
  return (
    <Box className="signature_field">
      {!isSigned && ableToSign && Array.isArray(usersSignatures) && (
        <Button
          sx={{ padding: "0px" }}
          className="signature_field-btn"
          onClick={handleSignAct}
          disabled={isLoading}
        >
          {t("button:signing")}
          {isLoading && (
            <CircularProgress
              sx={{
                "&.MuiCircularProgress-root": {
                  color: "var(--main)",
                  marginLeft: "15px",
                },
              }}
              style={{ height: "15px", width: "15px" }}
            />
          )}
        </Button>
      )}
      {!!usersSignatures?.length && (
        <Box className="signature_field-content">
          <Typography className="signature_field-content-title">{`${t(
            "signature:document_signed"
          )}:`}</Typography>
          {usersSignatures.map((fio, i) => (
            <Typography key={i}>{fio}</Typography>
          ))}
        </Box>
      )}
    </Box>
  );
};
export const ListOfLinks = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [openFASDialog, setOpenFASDialog] = useState(false);

  const handleDialogState = () => setOpenFASDialog(!openFASDialog);

  return (
    <>
      <FASDialog open={openFASDialog} handleClose={handleDialogState} />
      <List className="acts-header-list">
        <ListItem disablePadding>
          <ListItemButton onClick={() => navigate(PATHS.CREATE_AFA_ACT)}>
            <ListItemText primary={t("button:create_afa_act")} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={() => navigate(PATHS.CREATE_SNME_ACT)}>
            <ListItemText primary={t("button:create_snme_act")} />
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton onClick={handleDialogState}>
            <ListItemText
              sx={{ whiteSpace: "nowrap" }}
              primary={t("button:create_common_control_act")}
            />
          </ListItemButton>
        </ListItem>
      </List>
    </>
  );
};

export const TableOfDocuments = () => {
  const { t } = useTranslation();

  const { control, setValue, getValues } = useForm({
    mode: "onChange",
    defaultValues: {
      [formFields.searchParams]: DEFAULT_PARAMS,
    },
  });
  const params = useWatch({
    control,
    name: formFields.searchParams,
  });

  const [pageNumber, changePageNumber] = useState(0);
  const [searchuserSign, setsearchuserSign] = useState("");
  const [searchuserdefectSign, setsearchuserdefectSign] = useState("");
  const [searchDefectActNumber, setsearchDefectActNumber] = useState("");
  const [openSelectModal, setOpenSelectModal] = useState(false);
  const { allActs } = useAppSelector((state) => state.documentation);
  const { setIsErrorRequest } = useAction();
  const {
    data: gridcolumns,
    error: errorgridColumns,
    isLoading: isLoadingerrorColumns,
  } = useGetUserGridSettingQuery({ gridSettingName: "DocumentList" });

  const [
    getUserGrid,
    { error: errorgrid, refetch: refetchUserGrid /* isLoading */ },
  ] = useLazyGetUserGridSettingQuery();
  const [getActsTable, { isLoading, error }] = useLazyGetActsTableQuery();

  const columnsFromStorage = (() => {
    const storageData = gridcolumns?.columnSettings || [];
    return storageData?.reduce(
      (acc, { name, visible }) => ({ ...acc, [name]: visible }),
      {}
    );
  })();
  useEffect(() => {
    const createdDate = getValues(
      `${formFields.searchParams}.CreationDateRange`
    );
    const facilId = getValues(`${formFields.searchParams}.FacilityId`);
    const act = getValues(`${formFields.searchParams}.ActType`);
    const ActType = act == "все" || !act ? { ActType: "" } : { ActType: act };
    const facility =
      facilId == "все" || !facilId
        ? { FacilityId: "" }
        : { FacilityId: facilId };
    delete params.FacilityId;
    delete params.ActType;
    delete params.CreationDateRange;
    let dd = {};
    if (createdDate) {
      if (createdDate.MaxValue != "")
        dd["CreationDateRange.MaxValue"] = createdDate.MaxValue;
      if (createdDate.MinValue != "")
        dd["CreationDateRange.MinValue"] = createdDate.MinValue;
    }
    changePageNumber(0);
    getActsTable({
      ...dd,
      ...ActType,
      ...params,
      ...facility,
      pageNumber: 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    const createdDate = getValues(
      `${formFields.searchParams}.CreationDateRange`
    );
    const facilId = getValues(`${formFields.searchParams}.FacilityId`);
    const act = getValues(`${formFields.searchParams}.ActType`);
    const ActType = act == "все" || !act ? { ActType: "" } : { ActType: act };
    const facility =
      facilId == "все" || !facilId
        ? { FacilityId: "" }
        : { FacilityId: facilId };
    delete params.FacilityId;
    delete params.ActType;
    delete params.CreationDateRange;
    let dd = {};
    if (createdDate) {
      if (createdDate.MaxValue != "")
        dd["CreationDateRange.MaxValue"] = createdDate.MaxValue;
      if (createdDate.MinValue != "")
        dd["CreationDateRange.MinValue"] = createdDate.MinValue;
    }
    getActsTable({
      ...dd,
      ...ActType,
      ...params,
      ...facility,
      pageNumber,
    });
  }, [pageNumber]);

  useEffect(() => {
    setIsErrorRequest(error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const recordsCount = useMemo(
    () => allActs && allActs?.recordsCount,
    [allActs]
  );

  const actKindOptions = [
    {
      value: "все",
      name: "все",
    },
    {
      value: "0",
      name: "АПС",
    },
    {
      value: "1",
      name: "СОУЭ",
    },
    {
      value: "2",
      name: "СПС",
    },
  ];
  const columns = [
    {
      id: "createdAt",
      label: t("tableHeader:actCreationDate"),
      numeric: false,
      clickable: false,
      enableSorting: false,
      size: "160px",
      RowCell: (props) => <SimpleTextCell {...props} />,
      isVisible: columnsFromStorage["createdAt"],
      HeaderCell: () => (
        <>
          <FormItem
            control={control}
            placeholder={"с"}
            name={`${formFields.searchParams}.CreationDateRange.MinValue`}
            sx={{ padding: "0px 0 1px 0px" }}
            input={(props) => (
              <DatePickerInbuild
                className="select_in_built small-light"
                {...props}
              />
            )}
          />
          <FormItem
            control={control}
            placeholder={"по"}
            name={`${formFields.searchParams}.CreationDateRange.MaxValue`}
            sx={{ padding: "0px 0 1px 0px" }}
            input={(props) => (
              <DatePickerInbuild
                className="select_in_built small-light"
                {...props}
              />
            )}
          />
        </>
      ),
    },
    {
      id: "facility",
      numeric: false,
      enableSorting: false,
      size: "100px",
      clickable: false,
      isVisible: columnsFromStorage["facility"],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <FormItem
          control={control}
          label={t("label:object")}
          name={`${formFields.searchParams}.FacilityId`}
          sx={{
            padding: "13px 0 13px 16px",
            color:"#4D5C6E !important"
          }}
          
          input={(props) => (
            <SelectSimpleObject
              {...props}
              IsTableheader={true}
              className="select_in_built small-light"
            />
          )}
        />
      ),
    },
    {
      id: "actKind",
      label: t("tableHeader:actKind"),
      numeric: false,
      clickable: false,
      enableSorting: false,
      isVisible: columnsFromStorage["actKind"],
      size: "140px",
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <FormItem
          control={control}
          name={`${formFields.searchParams}.ActType`}
          type={"select-text"}
          label={t("tableHeader:actKind")}
          options={actKindOptions}
          sx={{
            padding: "13px 0 13px 16px",
            width: "160px",
          }}
          input={(props) => (
            <SelectSimpleInbuilt
              className="select_in_built small-light"
              {...props}
            />
          )}
        />
      ),
    },
    {
      id: "documentName",
      label: t("tableHeader:acts"),
      numeric: false,
      enableSorting: false,
      isVisible: columnsFromStorage["documentName"],
      size: "150px",
      RowCell: (props) => <ClickableNameCell {...props} />,
      HeaderCell: () => (
        <div
          style={{
            color: "var(--headers)",
            fontSize: "16px",
            fontWeight: "500",
          }}
        >
          {t("tableHeader:acts")}
        </div>
      ),
    },
    {
      id: "usersSignatures",
      label: t("tableHeader:signature"),
      numeric: false,
      enableSorting: false,
      isVisible: columnsFromStorage["usersSignatures"],
      size: "200px",
      RowCell: (props) => <SignatureSignFieldSell {...props} />,
      HeaderCell: () => (
        <FormItem
          control={control}
          name={`${formFields.searchParams}.UserSignedAct`}
          sx={{
            padding: "9px 0 9px 16px",
          }}
          defaultValue={"все"}
          input={(props) => (
            <SearchInbuilt
              placeholder={t("tableHeader:signature")}
              className="select_in_built small-light"
              searchValue={searchuserSign}
              changeSearchValue={setsearchuserSign}
              {...props}
            />
          )}
        />
      ),
    },
    {
      id: "defectActNumber",
      label: t("tableHeader:defectActNumber"),
      numeric: false,
      enableSorting: false,
      isVisible: columnsFromStorage["defectActNumber"],
      size: "280px",
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <FormItem
          control={control}
          name={`${formFields.searchParams}.DefectActNumber`}
          sx={{
            padding: "9px 0 9px 16px",
          }}
          defaultValue={"все"}
          input={(props) => (
            <SearchInbuilt
              placeholder={t("tableHeader:defectActNumber")}
              className="select_in_built small-light"
              searchValue={searchDefectActNumber}
              changeSearchValue={setsearchDefectActNumber}
              {...props}
            />
          )}
        />
      ),
    },
    {
      id: "defectDocumentName",
      label: t("tableHeader:defectActs"),
      numeric: false,
      enableSorting: false,
      isVisible: columnsFromStorage["defectDocumentName"],
      size: "200px",
      RowCell: (props) => <ClickableNameCell {...props} />,
      HeaderCell: () => (
        <div
          style={{
            color: "var(--headers)",
            fontSize: "16px",
            fontWeight: "500",
          }}
        >
          {t("tableHeader:defectActs")}
        </div>
      ),
    },
    {
      id: "usersDefectSignatures",
      label: t("tableHeader:signature"),
      numeric: false,
      enableSorting: false,
      isVisible: columnsFromStorage["usersDefectSignatures"],
      size: "150px",
      RowCell: (props) => <SignatureDefectSignFieldSell {...props} />,
      HeaderCell: () => (
        <FormItem
          control={control}
          name={`${formFields.searchParams}.UserSignedDefectAct`}
          sx={{
            padding: "9px 0 9px 16px",
          }}
          defaultValue={"все"}
          input={(props) => (
            <SearchInbuilt
              placeholder={t("tableHeader:signature")}
              className="select_in_built small-light"
              searchValue={searchuserdefectSign}
              changeSearchValue={setsearchuserdefectSign}
              {...props}
            />
          )}
        />
      ),
    },
  ].filter(({ isVisible }) => isVisible);

  const getConfigFormModalSelect = (columns) =>
    columns?.map(({ name }) => ({
      name,
      label: `tableHeader:${name}`,
      type: "checkbox",
      rules: {},
      flexBasis: "50%",
      input: (props) => <CheckboxSimple {...props} />,
    }));
  const rows = useMemo(() => {
    const { data } = allActs;
    return data?.length
      ? data.map(
          ({
            id,
            facility,
            documentName,
            documentLink,
            defectDocumentName,
            defectDocumentLink,
            usersSigned,
            usersDefectSigned,
            currentUserSigned,
            currentUserDefectSigned,
            usersDefectSignatures,
            usersSignatures,
            createdAt,
            actKindId,
            actKind,
            ableToSign,
            ableToSignDefectAct,
            defectActNumber,
          }) => {
            return {
              id,
              facility,
              actKind,
              documentName: documentLink && {
                name: (
                  <Link target="_blank" to={`${documentLink}`}>
                    <Button
                      sx={{ p: 0 }}
                      variant="text"
                      endIcon={<DownloadIcon />}
                    >
                      {documentName}
                    </Button>
                  </Link>
                ),
                action: () => {},
              },
              usersSignatures: {
                isSigned: currentUserSigned,
                usersSignatures: usersSignatures,
                actType: actKindId,
                actId: id,
                ableToSign: ableToSign,
              },
              defectDocumentName: defectDocumentLink && {
                name: (
                  <Link target="_blank" to={`${defectDocumentLink}`}>
                    <Button
                      sx={{ p: 0 }}
                      variant="text"
                      endIcon={<DownloadIcon />}
                    >
                      {defectDocumentName}
                    </Button>
                  </Link>
                ),
                action: () => {},
              },
              usersDefectSignatures: {
                isSigned: currentUserDefectSigned,
                usersSignatures: usersDefectSignatures,
                actType: actKindId,
                ableToSign: ableToSignDefectAct,
                actId: id,
              },
              createdAt: dayjs(createdAt).format("DD.MM.YYYY HH:mm"),
              defectActNumber: defectActNumber,
            };
          }
        )
      : [];
    // eslint-disable-next-line
  }, [allActs]);

  useEffect(() => {
    setValue(
      `${formFields.searchParams}.UserSignedDefectAct`,
      searchuserdefectSign
    );
  }, [searchuserdefectSign, setValue]);
  useEffect(() => {
    setValue(`${formFields.searchParams}.UserSignedAct`, searchuserSign);
  }, [searchuserSign, setValue]);
  useEffect(() => {
    setValue(
      `${formFields.searchParams}.DefectActNumber`,
      searchDefectActNumber
    );
  }, [searchDefectActNumber, setValue]);

  const handleOpenSelectColumnsModal = () => {
    setOpenSelectModal(true);
  };

  return (
    <Box sx={{width:"100%"}}>
      <Box sx={{ textAlign: "right", marginBottom: "20px" }}>
      <Button
            className="btn btn-primary-fill magazine_events-select_container-btn"
            variant="outlined"
            onClick={handleOpenSelectColumnsModal}
            
            sx={{ height: "52px" }}
          >
            <Box
              component="img"
              src={settingsicon}
              sx={{marginRight:"16px"}}
          />
            {t("button:customize_fields_to_display")}
          </Button>
      </Box>
      <TableBasic
        withSearchField={false}
        columns={columns}
        rows={rows}
        handleClickCell={() => {}}
        pageNumber={pageNumber}
        changePageNumber={changePageNumber}
        isLoading={isLoading}
        count={recordsCount}
        minHeight="40vh"
        maxHeight="56vh"
      />
      <ModalSelectColumnsServer
        open={openSelectModal}
        setOpen={setOpenSelectModal}
        data={columnsFromStorage}
        refetch={() => getUserGrid({ gridSettingName: "DocumentList" })}
        nameStorage={"DocumentList"}
        config={getConfigFormModalSelect(gridcolumns?.columnSettings || [])}
      />
    </Box>
  );
};
