// libs imports
import React from "react";
import { t } from "i18next";
import { Box, Typography, Alert, InputAdornment } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

import { workingCorrectlyOptions } from "./options";

import InputText from "../../components/InputText";
import CheckboxSimple from "../../components/CheckboxSimple";
import Switcher from "../../components/Switcher";
import DatePickerField from "../../components/DatePickerField";
import { fileSizeCheck, fileTypeCheck } from "../../../validation";
import InputFileSingle from "../../components/InputFile/InputFileSingle";
import {
  FacilityAndFireCompartmentSelectGrouping,
  EquipmentTypeIdSelectGrouping,
  InputAutocompleteNameEquipments,
  PrefixStartNumberInput,
  CountEquipInput,
  OneEquipNumberInput,
} from "./Components";
import WorkContract from "./WorkContract";
import ShelfLifeItem from "./DateRelatedItems/ShelfLifeItem";
import SelectSimple from "../../components/SelectSimple";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import { DEFAULT_VALUE } from "../MyOrganizations/constants";

dayjs.extend(isSameOrAfter);

export const STEP = {
  DEFAULT: 1,
  SKIP: 3,
  LAST: 4,
};
export const placementOptions = [
  { value: 0, name: "Запотолочное пространство" },
  { value: 1, name: "Подпольное пространство" },
  { value: 2, name: "Основные помещения" },
];
export const stepsParams = [
  { step: 1, label: "generalInformation" },
  { step: 2, label: "regulations" },
  { step: 3, label: "schema" },
  //{ step: 4, label: "select" },
  { step: 4, label: "ready" },
];

export const formFields = {
  fireCompartmentId: "fireCompartmentId",
  object: "object",
  floor: "floor",
  name: "name",
  objectMain: "objectMain",
  floorMain: "floorMain",
  plume: "plume",
  number: "number",
  startNumber: "startNumber",
  endNumber: "endNumber",
  hasEquipmentGroup: "hasEquipmentGroup",
  equipmentsCount: "equipmentsCount",
  equipmentTypeId: "equipmentTypeId",
  integrationNumber: "integrationNumber",
  workingCorrectly: "workingCorrectly",
  manufactureDate: "manufactureDate",
  installationDate: "installationDate",
  contractDate: "contractDate",
  contractNumber: "contractNumber",
  shelfLife: "shelfLife",
  lastServiceDate: "lastServiceDate",
  performedServicePersonName: "performedServicePersonName",
  equipmentPassportFile: "equipmentPassportFile",
  years: "years",
  months: "months",
  selfService: "selfService",
  termOfContract: "termOfContract",
  equipmentContractFileIdAvailability: "equipmentContractFileIdAvailability",
  equipmentContractFileId: "equipmentContractFileId",
  responsibleOrganizationId: "responsibleOrganizationId",
  // for step 4
  floor: "floor",
  havingServiceContract: "havingServiceContract",
  regulationId: "regulationId",
  date: "date",
  period: "period",

  generalInformation: "generalInformation",
  regulations: "regulations",
  select: "select",
  schema: "schema",
  ready: "ready",
  placement: "placement",
  floorScheme: "floorScheme",
  roomAddress: "roomAddress",
  equipmentsCoordinates: "equipmentsCoordinates",
  roomAddresses: "roomAddresses",
};

const getDynamicFormFields = (items) => {
  if (!items) {
    return [];
  }
  return items.map((item, i) => {
    let subName = "";

    switch (item?.type) {
      case "number":
        subName = "period";
        break;
      case "date":
        subName = "date";
        break;
      default:
        break;
    }

    return {
      name: `${[formFields.regulations]}.${i}.${subName}`,
      label: item?.name,
      type: item?.type,
      rules: {
        max: item?.max,
        min: item?.min,
        required: true,
      },
      sx: { width: "100%" },
      additionalClassName:
        item?.type === "checkbox" ? "dynamic_checkbox_form" : "dynamic_form",
      InputProps: {
        endAdornment: subName === "period" && (
          <InputAdornment position="end">
            <Typography className="adornment">
              {t("label:in_months")}
            </Typography>
          </InputAdornment>
        ),
      },
      input: (props) =>
        item?.type === "checkbox" ? (
          <CheckboxSimple {...props} disabled={true} value={true} />
        ) : (
          <Box sx={{ marginTop: "4px" }}>
            {item?.type === "date" ? (
              <DatePickerField {...props} />
            ) : (
              <InputText {...props} />
            )}
            <Typography className="under-input">{item?.comment}</Typography>
          </Box>
        ),
    };
  });
};

export const contentFormConfig = (
  isActive,
  dynamicData,
  isSuccess,
  objectId,
  setValue,
  getValues,
  resetField
) => [
  {
    step: 1,
    name: formFields.generalInformation,
    label: "stepper_label:generalInformation",
    items: [
      {
        name: formFields.fireCompartmentId,
        label: "label:fireCompartmentId",
        type: "select-text",
        flexBasis: "49%",
        rules: {
          required: true,
        },
        input: (props) => (
          <FacilityAndFireCompartmentSelectGrouping {...props} />
        ),
      },
      {
        name: formFields.equipmentTypeId,
        label: "label:equipmentTypeId",
        type: "select-text",
        flexBasis: "49%",
        rules: {
          required: true,
        },
        // disabled: !objectId,
        input: (props) => (
          <EquipmentTypeIdSelectGrouping
            objectId={objectId}
            setValue={setValue}
            isRegulation={true}
            {...props}
          />
        ),
      },
      {
        name: formFields.name,
        label: "label:nameEquipment",
        type: "text",
        flexBasis: "49%",

        rules: {
          required: true,
        },
        disabled: !objectId, //|| selectedGroup,
        input: (props) => (
          <InputAutocompleteNameEquipments
            getValues={getValues}
            resetField={resetField}
            {...props}
          />
        ),
      },
      {
        name: formFields.placement,
        label: "label:placement",
        type: "select-number",
        flexBasis: "49%",
        defaultValue: 2,
        options: placementOptions,
        rules: {},
        input: (props) => (
          <Box
            className="medium_container"
            sx={{ alignSelf: "flex-start !important" }}
          >
            {" "}
            <SelectSimple
              sx={{ alignSelf: "flex-start !important" }}
              {...props}
            />{" "}
          </Box>
        ),
      },
      {
        name: formFields.hasEquipmentGroup,
        label: "label:hasEquipmentGroup",
        type: "checkbox",
        rules: {},
        sx: {ml: '12px'},
        flexBasis: "100%",
        expended: [
          {
            name: formFields.startNumber,
            label: "label:startNumber",
            type: "text",
            input: (props) => (
              <PrefixStartNumberInput
                placeholder={t("placeholder:object_number")}
                {...props}
              />
            ),
          },
          {
            name: formFields.equipmentsCount,
            label: "label:equipmentsCount",
            type: "number",
            rules: {
              maxLength: (v) => v?.length <= 3,
              validate: (value, formValues) => value >= 1,
            },
            onKeyDown: (e) => {
              if (
                e.key === "e" ||
                e.key === "E" ||
                e.key === "-" ||
                e.key === "+" ||
                e.key === "." ||
                e.key === ","
              ) {
                e.preventDefault();
              }
            },
            InputProps: { type: "number" },
            input: (props) => (
              <CountEquipInput
                placeholder={t("placeholder:number")}
                getValues={getValues}
                setValue={setValue}
                {...props}
              />
            ),
          },
          {
            name: formFields.endNumber,
            label: "label:endNumber",
            type: "text",
            disabled: true,
            input: (props) => (
              <Box className="medium_container">
                <InputText
                  placeholder={t("placeholder:object_number")}
                  sx={{ width: "350px" }}
                  {...props}
                />
              </Box>
            ),
          },
        ],
        input: (props) => <CheckboxSimple {...props} />,
      },
      {
        name: formFields.number,
        label: "label:equipNumber",
        flexBasis: "100%",
        rules: {
          required: true,
        },

        input: (props) => (
          <Box
            className="medium_container inp_number_plum"
            sx={{ width: "350px" }}
          >
            <OneEquipNumberInput
              placeholder={t("placeholder:equipment_number")}
              objectId={objectId}
              getValues={getValues}
              {...props}
            />
          </Box>
        ),
      },

      {
        name: formFields.plume,
        label: "label:plume",
        type: "number",
        flexBasis: "100%",
        rules: {
          validate: (value, formValues) => value == null || value?.length >= 3,
        },
        onKeyDown: (e) => {
          if (
            e.key === "e" ||
            e.key === "E" ||
            e.key === "-" ||
            e.key === "+" ||
            e.key === "." ||
            e.key === ","
          ) {
            e.preventDefault();
          }
        },
        InputProps: { type: "number" },
        input: (props) => (
          <Box
            className="medium_container inp_number_plum"
            sx={{ width: "350px" }}
          >
            <InputText {...props} subLabel={t("label:in_the_presence_of")} />
          </Box>
        ),
      },
      {
        name: formFields.integrationNumber,
        label: "label:equipmentIntegrationNumber",
        flexBasis: "100%",
        type: "text",
        input: (props) =>
          isActive && (
            <Box className="medium_container" sx={{ width: "350px" }}>
              <InputText {...props} subLabel={t("label:in_the_presence_of")} />
            </Box>
          ),
      },
      {
        name: formFields.manufactureDate,
        label: "label:manufactureDate",
        type: "date",
        flexBasis: "100%",
        rules: {
          required: true,
        },
        additionalClassName: "resizing",
        input: (props) => (
          <Box className="medium_container" sx={{ width: "350px" }}>
            <DatePickerField {...props} />
          </Box>
        ),
      },
      {
        name: formFields.installationDate,
        label: "label:installationDate",
        type: "date",
        flexBasis: "100%",
        rules: {
          validate: {
            earliestAvailableDateCheck: (value, { manufactureDate }) =>
              value ? dayjs(value).isSameOrAfter(dayjs(manufactureDate)) : null,
          },
        },
        additionalClassName: "resizing",
        input: (props) => (
          <Box className="medium_container" sx={{ width: "350px" }}>
            <DatePickerField sx={{ width: "33%" }} {...props} />
          </Box>
        ),
      },
      {
        name: formFields.lastServiceDate,
        label: "label:lastServiceDate",
        type: "date",
        flexBasis: "100%",
        rules: {},
        additionalClassName: "resizing",
        input: (props) => (
          <Box className="medium_container" sx={{ width: "350px" }}>
            <DatePickerField sx={{ width: "33%" }} {...props} />
          </Box>
        ),
      },
      {
        name: formFields.workingCorrectly,
        label: "label:workingCorrectly",
        type: "switch",
        flexBasis: "100%",
        options: workingCorrectlyOptions,
        rules: {},
        input: (props) => <Switcher {...props} />,
      },
      {
        id: 4,
        name: formFields.shelfLife,
        flexBasis: "100%",
        label: "label:shelfLife",
        CustomFormItem: ShelfLifeItem,
      },

      {
        name: formFields.performedServicePersonName,
        label: "label:performedServicePersonName",
        type: "text",
        flexBasis: "100%",
        rules: {},
        additionalClassName: "resizing",
        input: (props) => (
          <InputText
            placeholder="Введите ФИО"
            sx={{ width: "32%" }}
            {...props}
          />
        ),
      },
      {
        name: formFields.equipmentPassportFile,
        type: "file",
        rules: {
          validate: {
            type: (files) => {
              const availableFileTypes = [
                "image/jpeg",
                "image/png",
                "application/pdf",
              ];
              return fileTypeCheck(
                files.filesToAdd,
                formFields.equipmentPassportFile,
                availableFileTypes
              );
            },
            size: (files) => {
              const maxAvailableFileSize = 10; // MBs
              return fileSizeCheck(
                files.filesToAdd,
                formFields.equipmentPassportFile,
                maxAvailableFileSize
              );
            },
          },
        },
        canDeleteFile: false,
        preview: false,
        input: (props) => (
          <Box>
            <Typography className="label">
              {t("label:equipmentPassport")}
            </Typography>
            <InputFileSingle {...props} accept={".png, .jpg, .jpeg, .pdf"} />
          </Box>
        ),
      },
    ],
  },
  {
    step: 2,
    name: formFields.regulations,
    label: "stepper_label:regulations",
    items: [
      {
        name: formFields.name,
        label: "label:nameEquipment",
        type: "text",
        rules: {
          required: true,
        },
        disabled: true,
        input: (props) => (
          <Box>
            <InputText sx={{ maxWidth: "100%" }} {...props} />
          </Box>
        ),
      },
      ...getDynamicFormFields(dynamicData),
    ],
  },
  {
    step: 3,
    name: formFields.schema,
    label: "stepper_label:schema",
    items: [
      {
        name: formFields.fireCompartmentId,
        label: "label:fire_compartment",
        type: "text",
        rules: {},
        disabled: true,
        input: (props) => {
          const { name } = props.value ? JSON.parse(props.value) : { name: "" };
          return <InputText {...props} value={name} />;
        },
      },
      {
        name: formFields.floor,
        label: "label:floor",
        type: "select-text",
        rules: {},
        input: (props) => <SelectSimple {...props} />,
      },
    ],
  },
  /*{
      step: 4,
      name: formFields.select,
      label: "stepper_label:select",
      items: [
        {
          name: formFields.havingServiceContract,
          label: "label:having_service_contract",
          type: "checkbox",
          rules: {},
          input: (props) => (
            <Box className="resizing">
              <CheckboxSimple {...props} />
            </Box>
          ),
        },
        {
          name: formFields.name,
          label: "label:nameEquipment",
          type: "text",
          rules: {},
          disabled: true,
          input: (props) => (
            <Box className="medium_container">
              <InputText {...props} />
            </Box>
          ),
        },
        {
          id: 7,
          rules: {},
          CustomFormItem: WorkContract,
        },
      ],
    },*/
  {
    step: 4,
    name: formFields.ready,
    label: isSuccess ? "stepper_label:ready" : "stepper_label:error",
    items: [],
    Component: () => (
      <Box sx={{ height: "25vh" }}>
        {isSuccess ? (
          <Alert
            className="allert success"
            icon={<CheckIcon fontSize="inherit" />}
            variant="filled"
            severity="success"
          >
            {t("notification:success_add_equipment")}
          </Alert>
        ) : (
          <Alert className="allert error" variant="filled" severity="error">
            {t("notification:error_add_equipment")}
          </Alert>
        )}
      </Box>
    ),
  },
];

export const modalAddressConfig = [
  {
    id: 1,
    name: formFields.roomAddress,
    label: "label:roomAddress",
    type: "text",
    rules: {
      required: true,
    },
    input: (props) => <InputText {...props} />,
  },
];
