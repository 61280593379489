import React, { useState } from "react";
import Timeline from "react-visjs-timeline";
import dayjs from "dayjs";
import "./styles.css";
import { dateFormat } from "../../../constants";

export const TimelineThing = ({ calendarPlan }) => {
  const [options] = useState({
    width: "100%",
    stack: true,
    stackSubgroups: true,
    orientation: "top",
    showMajorLabels: true,
    showCurrentTime: true,
    zoomMin: 60 * 60 * 60 * 60,
    showTooltips: true,
    zoomable: true,
    moveable: true,
    start: calendarPlan?.start,
    end: calendarPlan?.end,
    selectable: true,
    groupOrder: "content",
    loadingScreenTemplate: function () {
      return "<h3>Загрузка данных...</h3>";
      
    },
  });

  const generateRandomClass = () => {
    const randomClasses = ["lightblue", "peach", "mint", "aqua", "lilac"];
    const randomIndex = Math.floor(Math.random() * randomClasses.length);
    return randomClasses[randomIndex];
  };

  return (
    <Timeline
      options={options}
      items={calendarPlan?.items?.map((item) => ({
        ...item,
        start: new Date(item.start).setHours(0,0,0,0),
        end: new Date(item.end).setHours(0,0,0,0),
        title: `Начало: ${dayjs(item.start).format(dateFormat)} - Конец: ${dayjs(item.end).format(dateFormat)}`,
        className: generateRandomClass(),
      }))}
      groups={calendarPlan?.groups?.map(({ content, performer, ...rest }) => ({
        ...rest,
        content: `${performer || ""}</br> ${content}`,
      }))}
    />   
  );
};
