import React,{useState} from "react";
import { useTranslation } from "react-i18next";
import { ListItemText, OutlinedInput, Typography,Box } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Search from "./Search";
import selectArrowUp from "../../../image/icons/selectArrowUp.png";
import selectArrowDown from "../../../image/icons/selectArrowDown.png"

const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 13;
const SEARCH_HEIGHT= 72;

const SelectSimpleSearch = ({
  options,
  label,
  onChange,
  error,
  value,
  labelKey,
  searchValue,
  placeholder,
  displayEmpty = false,
  setSearchValue,
  setValue,
  ...rest
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: options?.length>=4 ? (ITEM_HEIGHT+ ITEM_PADDING_TOP) * 4 + SEARCH_HEIGHT : (ITEM_HEIGHT+ ITEM_PADDING_TOP) * options?.length + SEARCH_HEIGHT ,
      },
    },
  };
  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };
  return (
    <Box sx={{ position: "relative" }} className="input_text">
    <InputLabel sx={{ mb: "8px" }}>
      {t(label)}
    </InputLabel>
    <FormControl fullWidth error={!!error} {...rest}>
      <Select
        value={(value || "")}
        input={<OutlinedInput />}
        onChange={onChange}
        MenuProps={MenuProps}
        displayEmpty={displayEmpty}
        sx={{ ".MuiSelect-select": { height: 0 } }}
        open={isOpen}
        onOpen={handleOpen}
        onClose={handleClose}
        IconComponent={() =>
          <Box sx={{ mr: "24px",cursor:"pointer" }} onClick={()=>{setIsOpen((prev)=> !prev)}}>
            {isOpen ? (
              <img src={selectArrowUp} />
            ) : (
              <img src={selectArrowDown} />
            )
            }
          </Box>}
      >
        <Search searchValue={searchValue} setSearchValue={setSearchValue} />
        {displayEmpty &&
            <MenuItem sx={{ height: ITEM_HEIGHT+ ITEM_PADDING_TOP }} key={"none"} value="">
              {t(placeholder) || "Нет"}
            </MenuItem>

          }
        {options && options?.length > 0 && options?.map((option, i) => (
          <MenuItem
            key={i}
            value={option.value || option || ""}
            sx={{ height: ITEM_HEIGHT+ITEM_PADDING_TOP }}
          >
            <ListItemText
              primary={
                <Typography>
                  {option.name?.toString() || option?.[labelKey] || option || ""}
                </Typography>
              }
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
    </Box>
  );
};

export default SelectSimpleSearch;
