import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  useEditMessageCodeMutation,
  useLazyGetMessageCodeQuery,
  useLazyGetMessageCodeElementsQuery,
  useLazyGetMessageCodeTypesQuery,
  useLazyResetMessageCodesQuery
} from "../../../middleware/messageCodeApi";
import { useAction } from "../../../hooks/actions";
import { Box, Typography } from "@mui/material";
import { useAppSelector } from "../../../hooks/redux";
import { useForm, useWatch } from "react-hook-form";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Button,
  Tooltip,
  Paper,
  Select,
  CircularProgress,
  MenuItem,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import InfiniteScroll from 'react-infinite-scroll-component'
import { elementOptions, messageCodeTypeOptions } from "./constants";
import { WarningValidationForm } from "../../components/Notifications";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import FormItem from "../../components/FormItem";
import { SimpleHeader } from "../TasksList/Components";
import { SearchInbuilt } from "../../components/SearchInbuilt";
import RefreshIcon from '@mui/icons-material/Refresh';
import { PATHS } from "../../../constants";
import "./styles/style.css"

export const MessageCodePage = () => {
  const { t } = useTranslation();
  const { messageCodeTypes } = useAppSelector((state) => state.messageCode);

  const { messageCodeElems } = useAppSelector((state) => state.messageCode);
  const [searchValue /* changeSearchValue */] = useState("");
  const [pageNumber, changePageNumber] = useState(0);
  const [selectedValues, setSelectedValues] = useState({});
  const [ranOnce, setRanOnce] = useState(false);
  const [messElem, setmessElem] = useState([]);
  const [messTypes, setmessTypes] = useState([]);
  const [getParams, setSearchParams] = useSearchParams();
  const protectionObjectId = getParams.has("protectionObjectId") ? getParams.get("protectionObjectId") : null;
  const [searchName, setSearchName] = useState('')
  const [searchCode, setSearchCode] = useState('')

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const params = useWatch({
    control,
    name: "search",
  });
  const isWarningValidation = !!Object.keys(errors).length;
  const [messag, setData] = useState({});
  const { id } = useParams();
  const [controlConsoleName, setControlConsoleName] = useState("");
  const [protectionObjecName, setprotectionObjecName] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const {
    setIsErrorRequest,
    setIsErrorSubmit,
    setIsSuccessSubmit,
    changeTitlePage,
  } = useAction();

  const [getMessageCode, { error: errorGetMessage /* isLoading */ }] =
    useLazyGetMessageCodeQuery();
  const [getMessageCodeElements, { error: errorGetMessageElem /* isLoading */ }] =
    useLazyGetMessageCodeElementsQuery();
  const [getMessageCodeTypes, { error: errorGetMessageTypes /* isLoading */ }] =
    useLazyGetMessageCodeTypesQuery();
  const [editMessageCode, { error: errorEdit, isSuccess: isSuccessEdit }] =
    useEditMessageCodeMutation();
    
    const [resetMessageTemplate, { error: errorresetMessageTemplate /* isLoading */ }] =
    useLazyResetMessageCodesQuery();

  useEffect(() => {

    getMessageCodeElements().then((data) => {
      setmessElem(data)
    })
    getMessageCodeTypes().then((data) => {
      setmessTypes(data)
    })
    setmessElem([...messElem])
    setmessTypes([...messTypes])

  }, [getMessageCodeElements, getMessageCodeTypes]);
  useEffect(() => {
    setIsErrorSubmit(errorEdit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorEdit]);

  useEffect(() => {
    setIsErrorRequest(errorGetMessage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorGetMessage]);

  useEffect(() => {
    setIsSuccessSubmit(isSuccessEdit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessEdit]);

  useEffect(() => {
    controlConsoleName && changeTitlePage(controlConsoleName || "");
  }, [controlConsoleName, changeTitlePage]);

  useEffect(() => () => changeTitlePage(""), [changeTitlePage]);

  const fetchData = async () => {
    try {
      let dd = {}
      let res = ""

      if (searchName != "") dd["name"] = searchName
      if (searchCode != "") dd["code"] = searchCode
      if (dd != {}) {
        res = "&" + Object.keys(dd).map((key) => `${key}=${dd[key]}`).join("&")
      }
      const response = await getMessageCode({
        controlConsoleId: id,
        pageNumber: 0,
        protectionObjectId: (protectionObjectId != null) ? `&protectionObjectId=${protectionObjectId}` : "",
        filters: res
      });
      console.log("error", response?.data?.messageCodes);
      setData({ ...response?.data, hasMore: response?.data?.messageCodes?.data.length < response?.data.messageCodes.recordsCount ? true : false, });
      if (response?.data?.protectionObjectName != null)
        setprotectionObjecName(response?.data?.protectionObjectName)

      setControlConsoleName(response?.data?.controlConsoleName);

    } catch (error) {
      console.log("error");
    }
  };
  useEffect(() => {
    

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchName, searchCode]);

  const onSubmit = () => {
    let res = {};
    if (protectionObjectId)
      res = { protectionObjectId: protectionObjectId }
    const newData = messag?.messageCodes.data
    // setData(newData);
    console.log("newData", { messageCodes: [...newData], controlConsoleId: id, ...res })
    editMessageCode({ messageCodes: [...newData], controlConsoleId: id, ...res });
  };

  const handleFieldChange = (event, field, id) => {
    const updatedData = messag?.messageCodes.data.map((item) => {
      if (item.id === id) {
        return { ...item, [field]: event.target.value };
      }
      return item;
    });
    setData({ ...messag, messageCodes: { ...messag?.messageCodes, data: updatedData } });

  };

  const handleCancel = () => {
    navigate(-1);
  };
  const handleClose = () => {
    navigate(PATHS.CONTROLLERS);
  };
  
  const messagefetchData = () => {
    setLoading(true);
    setTimeout(() => {
      let dd = {}
      let res = ""

      if (searchName != "") dd["name"] = searchName
      if (searchCode != "") dd["code"] = searchCode
      if (dd != {}) {
        res = "&" + Object.keys(dd).map((key) => `${key}=${dd[key]}`).join("&")
      }
      getMessageCode({
        controlConsoleId: id,
        pageNumber: pageNumber + 1,
        protectionObjectId: (protectionObjectId != null) ? `&protectionObjectId=${protectionObjectId}` : "",
        filters: res
      }).then(({ data, isSuccess }) => {
        changePageNumber(prev => prev + 1)
        setData({ hasMore: data?.messageCodes.data.length + messag?.messageCodes.data.length < data.messageCodes.recordsCount ? true : false, messageCodes: { ...messag?.messageCodes, data: [...messag?.messageCodes.data, ...data.messageCodes.data] } });
        setLoading(false);
      })

    }, 1000);

  }
  const handleMouseEnter = (id) => {
    setSelectedValues({})
    setSelectedValues((prevState) => {
      return { ...prevState, [id]: true };
    });
  };

  const handleMouseLeave = (id) => {
    setSelectedValues({})
  };
  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight;
    if (bottom >= 1 && bottom <= 50 && !loading && messag?.hasMore) {
      messagefetchData()
    }
  };
  const resetMesageTemplate = () => {
     resetMessageTemplate({
          controlConsoleId: id,
          protectionObjectId: (protectionObjectId != null) ? `&protectionObjectId=${protectionObjectId}` : "",
        }).then(() => {
          fetchData()
        })    
  }

  return (
    <Box className="object">
      <Box>
        <Box className="object-header">
          <Typography className="title-page">
            {t("title_page:message_template")}
          </Typography>
          <Box sx={{ display: "inherit" }}>
            <Typography className="title-page" sx={{ marginRight: "40px" }}>{`Пульт: ${controlConsoleName}`}</Typography>
            <Typography className="title-page">{protectionObjecName != "" ? `Объект: ${protectionObjecName}` : ""}</Typography>
          </Box>
          
          <Box className="object-header-buttons">
          <Box className="full_screen_icon-wrapper">
            <RefreshIcon
              className="full_screen_icon"
              sx={{
                marginTop: "7px",
                marginLeft: "30px"
              }}
              onClick={() => resetMesageTemplate()}
            />
          </Box>
          <Button className="btn btn-outlined-grey" onClick={handleClose}>
            {t("button:to_back")}
          </Button>
            <Button className="btn btn-outlined-grey" onClick={handleCancel}>
              {t("button:comeback")}
            </Button>
            <Button
              form="myform"
              className="btn btn-primary-fill"
              onClick={onSubmit}
            >
              {t("button:save")}
            </Button>
          </Box>
        </Box>
        
        <Box sx={{ marginTop: "30px" }}>
          {messag && (
            <>

              <TableContainer component={Paper} onScroll={handleScroll} style={{ maxHeight: "70vh", overflow: 'auto' }}>
                <Table stickyHeader className="table">
                  <TableHead className="table-head">
                    <TableRow
                      sx={{
                        backgroundColor: "#e2e8f0",
                        height: "40px",
                      }}
                    >
                      <TableCell
                        sx={{ padding: "8px 16px" }}
                        style={{ backgroundColor: "#e2e8f0" }}
                        className={`table-head-cell`}
                      >
                        <Box sx={{ width: "110px" }}>
                          <SimpleHeader name={t("tableHeader:message_code")} />
                          <FormItem
                            control={control}

                            name={`search.code`}
                            sx={{ padding: "12px 0" }}
                            input={(props) => (
                              <SearchInbuilt
                                className="select_in_built small-light"
                                bgcolor={"var(--bgLightGrey)"}
                                searchValue={searchCode}
                                disabled
                                changeSearchValue={setSearchCode}
                                {...props}
                              />
                            )}
                          />
                        </Box>

                      </TableCell>
                      {/*<TableCell
                          sx={{ padding: "8px" }}
                          style={{ backgroundColor: "#e2e8f0" }}
                        >
                          Шаблон сообщений
                      </TableCell>*/}
                      <TableCell
                        sx={{ padding: "8px" }}
                        style={{ backgroundColor: "#e2e8f0" }}
                        className={`table-head-cell sticky`}
                      >
                        <Box sx={{ width: "210px" }}>
                          <SimpleHeader name={t("tableHeader:event_name")} />
                          <FormItem
                            control={control}

                            name={`search.name`}
                            sx={{ padding: "12px 0" }}
                            input={(props) => (
                              <SearchInbuilt
                                className="select_in_built small-light"
                                bgcolor={"var(--bgLightGrey)"}
                                searchValue={searchName}
                                disabled
                                changeSearchValue={setSearchName}
                                {...props}
                              />
                            )}
                          />
                        </Box>

                      </TableCell>

                      <TableCell
                        sx={{ padding: "8px 16px" }}
                        style={{ backgroundColor: "#e2e8f0" }}
                        className={`table-head-cell sticky`}
                      >
                        <SimpleHeader name={t("tableHeader:event")} />

                      </TableCell>
                      <TableCell
                        sx={{ padding: "8px 16px" }}
                        style={{ backgroundColor: "#e2e8f0" }}
                        className={`table-head-cell sticky`}
                      >
                        <SimpleHeader name={t("tableHeader:element")} />
                      </TableCell>

                    </TableRow>
                  </TableHead>
                  <TableBody >


                    {messag?.messageCodes?.data?.length > 0 && messag?.messageCodes?.data.map((item, index) => (
                      <TableRow
                        key={item.id}
                        sx={{
                          backgroundColor: messageCodeTypes.find(itemm => itemm.value === item.messageCodeTypeId)?.color

                        }}
                      >
                        <TableCell sx={{ width: "50px",border:`1px solid ${messageCodeTypes.find(itemm => itemm.value === item.messageCodeTypeId)?.borderColor}`,borderRight:"0px" }}>{item.code}</TableCell>
                        {/* <TableCell>{item.code}</TableCell>*/}
                        <TableCell sx={{ width: "750px", padding: "8px",border:`1px solid ${messageCodeTypes.find(itemm => itemm.value === item.messageCodeTypeId)?.borderColor}`,borderLeft:"0px",borderRight:"0px" }}>
                          <Box sx={{ width: "750px" }} onMouseEnter={() => handleMouseEnter(`name${item.id}`)}
                            onMouseLeave={() => handleMouseLeave(`name${item.id}`)}>
                            {selectedValues[`name${item.id}`] ?
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",

                                }}
                                onMouseLeave={() => handleMouseLeave(`name${item.id}`)}
                              >
                                <TextField
                                  fullWidth
                                  variant="standard"
                                  value={item.name}
                                  onChange={(event) =>
                                    handleFieldChange(event, "name", item.id)
                                  }
                                />
                                <EditOutlinedIcon />
                              </Box>
                              :
                              <Typography

                                onMouseEnter={() => handleMouseEnter(`name${item.id}`)}
                              >
                                {item.name}
                              </Typography>
                            }
                          </Box>
                        </TableCell>

                        <TableCell sx={{ width: "160px", padding: 0,border:`1px solid ${messageCodeTypes.find(itemm => itemm.value === item.messageCodeTypeId)?.borderColor}`,borderLeft:"0px",borderRight:"0px"  }}>
                          <Box sx={{ width: "250px" }} onMouseEnter={() => handleMouseEnter(`codetypes${item.id}`)}
                            onMouseLeave={() => handleMouseLeave(`codetypes${item.id}`)}>
                            {selectedValues[`codetypes${item.id}`] ?
                              <Select
                                sx={{
                                  "& .MuiOutlinedInput-notchedOutline": {
                                    border: "none",
                                  },
                                  "&:hover .MuiOutlinedInput-notchedOutline": {
                                    border: "none",
                                  },
                                  "& .MuiSelect-select:focus": {
                                    backgroundColor: "transparent",
                                  },
                                }}
                                onMouseLeave={() => handleMouseLeave(`codetypes${item.id}`)}
                                value={item.messageCodeTypeId}
                                onChange={(event) =>
                                  handleFieldChange(
                                    event,
                                    "messageCodeTypeId",
                                    item.id
                                  )
                                }

                              >
                                {messageCodeTypes.length > 0 && messageCodeTypes?.map((option) => (
                                  <MenuItem
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.name}
                                  </MenuItem>
                                ))}
                              </Select>
                              :
                              <Typography
                                sx={{ padding: "17px 32px 17px 14px" }}
                                onMouseEnter={() => handleMouseEnter(`codetypes${item.id}`)}
                              >
                                {messageCodeTypes.find(itemm => itemm.value === item.messageCodeTypeId)?.name}
                              </Typography>
                            }
                          </Box>
                        </TableCell>

                        <TableCell sx={{ width: "250px", padding: 0,border:`1px solid ${messageCodeTypes.find(itemm => itemm.value === item.messageCodeTypeId)?.borderColor}`,borderLeft:"0px"  }}>
                          <Box sx={{ width: "250px" }} onMouseEnter={() => handleMouseEnter(`element${item.id}`)}
                            onMouseLeave={() => handleMouseLeave(`element${item.id}`)}>
                            {selectedValues[`element${item.id}`] ?
                              <Select
                                sx={{
                                  "& .MuiOutlinedInput-notchedOutline": {
                                    border: "none",
                                  },
                                  "&:hover .MuiOutlinedInput-notchedOutline": {
                                    border: "none",
                                  },
                                  "& .MuiSelect-select:focus": {
                                    backgroundColor: "transparent",
                                  },
                                }}
                                onMouseLeave={() => handleMouseLeave(`element${item.id}`)}
                                value={item.elementId}
                                onChange={(event) =>
                                  handleFieldChange(event, "elementId", item.id)
                                }
                              >
                                {messageCodeElems.length > 0 && messageCodeElems?.map((option) => (
                                  <MenuItem
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.name}
                                  </MenuItem>
                                ))}
                              </Select>
                              :
                              <Typography
                                sx={{ padding: "17px 32px 17px 14px" }}
                                onMouseEnter={() => handleMouseEnter(`element${item.id}`)}
                              >
                                {messageCodeElems.find(itemm => itemm.value === item.elementId)?.name}
                              </Typography>
                            }
                          </Box>
                        </TableCell>


                      </TableRow>
                    ))}
                    {loading && (
                      <TableRow>
                        <TableCell colSpan={4}>
                          <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <CircularProgress />
                          </Box>
                        </TableCell>
                      </TableRow>
                    )}

                  </TableBody>
                </Table>
              </TableContainer>

            </>
          )}

        </Box>
      </Box>
      {isWarningValidation && (
        <WarningValidationForm isWarning={isWarningValidation} />
      )}
    </Box>
  );
};
