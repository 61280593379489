import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  getDefaultValues,
  ProfileMainDataFormFormConfig,
} from "./form-config.jsx";
import { useEditProfileInfoMutation } from "../../../../middleware/usersApi";
import { useManipulateFilesMutation } from "../../../../middleware/apiUploadFile";
import { useAction } from "../../../../hooks/actions";

import FormItem from "../../../components/FormItem";
import { WarningValidationForm } from "../../../components/Notifications/index.jsx";
import { ConfirmPhoneNumber } from "../ConfirmPhoneNumber/index.jsx";
import { getMaskPhone } from "../../../../helpers/helper.js";

import "./../styles/styles.css";
import DefaultAvatarIcon from "../../../components/icons/DefaultAvatarIcon.jsx";

const ProfileMainDataForm = ({ profileData, token, isEmpoyee = false }) => {
  const { t } = useTranslation();
  const [
    editProfileInfo,
    { error: errorEditProfileInfo, isSuccess: isSuccessEditProfileInfo },
  ] = useEditProfileInfoMutation();
  const [manipulateFiles, { error: errorFile, isSuccess: isSuccessFile }] =
    useManipulateFilesMutation();

  const { setIsErrorSubmit, setIsSuccessSubmit } = useAction();
  const {
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: getDefaultValues(profileData),
  });
  console.log(profileData);

  const isWarningValidation = !!Object.keys(errors).length;
  const isError = errorEditProfileInfo || errorFile;
  const isSuccess = isSuccessEditProfileInfo && isSuccessFile;

  useEffect(() => {
    setIsErrorSubmit(isError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  useEffect(() => {
    setIsSuccessSubmit(isSuccess);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  return (
    <>
      <div className="profile">
        <div className="user-profile">
          <div className="user-profile__img">
            {profileData.avatarLink ? (
              <img src={profileData.avatarLink} alt="Аватар пользователя" />
            ) : (
              <DefaultAvatarIcon />
            )}
          </div>
          <div className="user-profile__info">
            <p className="user-profile__name">
              {profileData.lastName} {profileData.firstName}{" "}
              {profileData.surname}
            </p>

            <div className="user-profile__roles">
              <div>
                {t("label:role")}: {profileData.role}
              </div>
              <div>
                {t("label:position")}: {profileData.jobTitle}
              </div>
            </div>

            <div className="user-profile__number">
              <p>
                {profileData.phone
                  ? getMaskPhone(profileData.phone)
                  : t("label:doesntHave")}
              </p>
              <ConfirmPhoneNumber isConfirmed={profileData.phoneVerified} />
            </div>

            <p className="user-profile__email">{profileData.email}</p>
            <p className="user-profile__job-name">{profileData.userEmployers[0]}</p>
          </div>
        </div>

        <div className="profile-notification disabled">
          <p className="profile-notification__title">
            {t("label:notifications")}
          </p>
          {ProfileMainDataFormFormConfig.map(
            ({
              id,
              name,
              label,
              input,
              expended,
              block,
              title,
              CustomFormItem,
              Component,
              ...rest
            }) => (
              <FormItem
                key={id}
                control={control}
                errors={errors}
                name={name}
                label={label}
                input={input}
                expended={expended}
                {...rest}
              />
            )
          )}
        </div>
      </div>

      {isWarningValidation && (
        <WarningValidationForm isWarning={isWarningValidation} />
      )}
    </>
  );
};

export default ProfileMainDataForm;
