import React, { useEffect, useState,useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Box, Typography,Button } from "@mui/material";

import { useNavigate, useParams,useSearchParams  } from "react-router-dom";


import { PATHS } from "../../../../constants";
import {
  convertToValuesArray,
  getPositiveKeys,
  getRequestDataFilesForAdd,
  getRequestData,
  updateValuesWithFilter,
} from "../AddReglament/helpers";
import { useAction } from "../../../../hooks/actions";

import BasicForm from "../BasicForm";
import { formFields } from "../AddReglament/options";
import {
  useEditFacilityMutation,
} from "../../../../middleware/facilitiesApi";
import { useManipulateFilesMutation } from "../../../../middleware/apiUploadFile";

import { useGetEquipmentSystemsQuery } from "../../../../middleware/equipmentsApi";
import { useGetRegulationQuery,useUpdateRegulationsMutation } from "../../../../middleware/regulationsApi";
import {formConfigSection} from "../AddReglament/form-config";
const EditReglamentPage = ({ reglamentData, onClose, style }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [getParams] = useSearchParams();

  const id = getParams.has("id") ? getParams.get("id") : "";
  const regulationId = getParams.has("regulationId") ? getParams.get("regulationId") : "";
  const protectionObjectId = getParams.has("protectionObjectId") ? getParams.get("protectionObjectId") : "";
  const equipmentKindId = getParams.has("equipmentKindId") ? getParams.get("equipmentKindId") : "";
  const equipmentTypeId = getParams.has("equipmentTypeId") ? getParams.get("equipmentTypeId") : "";
  const equipmentGroup = getParams.has("equipmentGroup") ? getParams.get("equipmentGroup") : "";
  const {
    changeTitlePage,
    setIsErrorRequest,
    setIsErrorSubmit,
    setIsSuccessSubmit,
  } = useAction();


  const {
    data: regulationResponse,
    isLoading: isLoadingRegulation,
    error: errorGetRegulaion,
    refetch: refetchFacility,
  } = useGetRegulationQuery({ 
      Id:id, 
      RegulationId:regulationId,
      ProtectionObjectId:protectionObjectId,
      EquipmentKindId:equipmentKindId,
      EquipmentTypeId:equipmentTypeId,
      EquipmentGroupId: equipmentGroup && equipmentGroup !== 'null' && equipmentGroup || undefined
    }, {
    skip: !equipmentKindId || reglamentData
  });

  const regulation = reglamentData || regulationResponse

  const [EditRegulation, { error: isErrorEdit, isSuccess: isSuccessEdit }] =
  useUpdateRegulationsMutation();
  const [manipulateFiles, { isError: isErrorFile, isSuccess: isSuccessFile }] =
    useManipulateFilesMutation();

  const isError = isErrorEdit || isErrorFile;
  const isSuccess = isSuccessEdit && isSuccessFile;


  const onSubmit = async (data) => {
    const { entities,techcardFiles } = getRequestData({data,id:regulation.id});
  
    EditRegulation(entities).then((result) => {
      if ("error" in result) {
        return;
      }
     const id = result?.data?.id;
      const requestDataFiles = getRequestDataFilesForAdd(id, techcardFiles);

     manipulateFiles(requestDataFiles).then((response) => {
        if ("error" in response) {
          return;
        }
        
      });
      if(onClose) {
        onClose()
      } else {
        navigate(PATHS.EQUIPMENT_REGLAMENTS);
      }
    })
    
  };

  const handleCancel = () => navigate(PATHS.EQUIPMENT_REGLAMENTS);

  useEffect(() => {
    regulation && changeTitlePage(regulation?.name || "");
  }, [regulation, changeTitlePage]);

  useEffect(() => () => changeTitlePage(""), [changeTitlePage]);

  useEffect(() => {
    setIsErrorRequest(errorGetRegulaion);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorGetRegulaion]);

  useEffect(() => {
    setIsErrorSubmit(isError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  useEffect(() => {
    setIsSuccessSubmit(isSuccess);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  return (
    <Box className="object">
      <Box className="template_page-header">
      <Typography className="title-page">
          {regulation?.name || ""}
        </Typography>
        <Box className="template_page-header-buttons">
          <Button className="btn btn-outlined-grey" onClick={onClose || handleCancel}>
            {t("button:comeback")}
          </Button>
          <Button
            form="editRegulationForm"
            className="btn btn-primary-fill"
            type="submit"
            disabled={isSuccess}
          >
            {t("button:save")}
          </Button>
        </Box>
      </Box>
      {!isLoadingRegulation && !!regulation ? (
            <BasicForm regulation={regulation} onSubmit={onSubmit} style={style} isErrorRequest={errorGetRegulaion} />
      ) : (
        <Typography className="object-content-empty_text">
          {t("text_empty_page:edit_reglament")}
        </Typography>
      )}

    </Box>
  );
};

export default EditReglamentPage;
