import { ROLES } from "../../../../constants";
import { getIsDisabledField } from "./options";

export const getConfigByRole = ({ roleId, config }) => {
  const roleUser = ROLES[roleId];
let arr = config.map((item) => ({
  ...item,
  disabled: false,
  role: roleUser, 
}));

  return arr
};
export const getFacilityAndFireCompartmentOptionsGroup = (data) => 
  data.map(el => ({
    id: el.id,
    name: el.name,
    items: el.fireCompartments,
    open: false,
  }));

export const getRequestData = (data) => {
  const { jobFiles, priority, jobChecklist } = data || {};
  const entities = {
    ...data, 
    priorityId: priority,
    
    jobFiles:[],
    jobChecklist: jobChecklist?.map(({ id, isCompleted }) => ({ id, isCompleted })) || [],
    jobEquipments: [],
    
  };
  
  delete entities.jobType;
  delete entities.priority;

  return {entities, jobFiles};
};

export const getRequestDataFilesForAdd = (id, files) => {
  const formData = new FormData();

  if(!files) {
      return formData;
  }

  const { filesToAdd, filesToDelete } = files || {};
  let fileConfigIndex = 0;

  if(filesToAdd?.length) {
    filesToAdd.forEach((file, index) => {
        fileConfigIndex += index
        formData.append(`entityFileList[${fileConfigIndex}].EntityId`, id);
        formData.append(`entityFileList[${fileConfigIndex}].EntityField`, "Job");
        formData.append(`entityFileList[${fileConfigIndex}].EntityType`, "Job");
        formData.append(`entityFileList[${fileConfigIndex}].File`, file);
        formData.append(`entityFileList[${fileConfigIndex}].ActionType`, "Add");
    })
    fileConfigIndex++
  }
  if(filesToDelete?.length) {
    filesToDelete.forEach((file, index) => {
        if (file.id) {
            fileConfigIndex += index
            formData.append(`entityFileList[${fileConfigIndex}].EntityId`, id);
            formData.append(`entityFileList[${fileConfigIndex}].EntityField`, "Job");
            formData.append(`entityFileList[${fileConfigIndex}].EntityType`, "Job");
            formData.append(`entityFileList[${fileConfigIndex}].id`, file.id);
            formData.append(`entityFileList[${fileConfigIndex}].ActionType`, "Delete");
        }
    })
    fileConfigIndex++;
  }

  return formData;
};
export const getEquipmentTypeIdOptionsGroup = (data) => {
  if (!Array.isArray(data)) {
    return [];
  }

  return data.map((el) => ({
    id: el?.id,
    name: el?.title,
    items: el?.equipmentTypes,
    open: false,
  }));
};