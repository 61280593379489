import React, { useMemo, useEffect, useRef, useState } from "react";
import { Box, Typography, Tooltip, Button, Slider } from "@mui/material";
import { useWatch } from "react-hook-form";
import { useAction } from "../../../../hooks/actions";
import { useAppSelector } from "../../../../hooks/redux";
import { config, formFields } from "../form-config";
import { getRoomAddressForScheme } from "../../../../helpers/helper";
import { StagingToCanvas } from "../../../components/DrawCanvas/StagingToCanvas";
import { MissingFloorSchemaForm } from "./MissingFloorSchemaForm";
import { ListEvents } from "./ListEvents";
import {
    useCalculateFloorSchemeParamsMutation

} from "../../../../middleware/equipmentsApi";
import PageheaderNav from "../../../components/PageheaderNav";
import FormItem from "../../../components/FormItem";
import SelectSimple from "../../../components/SelectSimple";
import { useTranslation } from "react-i18next";

export const StagingTO = ({
    control,
    floorScheme,
    setValue,
    getValues,
    draggable = false,
    groupClick,
    handleListItemClick,
    selectedListItem,
    loadScheme,
    changeRoomAddresses,
    itemsNav,
    setzoomOut,
    zoomOut,
    schemepage,
    logsRooms,
    openEventSide,
    equipGroup = false,
    setopenEventSide,
    logsMessagess,
    setisChangeFloor,
    isChangeFloor,
    isLoading,
    scaleCanvas,
    setScale,
    stageposition,
    setstageposition,
    setopenEquipDialog,
    setFloorScheme,
    isChangefloorScheme,
    setSelectedEquipId,
    isListClick,
    stageRef,
    canvasRef,
    imageRef,
    setisListClick,
    handleAddPlume,
    handleEditPlume,
    handlestagingTO,
    handleTO,
    minScale,
    setminScale,
    StartTO, roomsColors,
    searchObject,
    setsearchObject,
    lineColor
}) => {
    const { SCHEMA_CANVAS, openSidebar } = useAppSelector(state => state.settings);
    let wwwidth = openSidebar ? window.innerWidth - 317 : window.innerWidth - 96

    const $container = useRef(null);
    const [fullscreen, setFullScreen] = useState(false);
    const [maxScale, setmaxScale] = useState(5);
    const [inputScale, setinputScale] = useState("");

    const [optimalscale, setoptimalscale] = useState(1);
    const [optimaloffsetAddEquip, setoptimaloffsetAddEquip] = useState({ x: 0, y: 0 });

    const [image, setImage] = useState(null);
    const [markScale, setmarkScale] = useState([]);

    const [widthWorkplace, setwidthWorkplace] = useState();
    const [heightWorkplace, setheightWorkplace] = useState();
    const { t } = useTranslation();
    const { floor, roomAddresses } = useWatch({
        control,
    });
    const [
        calculateFloorScheme,
        {
        },
    ] = useCalculateFloorSchemeParamsMutation();


    const { setCanvasSize } = useAction();


    useEffect(() => {
        let change = 0
        if (openEventSide)
            change += 220
        if (openSidebar)
            change += 317
        else
            change += 110
        change += 80
        setwidthWorkplace(() => window.innerWidth - change)
    }, [openSidebar, openEventSide, schemepage?.current?.clientWidth]);
    useEffect(() => {

        let heightButtonBlock = $container?.current?.clientHeight + 40
        console.log("schemepage?.current?.clientWidth", schemepage?.current?.clientHeight, heightButtonBlock)
        setheightWorkplace(() => schemepage?.current?.clientHeight - heightButtonBlock)
    }, [schemepage?.current?.clientHeight,$container?.current?.clientHeight]);

    const {
        width: screenRelatedWidth,
        height: screenRelatedHeight,
        scale,
    } = useMemo(() => {
        let width = 0
        let height = 0

        if (SCHEMA_CANVAS.HEIGHT > SCHEMA_CANVAS.WIDTH) {
            height = heightWorkplace
            width = heightWorkplace / (SCHEMA_CANVAS.HEIGHT / SCHEMA_CANVAS.WIDTH)
        } else {
            width = widthWorkplace
            height = widthWorkplace / (SCHEMA_CANVAS.WIDTH / SCHEMA_CANVAS.HEIGHT)
            if ((height > window.innerHeight - 544 && wwwidth >= 1386) || (height > window.innerHeight - 464 && wwwidth < 1386)) {
                height = heightWorkplace
                width = heightWorkplace / (SCHEMA_CANVAS.HEIGHT / SCHEMA_CANVAS.WIDTH)
            }
        }
        return SCHEMA_CANVAS.WIDTH
            ? {
                width,
                height,
                scale: {
                    x: width / SCHEMA_CANVAS.WIDTH,
                    y: height / SCHEMA_CANVAS.HEIGHT,
                },
            }
            : {};
    }, [SCHEMA_CANVAS,]);

    const screenRelatedRoomAddresses = useMemo(
        () => getRoomAddressForScheme(roomAddresses, scale),
        [roomAddresses, scale]
    );

    const toggleList = () => {
        loadScheme(1, 0, 0, false, false)
        // setstageposition({ x: 0, y: 0 });
        //  setScale(1)

        /*if (getValues(formFields.object) == selectedListItem.protectedObjectId && getValues(formFields.floor) == selectedListItem.floorInfoId) {
            loadScheme(1, 0, 0, false)
        } else {
            setValue(formFields.object, selectedListItem.protectedObjectId)
            setmessageFloor(selectedListItem.floorInfoId)
        }*/
    }
    /* const offsetScalePosition = () => {
 
         setstageposition({ x: 0, y: 0 });
         //setScale((prev) => 1)
         const scaleAdresses = getRoomAddressForScheme(roomAddresses, scale).filter((item) => item.id == selectedListItem.roomAddressId)[0]?.coordinates
         const scaleAdressess = roomAddresses.filter((item) => item.id == selectedListItem.roomAddressId)[0]?.coordinates
         if (scaleAdressess && scaleAdresses) {
             const scalecoordinates = scaleAdressess.filter((item) => item.order == 0)[0]
             const oddIndexValues = scaleAdresses.filter((_, index) => index % 2 !== 0);  // Нечетные по индексу элементы
             const evenIndexValues = scaleAdresses.filter((_, index) => index % 2 === 0);
             const minEvenIndex = Math.min(...evenIndexValues);  // Минимальное значение четных по индексу элементов
             const maxEvenIndex = Math.max(...evenIndexValues);  // Максимальное значение четных по индексу элементов
 
             const minOddIndex = Math.min(...oddIndexValues);  // Минимальное значение нечетных по индексу элементов
             const maxOddIndex = (Math.max(...oddIndexValues));
             const diffX = (maxEvenIndex - minEvenIndex)
             const diffY = (maxOddIndex - minOddIndex)
 
             let scaleStageX = 2;
             let scaleStageY = 2;
             let scaleStage = 2;
             let coorX = 0;
             let coorY = 0;
             scaleStageY = (((screenRelatedHeight - 10) / (maxOddIndex - minOddIndex)));
             scaleStageX = ((screenRelatedWidth - 10) / (maxEvenIndex - minEvenIndex));
             if (diffX > diffY && (scaleStageY > scaleStageX || diffY * scaleStageY <= screenRelatedHeight / scaleStageY) || (diffX <= diffY && screenRelatedWidth < screenRelatedHeight)) {
                 scaleStage = scaleStageX
             } else {
                 scaleStage = scaleStageY
             }
 
             setScale(scaleStage)
             const diffWidth = (screenRelatedWidth - (diffX * scaleStage)) / 2
             const diffHeight = (screenRelatedHeight - (diffY * scaleStage)) / 2
             if (diffX > diffY && (scaleStageY > scaleStageX || diffY * scaleStageY <= screenRelatedHeight / scaleStageY) || (diffX <= diffY && screenRelatedWidth < screenRelatedHeight)) {
                 coorX = (minEvenIndex - (diffWidth / scaleStage)) / scale.x
                 coorY = (minOddIndex - (diffHeight / scaleStage)) / scale.x
             } else {
                 coorX = (minEvenIndex - (diffWidth / scaleStage)) / scale.x
                 coorY = (minOddIndex - (diffHeight / scaleStage)) / scale.x
             }
 
             const offsetX = coorX * scaleStage * scale.y
             const offsetY = coorY * scaleStage * scale.y
             console.log("ABOBA", minEvenIndex, diffWidth, scale.y)
             setstageposition((prev) => ({ x: 0 - offsetX, y: 0 - offsetY }));
             setisListClick(false)
         }
 
     } */
    const handleChangeSliderCommitted = (event, newValue) => {
        const stage = stageRef.current;
        const canvas = canvasRef.current;
        const x = stage.x()
        const y = stage.y()

        const pointTo = {
            x: widthWorkplace / 2 / scaleCanvas - canvas.x() / scaleCanvas,
            y: heightWorkplace / 2 / scaleCanvas - canvas.y() / scaleCanvas,
        };

        if (newValue <= 1 && scaleCanvas > 1)
            loadScheme(1, 0, 0, false, false)
        if (newValue > minScale) {
            const coordinates = [-stage.x() / newValue, -stage.y() / newValue, (screenRelatedWidth - stage.x()) / newValue, (screenRelatedHeight - stage.y()) / newValue];
            groupClick(coordinates, null, scale, screenRelatedHeight, screenRelatedWidth, false);
        } else if (newValue <= minScale) {
            loadScheme()
        }
        setScale(newValue);
        setstageposition({
            x: -(pointTo.x - screenRelatedWidth / 2 / newValue) * newValue,
            y: -(pointTo.y - screenRelatedHeight / 2 / newValue) * newValue,
        });
        // stage.position({ x: -stage.x() * scaleCanvas,y: -stage.y() * scaleCanvas});

    };
    const handleChangeSlider = (event, newValue) => {
        setScale(newValue)
    };

    const zoomOutClick = () => {
        let scaleCanva = scaleCanvas - minScale;
        const canvas = canvasRef.current;
        if (scaleCanva < 1) scaleCanva = 1

        const pointTo = {
            x: screenRelatedWidth / 2 / scaleCanvas - canvas.x() / scaleCanvas,
            y: screenRelatedHeight / 2 / scaleCanvas - canvas.y() / scaleCanvas,
        };
        console.log("stage", {
            x: -(pointTo.x - screenRelatedWidth / scaleCanva) * scaleCanva,
            y: -(pointTo.y - screenRelatedHeight / scaleCanva) * scaleCanva,
        })
        const ppoint = {
            x: -(pointTo.x - screenRelatedWidth / 2 / scaleCanva) * scaleCanva,
            y: -(pointTo.y - screenRelatedHeight / 2 / scaleCanva) * scaleCanva,
        }
        if (scaleCanva <= 1) {
            loadScheme(1, 0, 0, false, false)
        } else if (scaleCanva > 1 && scaleCanva < minScale) {
            loadScheme()
            setScale(scaleCanva)
        } else {
            setstageposition(ppoint);
            setScale(scaleCanva)
            const coordinates = [-ppoint.x / scaleCanva, -ppoint.y / scaleCanva, (widthWorkplace / scaleCanva - ppoint.x / scaleCanva), (heightWorkplace / scaleCanva - ppoint.y / scaleCanva)];
            // const coordinates = [-canvas.x() / scaleCanva, -canvas.y() / scaleCanva, (widthWorkplace *scaleCanva  - canvas.x()) / scaleCanva, (screenRelatedHeight *scaleCanva  - canvas.y()) / scaleCanva];
            groupClick(coordinates, null, scale, canvas.height(), canvas.width(), false);
            //setstageposition()
        }

    }
    const resetPosition = () => {
        setScale(optimalscale)
        setstageposition(optimaloffsetAddEquip)

    }
    const zoomInClick = () => {

        const canvas = canvasRef.current;
        const backImage = imageRef.current;
        let scaleCanva = scaleCanvas + minScale;
        if (scaleCanva > minScale + 5) scaleCanva = minScale + 5
        const workWidth = widthWorkplace > backImage.width() ? backImage.width() : widthWorkplace
        const workHeight = heightWorkplace > backImage.height() ? backImage.height() : heightWorkplace
        const pointTo = {
            x: workWidth / 2 / scaleCanvas - canvas.x() / scaleCanvas,
            y: workHeight / 2 / scaleCanvas - canvas.y() / scaleCanvas,
        };
        const ppoint = {
            x: -(pointTo.x - screenRelatedWidth / 2 / scaleCanva) * scaleCanva,
            y: -(pointTo.y - screenRelatedHeight / 2 / scaleCanva) * scaleCanva,
        }
        if (scaleCanva >= minScale) {

            const coordinates = [-ppoint.x / scaleCanva, -ppoint.y / scaleCanva, (widthWorkplace / scaleCanva - ppoint.x / scaleCanva), (heightWorkplace / scaleCanva - ppoint.y / scaleCanva)];
            // const coordinates = [-canvas.x() / scaleCanva, -canvas.y() / scaleCanva, (widthWorkplace *scaleCanva  - canvas.x()) / scaleCanva, (screenRelatedHeight *scaleCanva  - canvas.y()) / scaleCanva];
            groupClick(coordinates, null, scale, canvas.height(), canvas.width(), false);
        } else if (scaleCanva > 1 && scaleCanva < minScale) {
            loadScheme()
        }
        setstageposition(ppoint);
        setScale(scaleCanva)
        // setstageposition(pointTo);
    }

    const handleBlur = () => {
        if (scaleCanvas < 0.5) {
            setValue(0.5);
        } else if (scaleCanvas > maxScale) {
            setValue(maxScale);
        }
    };
    const calculateFloorOptions = () => {
        if (image) {
            if (image?.width != null && image?.height != null && image?.width != 0 && image?.height != 0) {
                calculateFloorScheme({ FloorInfoId: floor, CanvasHeight: parseInt(heightWorkplace), CanvasWidth: parseInt(widthWorkplace), MinimumDistance: 25 }).then(({ data }) => {
                    setoptimalscale(data.optimalScale)
                    setminScale(data.optimalScale)

                })
            }
        }

    }
    useEffect(() => {
        if (isChangeFloor) {
            calculateFloorOptions();

        }
    }, [image])

    if (isLoading) return;
    return <Box sx={{ display: "flex", maxHeight: window.innerHeight - 330 }}>
        {/*<Button
            onClick={toggleList}
            className="sidebar-arrow-button"
            sx={{
                transform: openSidebar ? "rotate(0deg)" : "rotate(-180deg)",
                minWidth: "0",
                borderRadius: "50% 50%",
                border: "1px solid #4339f270",
                left: openSidebar
                    ? "calc(var(--widthFullSideBar) - var(--arrowBtn) / 2)"
                    : "calc(var(--widthShortSideBar) - var(--arrowBtn) / 2)",
            }}
        >
            <ArrowIconLeftBlue />
        </Button>
        */}

        {(window.innerWidth > 600 || (!openEventSide && window.innerWidth <= 600)) &&
            <Box sx={{ position: "relative  ", width: "100%", }}>

                <Box ref={$container} className="plume-form_wrapper-select_container" sx={{ flexWrap: "wrap", display: "flex", marginBottom: "40px", padding: "0px 32px 0px 0px", alignItems: "end", justifyContent: "space-between" }}>
                    <PageheaderNav itemsNav={itemsNav} />
                    <Box sx={{ display: "flex", gap: "16px" }}>
                        {!openEventSide &&
                            <Box className="full_screen_icon-wrapper" sx={{ alignItems: "end" }}>

                                <Box
                                    className="list"
                                    onClick={() => setopenEventSide(true)}
                                />
                            </Box>
                        }
                        {config.map(({ id, name, label, type, isCanDisabled, ...rest }) => (
                            <FormItem
                                key={id}
                                name={name}
                                control={control}
                                label={label}
                                type={type}
                                searchObject={searchObject}
                                setsearchObject={setsearchObject}
                                input={(props) => <SelectSimple {...props} />}
                                {...rest}
                            />
                        ))}
                    </Box>
                    <Box sx={{ display: "flex", gap: "16px", alignItems: "end" }}>
                        {floorScheme ?
                            <Box>
                                <Box sx={{
                                    display: "flex", gap: "16px", border: "1px solid #EBEBF2", borderRadius: "50px", padding: "5px 14px", alignItems: "center"
                                }}>
                                    <Box
                                        className="less"
                                        onClick={() => { zoomOutClick() }}
                                    />
                                    <Slider
                                        value={scaleCanvas}
                                        step={0.1}
                                        min={0.5}
                                        max={minScale + 5}
                                        onChangeCommitted={handleChangeSliderCommitted}
                                        sx={{ color: "#4339F2", width: "200px" }}
                                    />

                                    <Box
                                        className="more"
                                        onClick={() => { zoomInClick() }}
                                    />
                                </Box>

                            </Box>
                            :
                            <Box></Box>

                        }
                        <Button
                            className="btn btn-primary-fill magazine_events-select_container-btn"
                            variant="outlined"
                            sx={{ height: "52px" }}
                            onClick={handlestagingTO}

                        >
                            {t("button:staging_to_TO")}
                        </Button>
                    </Box>
                </Box>

                <Box sx={{ display: "flex", height: heightWorkplace }}>
                    {logsMessagess && (openEventSide) &&
                        <ListEvents
                            toggleList={toggleList}
                            handleListItemClick={handleListItemClick}
                            selectedListItem={selectedListItem}
                            logsMessagess={logsMessagess}
                            width={screenRelatedWidth || 0}
                            height={screenRelatedHeight || 0}
                            scale={scale}
                            StartTO={StartTO}
                            handleTO={handleTO}
                            openSidebar={openEventSide}
                            setopenEventSide={setopenEventSide}
                        />
                    }


                    {floorScheme ? (
                        <StagingToCanvas
                            name={`${formFields.equipmentsCoordinates}`}
                            width={screenRelatedWidth || 0}
                            height={screenRelatedHeight || 0}
                            schemaImg={floorScheme}
                            roomAddresses={screenRelatedRoomAddresses}
                            setValue={setValue}
                            formFields={formFields}
                            getValues={getValues}
                            loadScheme={loadScheme}
                            groupClick={groupClick}
                            roomsColors={roomsColors}
                            widthWorkplace={widthWorkplace}
                            heightWorkplace={heightWorkplace}
                            setopenEquipDialog={setopenEquipDialog}
                            minScale={minScale}
                            setSelectedEquipId={setSelectedEquipId}
                            equipGroup={equipGroup}
                            logsRooms={logsRooms}
                            canvasRef={canvasRef}
                            imageRef={imageRef}
                            stageRef={stageRef}
                            setzoomOut={setzoomOut}
                            zoomOut={zoomOut}
                            selectedEvent={selectedListItem}
                            scale={scaleCanvas}
                            image={image}
                            setImage={setImage}
                            maxScale={maxScale}
                            scalecanvas={scale}
                            setScale={setScale}
                            stageposition={stageposition}
                            setstageposition={setstageposition}
                            control={control}
                            lineColor={lineColor}
                            draggable={draggable}
                            fullscreen={fullscreen}
                            setFullScreen={setFullScreen}

                        />
                    ) : getValues(formFields.object) != "все" ? (
                        <Box className="plume-image_container-schema">
                            <MissingFloorSchemaForm floor={floor} setFloorScheme={setFloorScheme} />
                        </Box>
                    ) :
                        (
                            <Box></Box>
                        )
                    }
                </Box>
            </Box>
        }
    </Box >

};
