import React from "react";
import { Box, Typography } from "@mui/material";

import { formFields, getDynamicFormFields } from "../form-config";

import FormItem from "../../../../components/FormItem";
import { useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";

const BasicInformationForm = ({ control, errors, config, setValue, data }) => {
  const { t } = useTranslation();

  const { fields } = useFieldArray({
    control,
    name: formFields.regulations,
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "flex-start",
        alignItems: "flex-end",
        columnGap: "15px",
      }}
    >
      {config.map(({ name, title, label, input, Component, flexBasis, ...rest }, i) => (
        Component
          ? <Component
              key={i}
              data={data}
              control={control}
              setValue={setValue}
            />
          : (
              <Box key={i} className="resizing_basic_forms">
                {!!title && <Typography className="label">{t(title)}</Typography>}
                <FormItem
                  control={control}
                  errors={errors}
                  name={name}
                  label={label}
                  input={input}
                  {...rest}
                />
              </Box>
            )
      ))}

      {!!fields?.length && (

          <Box className="block_form-wrapper">
            {getDynamicFormFields(fields).map(
              ({ name, label, input, ...rest }) => (
                <FormItem
                  key={name}
                  control={control}
                  errors={errors}
                  name={name}
                  label={label}
                  input={input}
                  {...rest}
                />
              )
            )}
          </Box>
      )}
    </Box>
  );
};

export default BasicInformationForm;
