import React, { useEffect, useState,useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  OutlinedInput,
  InputLabel,
  MenuItem,
  ListItemText,
  FormControl,
  Select,
  Typography,
  Box
} from "@mui/material";
import selectArrowUp from "../../../image/icons/selectArrowUp.png";
import selectArrowDown from "../../../image/icons/selectArrowDown.png"
import { MyListSubheader, MyListSubheaderSearch } from "./MyListSubheader";

const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 13;
const SEARCH_HEIGHT = 72;

const SelectGrouping = ({
  options,
  label,
  onChange,
  error,
  value,
  searchValue,
  setSearchValue,
  IsTableheader = false,
  defaultValue = 0,
  bgcolor,
  height = "",
  setValue,
  disabled = false,
  isRegulation,
  ...rest
}) => {
  const { t } = useTranslation();
  
  const [updatedOptions, setUpdatedOptions] = useState([]);
  const allOptionItemsCount = useMemo(() => {
    let totalCount = 0;
    totalCount += updatedOptions?.length;
    
    updatedOptions?.map((parent) => {
      if (parent?.open) totalCount += parent?.items?.length;
    })
   
    return totalCount;

  }, [updatedOptions])
  const MenuProps = {
    autoFocus: false,
    PaperProps: {
      style: {
        maxHeight: allOptionItemsCount>=4 ? (ITEM_HEIGHT+ ITEM_PADDING_TOP) * 4 + SEARCH_HEIGHT : (ITEM_HEIGHT+ ITEM_PADDING_TOP) * allOptionItemsCount + SEARCH_HEIGHT ,
      },
    },
  };
  const [selectedValue, setSelectedValue] = useState(value);
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
    setSearchValue("")
  };
  useEffect(() => {
    setUpdatedOptions(options);

    if (options?.length) {
      if (Number(value)) {

        const selectedItem = options
          ?.reduce((sum, { items }) => [...sum, ...items], [])
          ?.find(({ id }) => id === value);

        setSelectedValue(JSON.stringify(selectedItem) || defaultValue === 0 ? value : JSON.stringify(defaultValue));
      } else {
        setSelectedValue(value || defaultValue === 0 ? value : JSON.stringify(defaultValue));
      }
    }
  }, [options, value, defaultValue]);
  console.log("updatedOptionss", selectedValue)
  return (
    <Box sx={{ position: "relative" }} className="input_text">
      {!IsTableheader && <InputLabel sx={{ mb: "8px" }}>
        {t(label)}
      </InputLabel>
      }
      <FormControl fullWidth error={!!error && !disabled} sx={{ background: bgcolor ? bgcolor : "inherit", height: height }} {...rest}>
        <Select
          value={selectedValue}
          defaultValue={defaultValue}
          disabled={disabled}
          label={t(label)}
          input={<OutlinedInput />}
          onChange={onChange}
          MenuProps={MenuProps}
          open={isOpen}
          onOpen={handleOpen}
          displayEmpty={IsTableheader ? true : false}
          inputProps={{ 'aria-label': 'Without label' }}
          onClose={handleClose}
          IconComponent={() =>
            <Box sx={{ mr: "24px", cursor: "pointer" }} onClick={() => { setIsOpen((prev) => !prev) }}>
              {isOpen ? (
                <img src={selectArrowUp} />
              ) : (
                <img src={selectArrowDown} />
              )
              }
            </Box>}
          sx={{
            ".MuiSelect-select": {
              display: "flex",
              alignItems: "center",
              height: 0,
            },
            maxHeight: "56px",
          }}
          {...rest}
        >
          <MyListSubheaderSearch
            setSearchValue={setSearchValue}
            searchValue={searchValue}
          />
          {IsTableheader &&
            <MenuItem disabled value=""  sx={{ height: ITEM_HEIGHT + ITEM_PADDING_TOP }}>
              {t(label)}
            </MenuItem>
          }
          {updatedOptions?.length ? (
            updatedOptions.map((option) => {
              const { open, items } = option || {};

              if (!items) {
                const { id, name } = option;
                return (
                  <MenuItem key={id} value={id} sx={{ height: ITEM_HEIGHT + ITEM_PADDING_TOP }}>
                    <ListItemText primary={name || ""} />
                  </MenuItem>
                );
              }
              if (items && items.length == 1) {
                const { id, name } = option;
                return (
                  <MenuItem
                    sx={{ height: ITEM_HEIGHT + ITEM_PADDING_TOP }}
                    key={id} value={JSON.stringify({ parentId: option.id, id: items[0]?.id, name: `${name}, ${items[0]?.name}` }) || ""}>
                    <ListItemText sx={{
                      paddingLeft: "10px",
                    }} primary={`${name}, ${items[0]?.name}` || ""} />
                  </MenuItem>
                );
              }

              const Items = items?.length
                ? items.map((item) => {
                  const { id, name } = item;
                  return (
                    <MenuItem
                      key={id}
                      sx={{
                        display: `${open ? "block" : "none"}`,
                        paddingLeft: "70px",
                        height: ITEM_HEIGHT + ITEM_PADDING_TOP
                      }}
                      value={JSON.stringify({ ...item, parentId: String(isRegulation ? option.id : id), id: String(isRegulation ? id : option.id), name: `${option.name}, ${name}` }) || ""}
                    >
                      <Typography>
                        {`${name}`}
                      </Typography>
                    </MenuItem>
                  );
                })
                : null;

              return [
                <MyListSubheader
                  key="MyListSubheader"
                  setOptions={setUpdatedOptions}
                  options={updatedOptions}
                  itemOption={option}
                />,
                Items,
              ];
            })
          ) : (
            <MenuItem disabled={true} sx={{ height: ITEM_HEIGHT + ITEM_PADDING_TOP }}>
              <ListItemText primary={t("label:nothingFound")} />
            </MenuItem>
          )}
        </Select>
      </FormControl>
    </Box>
  );
};

export default SelectGrouping;
