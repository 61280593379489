import React, { useState, useEffect } from "react";
import { Box, Typography, CircularProgress, Backdrop } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAction } from "../../../hooks/actions";

import { getDefaultSatrtDate, getDefaultEndDate } from "../Analytics/helpers";

import DateRangePicker from "../../components/DateRangePicker/DateRangePicker";
import { TimelineThing } from "./TimelineThing";
import { useGetCalendarPlanQuery } from "../../../middleware/calendarPlanApi";

export const TimeTable = () => {
  const { t } = useTranslation();

  const [startDate, setStartDate] = useState(getDefaultSatrtDate());
  const [endDate, setEndDate] = useState(getDefaultEndDate());

  const { setIsErrorRequest } = useAction();

  const {
    data: calendarPlan,
    error: errorCalendarPlan,
    status,
  } = useGetCalendarPlanQuery({ startDate, endDate }, { refetchOnMountOrArgChange: true });

  useEffect(() => {
    setIsErrorRequest(errorCalendarPlan);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorCalendarPlan]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "20px",
          alignItems: "center",
        }}
      >
        <Typography className="title-page">
          {t("title_page:time_table")}
        </Typography>
        <DateRangePicker
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />
      </Box>

      {status === 'fulfilled' && <Box sx={{ marginTop: "20px" }}>
        <TimelineThing calendarPlan={calendarPlan} />
      </Box>}

      <Backdrop sx={{ color: "var(--white)", zIndex: 9999 }} open={status === "pending"}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};
