import React, { useCallback, useState, useEffect } from "react";
import {
  Map,
  YMaps,
  SearchControl,
  Clusterer,
  ZoomControl,
} from "@pbe/react-yandex-maps";
import { Typography, Box, Button } from "@mui/material";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./styles/styles.css";

import { YANDEX_MAPS_DATA } from "../MyOrganizations/constants";
import { useChartsIcons } from "../EquipmentDetails/hooks";
import { useLazyGetFacilitiesMapElementsInfoQuery } from "../../../middleware/facilitiesApi";

import CustomPlacemark from "../../components/DonurChartMarker/indxex";
import MapPopUp from "../../components/MapPopUp";
import { PATHS } from "../../../constants";
import DonutChart from "../../components/DonutChart";
import { useAppSelector } from "../../../hooks/redux";
import SetNewPasswordDialog from "../Profile/SetNewPasswordDialog";
import CountOfTasksWidget from "../../components/CountOfTasksWidget";
import NewsWidget from "../../components/NewsWidget";
import { ArrowInWidgets } from "../../components/icons/NewArrowIcons/ArrowInWidgets";

const COLORS = ["#F8F9FD", "#F8F9FD", "#BFCCDB", "#9CA9BD", "#A4A8FC"];

const ObjectsOnMap = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const mapRef = React.createRef(null);
  const clusterRef = React.createRef(null);
  const [isMapPopUpOpen, setIsMapPopUpOpen] = useState(false);
  const [selectedMarkerID, setSelectedMarkerId] = useState("");
  const [facilitiesMapElements, setFacilitiesMapElements] = useState([]);
  const token = useAppSelector((state) => state.users.token);
  const isLoggedBefore = localStorage.getItem("isLoggedBefore");
  const [passwordDialog, setPasswordDialog] = useState(
    isLoggedBefore === "false" ? false : true
  );
  const handlePasswordDialog = () => setPasswordDialog(!passwordDialog);

  const [mapState, setMapState] = useState(YANDEX_MAPS_DATA.defaultState);
  const [ChartsList, getSrcSVG] = useChartsIcons({
    Chart: DonutChart,
    equipments: facilitiesMapElements,
    ismap: true
  });
  const { openSidebar } = useAppSelector(state => state.settings);
  const [getFacilitiesMapElementsInfo, { isLoading: isLoadingFacilities }] =
    useLazyGetFacilitiesMapElementsInfoQuery();

  useEffect(() => {
    (async function () {
      const data = await getFacilitiesMapElementsInfo();
      const filterData = data?.data?.filter((item) => item?.address !== null);
      setFacilitiesMapElements(filterData);
    })();
  }, [getFacilitiesMapElementsInfo]);

  const popUpHandler = useCallback(
    (id) => {
      setSelectedMarkerId(id);
      setIsMapPopUpOpen(true);
    },
    [setSelectedMarkerId]
  );

  useEffect(() => {
    if (!isLoadingFacilities && facilitiesMapElements?.length > 0) {
      const bounds = getMapBounds();
      const newMapState = calculateMapState(bounds);
      setMapState((prev) => ({ ...prev, ...newMapState }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingFacilities, facilitiesMapElements]);

  function getMapBounds() {
    const bounds =
      !isLoadingFacilities &&
      facilitiesMapElements?.reduce(
        (acc, obj) => {
          if (obj?.address) {
            return [
              [
                Math.min(acc[0][0], obj?.address?.coordinates?.latitude),
                Math.min(acc[0][1], obj?.address?.coordinates?.longitude),
              ],
              [
                Math.max(acc[1][0], obj?.address?.coordinates?.latitude),
                Math.max(acc[1][1], obj?.address?.coordinates?.longitude),
              ],
            ];
          } else {
            return acc;
          }
        },
        [
          [Infinity, Infinity],
          [-Infinity, -Infinity],
        ]
      );
    return bounds;
  }

  function calculateMapState(bounds) {
    const [[minLat, minLon], [maxLat, maxLon]] = bounds;

    const center = [(minLat + maxLat) / 2, (minLon + maxLon) / 2];
    const isSingleMarker = minLat === maxLat && minLon === maxLon;
    let zoom;
    if (isSingleMarker) {
      zoom = 12;
    } else {
      const deltaLat = Math.abs(maxLat - minLat);
      const deltaLon = Math.abs(maxLon - minLon);
      const maxDelta = Math.max(deltaLat, deltaLon);
      const coefficient = maxDelta <= 6 ? 0 : maxDelta > 11 ? 2 : 1;

      zoom = Math.floor(Math.log2(360 / maxDelta)) + coefficient;
    }
    return {
      center,
      zoom,
    };
  }

  function getRegions(ymaps) {
    if (mapRef && mapRef.current) {
      let objectManager = new ymaps.ObjectManager();
      ymaps.borders
        .load("RU", {
          lang: "ru",
          quality: 3,
        })
        .then(function (result) {
          let queue = [];
          let regions = result.features?.reduce(function (acc, feature) {
            let iso = feature.properties.iso3166;
            feature.id = iso;
            feature.options = {
              fillOpacity: 0.5,
              strokeColor: "#808080",
              strokeOpacity: 0.5,
            };
            acc[iso] = feature;
            return acc;
          }, {});
          function paint(iso) {
            let allowedColors = COLORS.slice();
            let region = regions[iso];
            let neighbors = region.properties.neighbors;
            if (region.options.fillColor) {
              return;
            }
            if (neighbors.length !== 0) {
              neighbors.forEach(function (neighbor) {
                const fillColor = regions[neighbor].options.fillColor;
                if (fillColor) {
                  let index = allowedColors.indexOf(fillColor);
                  if (index !== -1) {
                    allowedColors.splice(index, 1);
                  }
                } else if (queue.indexOf(neighbor) === -1) {
                  queue.push(neighbor);
                }
              });
            }
            region.options.fillColor = allowedColors[0];
          }
          for (let iso in regions) {
            if (!regions[iso].options.fillColor) {
              queue.push(iso);
            }
            while (queue.length > 0) {
              paint(queue.shift());
            }
          }
          result.features = [];
          for (let reg in regions) {
            result.features.push(regions[reg]);
          }
          objectManager.add(result);
          mapRef.current.geoObjects.add(objectManager);
        });
    }
  }
  const onMarkerClickk = (e) => {
    const newCenter = e.get("target").geometry.getCoordinates();
    console.log("newCenter", newCenter); // получить координаты кликнутой метки
    setMapState({ center: newCenter, zoom: 12 }); // изменить центр и масштаб карты при клике на метку
  };
  const onMarkerClick = (e) => {
    const newZoom = mapRef.current.getZoom() + 5;
    console.log("newCenter", newZoom);
    const currentPosition = e.get("target").geometry.getCoordinates();
    mapRef.current.setCenter(currentPosition, newZoom);
  };
  const onTaskStateClick = (id, props) => {
    navigate(`${PATHS.LIST_TASKS}?facilityName=${id}&status=${props}`);
  };
  const House = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADoAAAA8CAYAAAA34qk1AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAPBSURBVHgB7ZpLTxNRFIDPnSkFNJQaHm15hIJGQjTYLpTEBaFRV7gA3LkRfwElSlnKVljQn8AfMNXEnTGQkJiQmIw81OAChqCh+IgVo0Jxer1nYMq0tNDSaWmv8yVN2jtzp/0658w5dzIABcY1ujjkDCzdgwJDoEDY/ZK9otwSAkp78DMFeEqIMhx+7JVT7wseyAJ2nJmjthdEtHFE8ihEDLG37qRNMhDFlyzrCiyE2B/RB9lAYSY80elLt1mAPOMKzPtjRJyGw5KgjlFx1fFw3q8fZJJZnU0VkvL4cSyQJzD8KsuEMQpkSBsrLxPh9tUW+PE7CrNLG7DzV9n7jQKZdAQWWjbHO4eTj1Nnq1DnpePjt1+QCXkJXeeo5KYxIUQIiZ+Z2upKVdJWWaZ+3vqzC09ercBPJq1DDWV2luMRcP9GO1Sdsab8ni02d+rlcnxueLyzFdJgeOg6Rxd7CBUlvWRH8zm4c70tLong+7vdF8DTVqufjqE8zf59OxiMoaGL+UgpnaS6se7LDeBprUm5P4Zk9yUXlFtEmPuwqQ27KRiPIaJqObAKQZaP8fpoY+F2y9MEjTVnj53f1V4PbU4bPH+9lhzKhpFz6GI+VlhFCXSSTUxugIVqJpIaddUVangnz7FaRTCCnESdI9Ig5iPoSgfm3EBSPmYKzkHZrouO+LEwrI3gxKHrGpkPUkKGtHzay7cGduHJ/TqCoYwvI8laVGvl6H4rh2A+9rLSgTWvWMlKNN7KUerWxjAfe6+5WYjlvcnKiYxF1VYOyCPQ1biudgfLJ2NDLF9kJKrmY4pWLpur6mlzpKjWytEjWrlSIW1iYSuntmPHtHLFAqVgr38gpV3apRTFfGQzE5ZW2Mphp1NMFx1MIa3OEgJ2QRRDrLaPpdo3YfWitXJwwlbuNEi5CiIwtb2jDEeC3sjB0D6Yj/rlEYKl46a3uejzEWVfSOvwKXFtmnD3QhXF+oh3AaiudGD7hSuLUmJu+bN+FYTIClX6v0x436iizsDCKuyfSSNbudNAWvkKs2839EPydlTxEnYjqo+dSbxxVRKtXCYczlthUGCNwBVth44me8lLInhNOe+o0o1Qd3E3qDmQfEONW9FkTFHeMEV5wxTlDVOUN0xR3jBFecMU5Q1TlDdMUd4wRXnDFOUNU5Q3TFHeMEV5478RtQCla9qTDO/XvwMvvEtwicnE7ZfsO1Zxlebh8e7igMoQjfkEOeiNCFTxqQOcwQI1ItJYfzjolQ8ev/FLbiiDHiCWFuACIm9Hd59pzxr9A+c3K7o+G/juAAAAAElFTkSuQmCC'
  const MyIconContentLayout = window.ymaps.templateLayoutFactory?.createClass(
    '<div style="font-family:Inter;color:#fff;margin-top:5px;font-weight:bold;font-size:16px">' +
    '{{ properties.geoObjects.length }}' +
    '</div>')
  return (
    <Box>
      {!passwordDialog && (
        <SetNewPasswordDialog
          token={token}
          open={!passwordDialog}
          firstEntered={true}
          onClose={handlePasswordDialog}
        />
      )}

      <ChartsList />
      <Box className="legal-entity-header">
        <Typography className="title-page">
          {t("title_page:objects_on_map")}
        </Typography>

        <div className="objects-on-map-url">
          <Link to={PATHS.LIST_OBJECTS}>
            {t("title_page:show_objects_on_list")} <ArrowInWidgets />
          </Link>
        </div>
      </Box>

      <Box>
        <div className="widgets-container">
          <CountOfTasksWidget />
          <NewsWidget />
        </div>

        <Box
          sx={{
            p: "16px 32px",
            boxSizing: "border-box",
            background: "var(--bgWhite)",
            mt: "40px",
            borderRadius: "32px",
          }}
        >
          <YMaps query={YANDEX_MAPS_DATA.query}>
            <Map
              modules={YANDEX_MAPS_DATA.modules}
              defaultOptions={YANDEX_MAPS_DATA.defaultOptions}
              instanceRef={mapRef}
              state={mapState}
             // onLoad={getRegions}
              width={openSidebar ? "calc(100vw - 484px)" : "calc(100vw - 264px)"}
              height="calc(100vh - 490px)"
            >
              <SearchControl
                options={{
                  float: "left",
                  placeholderContent: "Введите адрес объекта",
                }}
              />
              <ZoomControl options={{ float: "right" }} />
              <Clusterer
                instanceRef={clusterRef}
                options={{
                  clusterIcons: [
                    {
                      href: House,
                      size: [46, 44],
                      offset: [-20, -20]
                    },
                    {
                      href: House,
                      size: [64, 62],
                      offset: [-30, -30]
                    }],
                  clusterNumbers: [100],
                  clusterIconContentLayout: MyIconContentLayout,
                  clusterHideIconOnBalloonOpen: false,
                  geoObjectHideIconOnBalloonOpen: false,
                  //clusterDisableClickZoom: true,
                  //clusterOpenBalloonOnClick: true,
                  clusterBalloonPanelMaxMapArea: 0,
                  clusterBalloonContentLayoutWidth: 350,
                  clusterBalloonContentLayoutHeight: 150,
                }}
              >
                {!isLoadingFacilities &&
                  facilitiesMapElements?.map(
                    (item, index) =>
                      item?.address && (
                        <CustomPlacemark
                          key={item?.id}
                          id={item?.id}
                          geometry={[
                            item?.address?.coordinates?.latitude,
                            item?.address?.coordinates?.longitude,
                          ]}
                          name={item?.name}
                          jobChart={item?.jobChart}
                          onClick={onMarkerClick} //}
                          onClickPopUp={popUpHandler}
                          onTaskStateClick={onTaskStateClick}
                          linkChart={getSrcSVG(index)}
                        />
                      )
                  )}
              </Clusterer>
            </Map>
          </YMaps>
          {isMapPopUpOpen && (
            <MapPopUp
              selectedMarkerID={selectedMarkerID}
              setIsMapPopUpOpen={setIsMapPopUpOpen}
              setSelectedMarkerId={setSelectedMarkerId}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ObjectsOnMap;
