import { getEquipmentsCoordinatesWithRoomId } from "../../../helpers/helper";
import { floorSchemeInfo } from "./form-config";

export const getRequestEditData = (data) => {
  const { id, workingCorrectly, equipmentsCoordinates,floor, roomAddresses=[],roomAddressId, isActive,name,plume,placement,integrationNumber,manufactureDate,installationDate,number } = data || {};
  const { floorSchemeCoordinateX, floorSchemeCoordinateY, typeId } =
    equipmentsCoordinates[0] || {};
    console.log("equipmentsCoordinates",data)
  const placementEquip = placement == "Запотолочное пространство" ? 0 : placement == "Подпольное пространство" ? 1 : 2
  return {
    name,
    plume,
    integrationNumber:integrationNumber,
    manufactureDate:manufactureDate,
    installationDate:installationDate,
    id,
    floorInfoId:floor !== "" ? floor : null,
    placement:placementEquip,
    workingCorrectly,
    equipmentCoordinateX: floorSchemeCoordinateX,
    equipmentCoordinateY: floorSchemeCoordinateY,
    roomAddressId: roomAddressId !== "" ? roomAddressId : null,
    typeId,
    isActive,
    number
  };
};