import React from "react";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Box ,InputLabel} from "@mui/material";

import { dateFormat, dateFormatForSubmit } from "../../../constants";
import calendar from "../../../image/icons/calendar.png"

const CalendarIcon = () => (
  <img src={calendar} alt="Calendar" />
);
const DateRangePicker = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}) => {
  const handleStartDateChange = (date) => {
    setStartDate(dayjs(date.$d).format(dateFormatForSubmit));
  };

  const handleEndDateChange = (date) => {
    setEndDate(dayjs(date.$d).format(dateFormatForSubmit));
  };

  return (
    <Box>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={["DatePicker"]} sx={{padding:0}}>
          <Box sx={{ position: "relative" }} className="input_text">
            <InputLabel sx={{ mb: "8px" }}>
              {"Выберите начальную дату"}
            </InputLabel>
            <DatePicker
              value={startDate ? dayjs(startDate) : null}
              onChange={handleStartDateChange}
              slots={{ openPickerIcon: CalendarIcon }}
              format={dateFormat}
            />
          </Box>
          <Box sx={{ position: "relative" }} className="input_text">
            <InputLabel sx={{ mb: "8px" }}>
              {"Выберите конечную дату"}
            </InputLabel>
            <DatePicker
              value={endDate ? dayjs(endDate) : null}
              onChange={handleEndDateChange}
              slots={{ openPickerIcon: CalendarIcon }}
              format={dateFormat}
            />
          </Box>
        </DemoContainer>
      </LocalizationProvider>
    </Box>
  );
};

export default DateRangePicker;
