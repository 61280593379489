import React, { useMemo } from "react";
import { Box, Chip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import InputFileBtn from "../InputFileBtn";
import InputFileWithPreview from "./InputFileWithPreview";
import ClearIcon from "@mui/icons-material/Clear";
import InputFileAvatarPreview from "./InputFileAvatarPreview";
import { PreviewFileBlock } from "../PreviewFileBlock";
import { Link } from 'react-router-dom';
import DownloadIcon from '@mui/icons-material/Download';
import close from "../../../../image/icons/closeInputFile.png";
const InputFileSingle = ({
  onChange,
  error,
  label,
  preview,
  previewMode,
  value,
  canDeleteFile,
  disabled,
  size,
  direction = "initial",
  uploadBtnText = "button:uploadSingle",
  accept = ""
}) => {
  const { t } = useTranslation();

  const { filePrev, fileToAdd, filesToDelete } = useMemo(
    () => ({
      filePrev: value.filesPrev?.length ? value.filesPrev[0] : null,
      fileToAdd: value.filesToAdd?.length ? value.filesToAdd[0] : null,
      filesToDelete: value.filesToDelete || [],
    }),
    [value]
  );
  const handleChange = (event) => {
    const newFile = event.target.files[0];
    onChange({
      filesPrev: filePrev ? [filePrev] : [],
      filesToAdd: [newFile],
      filesToDelete: filePrev ? [filePrev] : [],
    });
  };

  const handleRemoveFile = () => {
    onChange({
      filesPrev: filePrev ? [filePrev] : [],
      filesToAdd: [],
      filesToDelete: filePrev ? [filePrev] : [],
    });
  };

  const shouldDeletePrevFile = () => {
    return (
      !filePrev ||
      (filesToDelete.length &&
        filesToDelete.find((file) => filePrev.id === file.id))
    );
  };


  if (previewMode === "avatar") {
    return (
      <InputFileAvatarPreview
        label={label}
        disabled={disabled}
        onChange={handleChange}
        file={shouldDeletePrevFile() ? fileToAdd : filePrev}
        error={error}
        size={size}
      />
    );
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        justifyContent: "stretch",
        boxSizing: "border-box",
        height: "100%",
        position: "relative",
      }}
    >

      {preview ? (
        <InputFileWithPreview
          label={label}
          disabled={disabled}
          handleChange={handleChange}
          file={shouldDeletePrevFile() ? fileToAdd : filePrev}
          handleRemoveFile={handleRemoveFile}
          uploadBtnText={uploadBtnText}
          accept={accept}
        />
      ) : (
        <Box
          className="input_file_block"
          sx={{
            display: "flex",
            gap: direction === "column" ? "20px" : "16px",
            height: "100%",
            flexDirection: direction,
          }}
        >
          <InputFileBtn
            onChange={handleChange}
            disabled={disabled}
            label={uploadBtnText}
            labelBtn={label}
            accept={accept}
            isError={error}
          />
          <Box
            className="input_file_block-preview_field"
            sx={{
              alignItems: "center",
              justifyContent: "center",
              flexDirection: direction,
             
              width:"114px"
            }}
          >


            {(fileToAdd || filePrev) && (
              <Box>
                <Box sx={{ textAlign: "right" }}>
                  <Box
                    onClick={()=>{ canDeleteFile && handleRemoveFile() }}
                    component="img"
                    src={close}
                  />
                </Box>
                <PreviewFileBlock file={shouldDeletePrevFile() ? fileToAdd : filePrev} />
              </Box>

            )}

            {!shouldDeletePrevFile() && (
              <Link
                to={filePrev.fileLink}
                download={filePrev.name || filePrev.fileName}
                target="_blank"
                rel="noreferrer noopener"
                style={{textDecoration: "none !important",color:"#4D5C6E"}}
              >
                <Typography sx={{ textDecoration: "none !important",fontSize: "14px",color:"#4D5C6E", }}>{filePrev.name || filePrev.fileName}</Typography>
                {/*
<Typography
                sx={{
                  p: "10px 12px",
                  bgColor: "var(--bgTableHeader)",
                  maxWidth: "160px",
                  minWidth: "160px",
                }}
                onClick={{}}
                label={<Box sx={{display: "flex"}}></Box>}
                onDelete={canDeleteFile ? handleRemoveFile : null}
                deleteIcon={<ClearIcon />}
                size="small"
              />
               */}

              </Link>
            )}
            {fileToAdd && (
              <Link
                to={fileToAdd.fileLink}
                download={fileToAdd.name}
                target="_blank"
                rel="noreferrer noopener"
                style={{ textAlign: "-webkit-center",textDecoration: "none !important",color:"#4D5C6E" }}
              >
                <Typography sx={{textDecoration: "none !important", fontSize: "14px" }}>{fileToAdd.name}</Typography>
                {/*
<Chip
                  sx={{
                    p: "10px 12px",
                    bgColor: "var(--bgTableHeader)",
                    maxWidth: "160px",
                    minWidth: "160px",
                  }}
                  onClick={{}}
                  label={<Box sx={{display: "flex"}}><Typography sx={{fontSize: "14px"}}>{fileToAdd.name}</Typography><DownloadIcon sx={{fontSize: "20px",marginTop: "2px",color: "var(--main)",}}/></Box>}
                  onDelete={canDeleteFile ? handleRemoveFile : null}
                  deleteIcon={<ClearIcon />}
                  size="small"
                />
               */}
              </Link>
            )}
          </Box>
        </Box>
      )}
      {error && (
        <Typography className="error-text" sx={{ position: "absolute", bottom: "-30px" }}>
          {error.type === "custom"
            ? error.message
            : t(`error_message:${error.message}`)}
        </Typography>
      )}
    </Box>
  );
};

export default InputFileSingle;
