import React from "react";
import { AppBar, Box, Toolbar } from "@mui/material";
import ProfileDropdown from "./ProfileDropdown";
import { NotificationPopUp } from "../../components/NotificationPopUp/NotificationPopUp";
import AdditionalInfo from "./AdditionalInfo";
import { useAppSelector } from "../../../hooks/redux";

import './style.css'

const Header = () => {
  const { openSidebar } = useAppSelector((state) => state.settings);
  return (
    <AppBar className={`app_header ${ !openSidebar ? 'closed-side-bar' : ''}`} position="absolute">
      <Toolbar
        sx={{
          height: "var(--heighHeader)",
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: "var(--bgWhite)",
          padding: '0px 32px 0 22px !important',
        }}
      >
        <Box className="header-navbar-text">
          <AdditionalInfo />
        </Box>

        <Box className="header-navbar-dropdown">
          <NotificationPopUp />
          <ProfileDropdown />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
