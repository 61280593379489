import { ClickableActionNameCell, RatingCell } from "./Components";
import { AddressInputInbuilt } from "../../components/AddressInputInbuilt";
import FormItem from "../../components/FormItem";
import { Box } from "@mui/material";
import { SearchInbuilt } from "../../components/SearchInbuilt";
import { t } from "i18next";
export const getColumns = ({
  control,
  columnsFromStorage,
  searchAddress,
  setsearchAddress,
  phone,
  setphone,
  searchObject,
  setsearchObject,
  searchintNumber,
  setsearchintNumber,
}) =>
  [
    {
      id: "numberAndNameObjects",
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:facilityName"),
      size: "250px",
      isVisible: columnsFromStorage["name"],
      RowCell: (props) => <ClickableActionNameCell {...props} />,
      HeaderCell: () => (
        <FormItem
          control={control}
          name={`Name`}
          sx={{
            padding: "9px 0 9px 16px",
            width: "100%",
          }}
          input={(props) => (
            <SearchInbuilt
              placeholder={t("tableHeader:facilityName")}
              className="select_in_built small-light"
              searchValue={searchObject}
              changeSearchValue={setsearchObject}
              {...props}
            />
          )}
        />
      ),
    },
    {
      id: "integrationNumber",
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:integrationNumber"),
      size: "230px",
      isVisible: columnsFromStorage["integrationNumber"],
      HeaderCell: () => (
        <FormItem
          control={control}
          name={`Name`}
          sx={{
            padding: "9px 0 9px 16px",
            width: "100%",
          }}
          input={(props) => (
            <SearchInbuilt
              placeholder={t("tableHeader:integrationNumber")}
              className="select_in_built small-light"
              searchValue={searchintNumber}
              changeSearchValue={setsearchintNumber}
              {...props}
            />
          )}
        />
      ),
    },
    {
      id: "facilityOverallRating",
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:facilityOverallRating"),
      size: "200px",
      isVisible: columnsFromStorage["facilityOverallRating"],
      RowCell: (props) => <RatingCell {...props} />,
      HeaderCell: () => (
        <Box
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            color: "var(--headers)",
            fontSize: "16px",
          }}
        >
          {t("tableHeader:facilityOverallRating")}
        </Box>
      ),
    },
    {
      id: "equipmentRegistry",
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:equipment"),
      size: "150px",
      isVisible: columnsFromStorage["integrationNumber"],
      RowCell: (props) => <ClickableActionNameCell {...props} />,
      HeaderCell: () => (
        <Box
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            color: "var(--headers)",
            fontSize: "16px",
          }}
        >
          {t("tableHeader:equipment")}
        </Box>
      ),
    },
    {
      id: "address",
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:address"),
      isVisible: columnsFromStorage["address"],
      clickable: false,
      size: "270px",
      HeaderCell: () => (
        <Box
          sx={{
            borderLeft: "none",
            width: "100%",
          }}
        >
          <AddressInputInbuilt
            placeholder={t("tableHeader:address")}
            className="select_in_built small-light"
            value={searchAddress}
            onChange={setsearchAddress}
          />
        </Box>
      ),
    },
    {
      id: "phone",
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:object_phone"),
      styleTableCell: {
        verticalAlign: "top",
      },
      size: "150px",
      isVisible: columnsFromStorage["phoneNumber"],

      HeaderCell: () => (
        <div
          style={{
            padding: "13px 0 13px 16px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            borderLeft: "none",
            color: "var(--headers)",
            fontSize: "16px",
          }}
        >
          <SearchInbuilt
            placeholder={t("tableHeader:object_phone")}
            className="select_in_built small-light"
            sx={{ padding: "12px 0px" }}
            searchValue={phone}
            changeSearchValue={setphone}
          />
        </div>
      ),
    },
    {
      id: "tasks",
      numeric: false,
      enableSorting: false,
      mode: "diagram",
      label: t("tableHeader:tasks"),
      isVisible: columnsFromStorage["tasks"],
      size: "78px",
      clickable: false,
      HeaderCell: () => (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            color: "var(--headers)",
            fontSize: "16px",
          }}
        >
          {t("tableHeader:tasks")}
        </div>
      ),
    },
    {
      id: "settings",
      numeric: false,
      enableSorting: false,
      label: "",
      size: 50,
      isVisible: true,
      clickable: false,
      HeaderCell: () => (
        <Box
          sx={{
            fontSize: '16px',
            color: 'var(--headers)',
          }}
        >{t("tableHeader:settings")}</Box>
      ),
    },
    {
      id: "edit",
      numeric: false,
      enableSorting: false,
      label: "",
      size: 50,
      isVisible: true,
      clickable: false,
      HeaderCell: () => (
        <Box
          sx={{
            height: "50px",
            borderLeft: "none",
            borderRadius: "0 40px 40px 0",
          }}
        ></Box>
      ),
    },
  ].filter(({ isVisible }) => isVisible);
