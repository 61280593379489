import React, { useMemo,useState } from "react";
import { Box } from "@mui/material";

import { useAppSelector } from "../../../../hooks/redux";
import { useAction } from "../../../../hooks/actions";
import { formFields } from "./options";
import TableBasic from "../../../components/TableBasic";
import { Techcard } from "./TechCard";
const ChecklistForm = ({ config, data }) => {
  const { pageNumber } = useAppSelector((state) => state.settings);
  const [openTechCard, setOpenTechCard] = useState(false);
  const [selectedJobId, setSelectedJobId] = useState(null);
  const { changePageNumber } = useAction();
  const handleOpenTechCardState = (id) => {
    setOpenTechCard(true);
    setSelectedJobId(id);
  };
  const rows = useMemo(() => {
    let { jobChecklist } = data || {};
    return jobChecklist?.length
      ? jobChecklist.map(({ id,...checklist }) => {
         
          return {
            techcard: {
              id,
              action: () => handleOpenTechCardState(id),
            },
            ...checklist,
          };
        })
      : [];
  }, [data]);

  return (
    
    <Box>
      {openTechCard && (
        <Techcard
          open={openTechCard}
          setOpen={setOpenTechCard}
          taskId={setSelectedJobId}
  
        />
      )}
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          alignItems: "flex-end",
          width: "100%",
        }}
      >
        <TableBasic
          columns={config}
          rows={rows}
          handleClickCell={() => {}}
          withSearchField={false}
          isLoading={false}
          isPagination={false}
          pageNumber={pageNumber}
          changePageNumber={changePageNumber}
        />
      </Box>
    </Box>
  );
};

export default ChecklistForm;
