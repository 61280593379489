import { Avatar, Box, IconButton, Menu, Typography } from "@mui/material";
import React from "react";
import { useAppSelector } from "../../../hooks/redux";
import { useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";
// import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../../constants";
import { persistor } from "../../../redux/store";
import { useLazyGetProfileInfoQuery } from "../../../middleware/usersApi";
import { useAction } from "../../../hooks/actions";
import CircularProgress from "@mui/material/CircularProgress";
import DefaultAvatarIcon from "../../components/icons/DefaultAvatarIcon";
import ArrowIcon from "../../components/icons/Header/ArrowIcon";
import LogoutIcon from "../../components/icons/Header/LogoutIcon";
import UserIcon from "../../components/icons/Header/UserIcon";

const ProfileDropdown = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    token,
    info: { firstName, lastName, avatarLink },
  } = useAppSelector((state) => state.users);
  const [anchorEl, setAnchorEl] = useState(null);
  const { setAuthStep } = useAction();

  const [getProfileInfo, { isError, isLoading }] = useLazyGetProfileInfoQuery();

  useEffect(() => {
    const requiredNecessaryValues = [firstName, lastName];
    const definedRequiredNecessaryValues = requiredNecessaryValues.filter(
      (value) => value
    );
    if (
      requiredNecessaryValues.length !== definedRequiredNecessaryValues.length
    ) {
      getProfileInfo(token);
    }
    // eslint-disable-next-line
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    await persistor.purge().then(() => {
      setAuthStep(0);
      navigate(PATHS.LOGIN_ROUTE);
    });
    localStorage.clear();
  };

  if (isError) {
    return null;
  }

  if (isLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Box>
    );
  }
  return (
    <Box>
      <IconButton
        aria-expanded={anchorEl}
        disableRipple
        onClick={handleClick}
        sx={{
          padding: "6px 12px",
          display: "flex",
          gap: "12px",
          borderRadius: "0",
          marginLeft: "12px",
        }}
      >
        <Box>
          {avatarLink ? (
            <Avatar
              alt="avatar"
              src={avatarLink}
              sx={{
                backgroundColor: "var(--main)",
                height: "52px",
                width: "52px",
              }}
            />
          ) : (
            <Box className="default-avatar-icon">
              <DefaultAvatarIcon />
            </Box>
          )}
        </Box>
        <Box className="user-name">
          {firstName} {lastName}
        </Box>
        <Box sx={{ margin: "0 0 8px 4px" }}>
          <ArrowIcon />
        </Box>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        sx={{
          "& .MuiMenu-list": {
            p: "10px",
            width: "222px",
          },
        }}
      >
        <MenuItem
          className="menu-item"
          onClick={() => navigate(PATHS.PROFILE_ROUTE)}
        >
          <UserIcon />
          <Typography sx={{ ml: "18px" }}>{t("label:profile")}</Typography>
        </MenuItem>

        <MenuItem className="menu-item" onClick={handleLogout}>
          <LogoutIcon />
          <Typography sx={{ ml: "18px" }}>{t("label:logout")}</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default ProfileDropdown;
