import React, { useMemo } from "react";
import { Tooltip } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import { useTranslation } from "react-i18next";

export const ControllerStatusCell = ({ item }) => {

  const { t } = useTranslation();
  const status = useMemo(
    () => (item ? "connectedController" : "disconnectedController"),
    [item]
  );

  return (
    <Tooltip title={t(`label:controllerStatusTooltips:${status}`)}>
      <CircleIcon sx={{ color: `var(--${status})` }} />
    </Tooltip>
  );
};
