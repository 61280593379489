import FormItem from "../../../../components/FormItem";

import {
    Box, InputAdornment
} from "@mui/material";
import React from "react";
import { RABFields, voltageFileds } from "./FormConfigs/equipments"
import { formFields } from "../form-config";
import { useWatch } from "react-hook-form";
const EquipColumn = ({ indexCirc, CircuitName, formConfig, plumeequip, isActExist, ...rest }) => {
    const { control, setValue } = rest;
    const { [formFields.protectionObjectId]: selectedFacilityId } = useWatch({
        control
    });

    if (selectedFacilityId) {
        let PlumesName = plumeequip.find(obj => obj.name === CircuitName);
        const EquipCircuitArr = PlumesName?.equipmentSelectElements

        return EquipCircuitArr?.map(({ id, name, plume, integrationNumbe, workingCorrectly }, index) => {
            const equipName = `${plume ? "шлейф 0" + plume + "," : ""} ${name}`
            return <Box sx={{
                display: "flex",
                alignSelf: "center",
                gap: "16px",
            }}>
                {formConfig.map((item) => {
                    let adorment = ""
                    if (CircuitName == "cableInsulationResistanceFormConfig")
                        adorment = "мОм"
                    else if (CircuitName == "RABCapacityFormConfig") {
                        adorment = "А\ч"
                    } else if (CircuitName == "voltageCurrentPowerSupplyFormConfig") {
                        adorment = "В,А"
                    }
                    if (item.name == "equipmentId") rest.equiptyp = equipName
                    if (item.name == "workingCorrectly") item.defaultValue = workingCorrectly.toString()
                    if (item.name == "equipmentId")
                        return (
                            <FormItem
                                {...item}
                                {...rest}
                                key={`${[rest.name]}.${index}.${item.name}`}

                                sx={{ alignSelf: "center", width: "250px" }}
                                value={id}

                                name={`${[rest.name]}.${index}.${item.name}`}
                            />
                        );
                    else if (item.name == "workingCorrectly")
                        return (
                            <Box>
                                <FormItem
                                    key={`${[rest.name]}.${index}.${item.name}`}
                                    {...item}
                                    sx={{ width: item.name == "corresponds" ? "200px" : "auto" }}
                                    {...rest}
                                    name={`${[rest.name]}.${index}.${item.name}`}
                                />
                                {(CircuitName == "voltageCurrentPowerSupplyFormConfig" || CircuitName == "RABCapacityFormConfig" || CircuitName == "cableInsulationResistanceFormConfig") &&
                                    RABFields.map((item) => {
                                        return (
                                            <FormItem
                                                key={`${[rest.name]}.${index}.${item.name}`}
                                                {...item}
                                                InputProps={{ endAdornment: <InputAdornment position="end">{adorment}</InputAdornment>, type: "number" }}
                                                {...rest}
                                                name={`${[rest.name]}.${index}.${item.name}`}
                                            />
                                        );


                                    })
                                }
                            </Box>
                        )
                    else
                        return (
                            <FormItem
                                key={`${[rest.name]}.${index}.${item.name}`}
                                {...item}
                                sx={{ width: item.name == "corresponds" ? "200px" : "auto" }}
                                {...rest}
                                name={`${[rest.name]}.${index}.${item.name}`}
                            />
                        );
                })}
                {(CircuitName == "RABCapacityFormConfig") &&
                    RABFields.map((item) => {
                        return (
                            <FormItem
                                key={`${[rest.name]}.${index}.${item.name}`}
                                {...item}

                                {...rest}
                                name={`${[rest.name]}.${index}.${item.name}`}
                            />
                        );


                    })
                }
                {(CircuitName == "voltageCurrentPowerSupplyFormConfig") &&
                    voltageFileds.map((item) => {
                        return (
                            <FormItem
                                key={`${[rest.name]}.${index}.${item.name}`}
                                {...item}

                                {...rest}
                                name={`${[rest.name]}.${index}.${item.name}`}
                            />
                        );


                    })
                }
            </Box>
        }
        )

    }


};


export default EquipColumn;