import React from "react";
import { useNavigate } from "react-router-dom";
import { Box,Typography } from '@mui/material';
import { PATHS } from '../../../constants'
import { useTranslation } from "react-i18next";

const PageheaderNav = ({ itemsNav }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Box sx={{ display: "flex" }}>
      {itemsNav.map((item) => (
        <Box className={`pageNavHeader ${(window.location.href.indexOf(item) > -1) ? "pageNavHeaderCureent" : ""}`}>
          <Typography onClick={() => navigate(PATHS[item.toUpperCase()])}>
            {t(`title_page:${item}`)}
          </Typography>
        </Box>

      ))}
    </Box>
  );
};

export default PageheaderNav;
