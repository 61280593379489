// libs imports
import React from "react";
import { InputAdornment, Typography } from "@mui/material";
import { t } from "i18next";
import InputText from "../../../components/InputText";

export const formFields = {
  defaultPlopInputName: "defaultPlopInputName",
  years: "years",
  months: "months",
  termOfContract: "termOfContract",
  shelfLife: "shelfLife",
};

export const DateItemsFormConfig = [
  {
    name: formFields.years,
    type: "number",
    rules: {},
    InputProps: {
      endAdornment: (
        <InputAdornment position="end">
          <Typography className="adornment" sx={{marginRight: '19px'}}>{t("label:in_years")}</Typography>
        </InputAdornment>
      ),
    },
    Input: (props) => <InputText {...props}/>,
  },
  {
    name: formFields.months,
    label: "",
    type: "number",
    rules: {},
    InputProps: {
      endAdornment: (
        <InputAdornment position="end">
          <Typography className="adornment" sx={{marginRight: '19px'}}>{t("label:in_months")}</Typography>
        </InputAdornment>
      ),
    },
    Input: (props) => <InputText {...props}/>,
  },
];

export const ShelfLifeItemFormConfig = [
  {
    name: formFields.shelfLife,
    label: "label:shelfLife",
    type: "number",
    rules: {
      validate: (value) => {
        return !!value && value > 0;
      },
    },
    input: (props) => <InputText {...props} />,
  },
];

export const WorkContractDateItemFormConfig = [
  {
    name: formFields.termOfContract,
    label: "label:termOfContract",
    type: "number",
    rules: {
      validate: {
        required: (value, formValues) => {
          if (
            formValues.selfService ||
            formValues.equipmentContractFileIdAvailability ||
            !formValues.havingServiceContract
          ) {
            return;
          }
          return !!value && value > 0;
        },
      },
    },
    input: (props) => <InputText {...props} />,
  },
];

export const resetFields = {
  [formFields.years]: "number",
  [formFields.months]: "number",
  [formFields.termOfContract]: "number",
};
