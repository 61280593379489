import React, { useCallback, useMemo, useState, useEffect } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { useAppSelector } from "../../../../hooks/redux";
import { useWatch } from "react-hook-form";
import { formFields } from "./options";
import SelectSimple from "../../../components/SelectSimple";
import { useGetFacFireCompSelectElementsQuery } from "../../../../middleware/facilitiesApi";
import { useGetResponsibleOrganizationsUsersQuery } from "../../../../middleware/usersApi";
import SelectGrouping from "../../../components/SelectGrouping";
import SelectSimpleSearch from "../../../components/SelectSimple/SelectSimpleSearch";
import {
  getEquipmentTypeIdOptionsGroup,
  getFacilityAndFireCompartmentOptionsGroup,
} from "./helpers";
import { 
  useLazyGetEquipmentSelectElementsQuery,
} from "../../../../middleware/equipmentsApi";
export const SimpleTextCell = ({ item }) => {
  const content = item;

  return <Typography className="standart_lg_text">{content}</Typography>;
};

export const ExecutiveUserNameSelectSimple = (props) => {
  const {control} = props
  const facility = useWatch({
    control,
    name: formFields.ProtectionObjectId,
  });
  const [searchValue, setSearchValue] = useState("");
  const { data: executives } = useGetResponsibleOrganizationsUsersQuery({ searchValue:searchValue,filters:`&protectionObjectId=${facility}` });
  
  const options = useMemo(() => {
    return executives
      ? executives.map(({ id, name }) => ({ value: id, name }))
      : [];
  }, [executives]);

  return (
    <Box sx={{ maxWidth: "49%" }}>
      <SelectSimpleSearch
        {...props}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        options={options}
      />
    </Box>
  );
};

export const SelectSimpleObject = (props) => {
  const { facilitySelectElements } = useAppSelector(
    (state) => state.facilities
  );

  return <SelectSimple options={facilitySelectElements} {...props} />;
};

export const SelectSimpleFloor = (props) => {
  const { floorOptionsSelectByFacility } = useAppSelector(
    (state) => state.facilities
  );

  return <SelectSimple options={floorOptionsSelectByFacility} {...props} />;
};

export const EquipmentTypeIdSelectGrouping = ({
  itemoption,
  
  bgcolor,
  ...props
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [equipmentSelectElements, setEquipmentSelectElements] = useState([]);
  const [getEquipmentSelectElements] = useLazyGetEquipmentSelectElementsQuery();

  useEffect(() => {
    
      getEquipmentSelectElements({ searchValue})
        .then((res) => {
          console.log("res",res)
          setEquipmentSelectElements(res?.data);
        })
        .catch((err) => {
          console.log(err);
        });
  }, [
    searchValue,
   
    getEquipmentSelectElements,
    equipmentSelectElements,
  ]);

  const options = useMemo(() => {
    return itemoption
      ? [itemoption, ...getEquipmentTypeIdOptionsGroup(equipmentSelectElements)]
      : getEquipmentTypeIdOptionsGroup(equipmentSelectElements);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equipmentSelectElements, itemoption, setEquipmentSelectElements]);

  return (
    <SelectGrouping
      {...props}
      setSearchValue={setSearchValue}
      searchValue={searchValue}
      options={options}
      defaultValue={0}
      bgcolor={bgcolor}
    />
  );
};
