import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  Stage,
  Layer,
  Line,
  Circle,
  Group,
  Image,
  Rect,
  Text,
} from "react-konva";
import { Box } from "@mui/material";

import {
  loadImg,
  getRectCoordinates,
  getIsInsidePoint,
} from "../../../helpers/helper";
import { getParamsText } from "../../pages/Plume/ImageSchema/helpers";
import { formFields, modalAddressConfig } from "../../pages/Plume/form-config";

import { SubNotificationPanel } from "../../pages/Plume/ImageSchema/SubNotificationPanel";
import { ModalRoomAddress } from "../Modals/ModalRoomAddress";
import { useAppSelector } from "../../../hooks/redux";
import { useFieldArray } from 'react-hook-form'

const emptyRectParams = {
  x: null,
  y: null,
  width: null,
  height: null,
};

const initialTransform = {
  x: 0,
  y: 0,
  active: false,
};

export const DrawingPlume = ({
  width,
  height,
  control,
  schemaImg,
  facilityId,
  isDrawPoligon,
  roomAddresses,
  getValues,
  setsearchIntNum,
  searchIntNum,
  resetField,
  minScale,
  isTurnDrag,
  canvasRef,
  transformCanvasData,
  setTransformCanvasData,
  imageRef,
  isAddEquip = false,
  widthWorkplace,
  heightWorkplace,
  newRoomAddresses,
  setNewRoomAddresses,
  scaleAddEquip,
  setscaleAddEquip,
  setoffsetAddEquip,
  offsetAddEquip,
  setValue,
}) => {
  const [image, setImage] = useState(null);
  const [transform, setTransform] = useState(initialTransform);
  const [isFinished, setIsFinished] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedintNum, SetselectedintNum] = useState(null);
  const [transformerVisible, setTransformerVisible] = useState(false);
  const [tooltipText, setTooltipText] = useState('');
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [points, setPoints] = useState([]);
  const [rectParams, setRectParams] = useState(emptyRectParams);
  const { SCHEMA_CANVAS1 } = useAppSelector((state) => state.settings);
  const stageRef = useRef(null);
  const scrollRef = useRef(null);
  const scale = useMemo(
    () => ({
      x: SCHEMA_CANVAS1.WIDTH / width,
      y: SCHEMA_CANVAS1.HEIGHT / height,
    }),
    [width, height, SCHEMA_CANVAS1]
  );
  const screenRelatedNewRoomAddresses = useMemo(() => {
    return newRoomAddresses.map(({ coordinates, ...rest }) => ({
      ...rest,
      coordinates: coordinates.map((item, index) =>
        index % 2 ? item / scale.y : item / scale.x
      ),
    }));
  }, [scale, newRoomAddresses]);
  console.log("newRoomAddresses", newRoomAddresses)
  // ==========
  const getPointPosition = (event) => {
    const { layerX, layerY, changedTouches } = event.evt;
    if (changedTouches) {
      const stage = event.target.getStage().attrs.container;
      const { left, top } = stage.getBoundingClientRect();
      return {
        layerX: changedTouches[0]?.clientX - left,
        layerY: changedTouches[0]?.clientY - top,
      };
    } else {
      return { layerX, layerY };
    }
  };

  const { fields: plumes, append: appendPlume, remove: removePlume, update: updatePlume } = useFieldArray({
    control,
    name: formFields.plumes,
  });

  const toStartDraw = (event) => {
    if (isTurnDrag) return
    if (isFinished) return;
    const { layerX, layerY } = getPointPosition(event);
   
    const isInside = getIsInsidePoint({
      x: layerX,
      y: layerY,
      roomAddresses: allRoomAddresses || [],
    });

    //  if (isInside) return;

    if (isDrawPoligon) {
      const insideX =
        layerX / scaleAddEquip - offsetAddEquip.x / scaleAddEquip >= points[0]?.x  - 10 && layerX/ scaleAddEquip - offsetAddEquip.x / scaleAddEquip  <= points[0]?.x  + 10;
      const insideY =
        layerY/ scaleAddEquip - offsetAddEquip.y / scaleAddEquip  >= points[0]?.y  - 10 && layerY/ scaleAddEquip - offsetAddEquip.y / scaleAddEquip  <= points[0]?.y + 10;
      const newPoint = {
        x: insideX ? points[0]?.x : layerX / scaleAddEquip - offsetAddEquip.x / scaleAddEquip,
        y: insideY ? points[0]?.y  : layerY / scaleAddEquip - offsetAddEquip.y / scaleAddEquip,
      };
      console.log("isInside", layerX,points[0]?.x,points[0]?.y,layerY)
      if (insideX && insideY) {
        setIsFinished(true);
        setTransform({
          x: newPoint.x,
          y: newPoint.y,
          active: true,
        });
      }
      
      setPoints([...points, newPoint]);
    } else {
      const newPoint = { x: layerX/ scaleAddEquip - offsetAddEquip.x / scaleAddEquip, y: layerY/ scaleAddEquip - offsetAddEquip.y / scaleAddEquip };
      const { x, y } = rectParams || {};

      if (x === null || y === null) {
        setIsFinished(false);
        setRectParams({ ...newPoint });
      }
    }
  };
  console.log("points",points)
  const toMoveDraw = (event) => {
    if (!isFinished) {
      const { layerX, layerY } = getPointPosition(event);
      const { x, y } = rectParams || {};
      if (x && y) {
        const width = layerX/ scaleAddEquip - offsetAddEquip.x / scaleAddEquip - x;
        const height = layerY/ scaleAddEquip - offsetAddEquip.y / scaleAddEquip - y;
        const isInside = getIsInsidePoint({
          x: layerX,
          y: layerY,
          roomAddresses: allRoomAddresses || [],
        });

        //  if (isInside) return;
        setRectParams({ ...rectParams, width, height });
      }
    }
  };

  const toEndDraw = (event) => {
    if (isDrawPoligon || isFinished) return;
    const { layerX, layerY } = getPointPosition(event);
    const { width: widthRect, height: heightRect } = rectParams || {};
    const isToGoUotFieldCanvas =
      layerY <= 0 || layerY >= height || layerX <= 0 || layerX >= width;

    if (widthRect && heightRect && !isToGoUotFieldCanvas) {
      const newPoint = { x: layerX, y: layerY };
      setIsFinished(true);
      setTransform({
        x: newPoint.x - widthRect,
        y: newPoint.y + 2,
        active: true,
      });
    } else {
      setRectParams(emptyRectParams);
    }
  };

  const handleCloseIcon = () => {
    setTransform(initialTransform);

    setPoints([]);
    setRectParams(emptyRectParams);
    setIsFinished(false);
  };

  const handleCheckIcon = () => setOpen(true);

  const handleModalSave = (e) => {

    const newCoordinates = isDrawPoligon
      ? points?.map((e) => [e.x * scale.x, e.y * scale.y]).flat()
      : getRectCoordinates(rectParams, scale);

    const sosao = {
      ...getValues(formFields.roomAddress),
      coordinates: newCoordinates,
      protectionObjectIntegrNumberAltId: selectedintNum,
      plumes: !plumes.find(el => typeof el === 'string') ? plumes.map(el => el.name) : plumes,

    }
    console.log("eerr", getValues("protectionObjectIntegrNumberAltId"), selectedintNum)
    setNewRoomAddresses((prev) => [
      {
        ...getValues(formFields.roomAddress),
        coordinates: newCoordinates,
        protectionObjectIntegrNumberAltId: selectedintNum,
        plumes: !plumes.find(el => typeof el === 'string') ? plumes.map(el => el.name) : plumes,

      },
      ...prev,

    ]);

    isDrawPoligon ? setPoints([]) : setRectParams(emptyRectParams);
    setTransform(initialTransform);
    setIsFinished(false);
    setValue(formFields.plumes, [])
    handleModalCancel();
  };

  const handleModalCancel = () => {
    handleCloseIcon();
    resetField(formFields.roomAddress);
    setOpen(false);
  };

  const style = {
    display: transform.active ? "block" : "none",
    transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
  };

  const allRoomAddresses = useMemo(() => {

    return [
      ...roomAddresses?.filter(({ id }) => id),
      ...screenRelatedNewRoomAddresses,
    ];
  }, [roomAddresses, screenRelatedNewRoomAddresses]);

  const drawScheme = async (schemaImg, height, width) => {

    await loadImg(schemaImg, height, width).then((image) => setImage(image));
  };
  useEffect(() => {

    schemaImg && drawScheme(schemaImg, height, width);
  }, [schemaImg, height, width]);
  const handleWheel = (e) => {

    e.evt.preventDefault();
    const stage = stageRef.current;
    const canvas = canvasRef.current;
    let newScale = scaleAddEquip;
    if (e.evt.deltaY < 0) {
      newScale = scaleAddEquip <= minScale + 5 ? scaleAddEquip + 0.5 : scaleAddEquip; // Ограничение максимального зума (2 в данном случае)
    } else {
      newScale = scaleAddEquip > 0.5 ? scaleAddEquip - 0.5 : scaleAddEquip; // Ограничение минимального зума (0.5 в данном случае)
    }
    setscaleAddEquip((prev) => newScale);

    const pointTo = {
      x: stage.getPointerPosition().x / scaleAddEquip - canvas.x() / scaleAddEquip,
      y: stage.getPointerPosition().y / scaleAddEquip - canvas.y() / scaleAddEquip,
    };
    //setoffsetAddEquip((prev) => stageposition)
    setoffsetAddEquip({
      x: -(pointTo.x - stage.getPointerPosition().x / newScale) * newScale,
      y: -(pointTo.y - stage.getPointerPosition().y / newScale) * newScale,
    });

  };
  const handleScrollbarMouseUp = (e, typeScroll) => {
    if (typeScroll == "vertical") {
      setoffsetAddEquip({ ...offsetAddEquip, y: -e.target.y() * scaleAddEquip });
    } else {
      setoffsetAddEquip({ ...offsetAddEquip, x: -e.target.x() * scaleAddEquip });
    }
    /* const canvas = canvasRef.current;
 
     const coordinates = [-canvas.x() / scale, -canvas.y() / scale, (widthWorkplace / scale - canvas.x() / scale), (heightWorkplace / scale - canvas.y() / scale)];
     groupClick(coordinates, null, scalecanvas, image.height, image.width, false, true);
     */
  };
  const handleScrollbarMouseMove = (e, typeScroll) => {

    const canvas = canvasRef.current
    if (typeScroll == "vertical") {
      canvas.position({ x: canvas.x(), y: -e.target.y() * scaleAddEquip })
    } else {
      canvas.position({ y: canvas.y(), x: -e.target.x() * scaleAddEquip })
    }
  };
  const handleDragEndStage = (e) => {

    const stage = canvasRef.current;

    const coordinates = [-stage.x() / scaleAddEquip, -stage.y() / scaleAddEquip, (width - stage.x()) / scaleAddEquip, (height - stage.y()) / scaleAddEquip];
    setoffsetAddEquip({ x: stage.x(), y: stage.y() })
    //  scrollRef.current.scrollTop = stage.y()
    //scrollRef.current.scrollLeft  = stage.x()
  }

  const handleDragMove = (e) => {
    if (!isTurnDrag) return
    const newX = e.target.x();
    const newY = e.target.y();

    // console.log("clampedX",e.target.y(),e.target.x())
    // Ограничиваем перемещение по горизонтали
    const maxX = 0;
    const minX = -(image.width * scaleAddEquip - width);

    // Ограничиваем перемещение по вертикали
    const maxY = 0;
    const minY = -(image.height * scaleAddEquip - height);

    // Если новые координаты выходят за границы, оставляем их на границе
    const clampedX = Math.min(Math.max(newX, minX), maxX);
    const clampedY = Math.min(Math.max(newY, minY), maxY);

    setTransformCanvasData({
      x: clampedX,
      y: clampedY,
    });

  }
  const handleShapeMouseMove = (e, text, coordinates) => {

    const mousePos = e.target.getStage().getPointerPosition();
    setTooltipText(text);
    setTooltipPosition({ x: mousePos.x + 5, y: mousePos.y + 5 }); // Обновляем позицию tooltip
    setTooltipVisible(true);
  };

  const handleShapeMouseOut = () => {
    setTooltipVisible(false);
  };
  return (
    <Box ref={scrollRef} sx={{ position: "relative", border: "1px solid #CFD9E8", borderRadius: "16px", padding: "10px", width: widthWorkplace ? widthWorkplace - 20 : width - 20, height: heightWorkplace ? heightWorkplace - 20 : height - 20, cursor: isTurnDrag ? "grab" : "crosshair" }}>
      <SubNotificationPanel
        style={style}
        handleCloseIcon={handleCloseIcon}
        handleCheckIcon={handleCheckIcon}
      />
      <ModalRoomAddress
        plumes={plumes}
        appendPlume={appendPlume}
        removePlume={removePlume}
        updatePlume={updatePlume}
        selectedintNum={selectedintNum}
        SetselectedintNum={SetselectedintNum}
        name={formFields.roomAddress}
        control={control}
        open={open}
        setsearchIntNum={setsearchIntNum}
        searchIntNum={searchIntNum}
        facilityId={facilityId}
        config={modalAddressConfig}
        handleSave={(e) => handleModalSave(e)}
        handleCancel={handleModalCancel}
      />
      <Stage
        width={widthWorkplace ? widthWorkplace - 20 : width - 20}
        height={heightWorkplace ? heightWorkplace - 20 : height - 20}

        ref={stageRef}
      >
        <Layer
          ref={canvasRef}
          scaleX={scaleAddEquip}
          scaleY={scaleAddEquip}
          x={offsetAddEquip.x}
          draggable={isTurnDrag}
          onDragMove={handleDragMove}
          onDragEnd={handleDragEndStage}
          y={offsetAddEquip.y}
          onMouseDown={toStartDraw}
          onMouseUp={toEndDraw}
          onMouseMove={toMoveDraw}
          onTouchStart={toStartDraw}
          onTouchEnd={toEndDraw}
          onTouchMove={toMoveDraw}
          onMouseEnter={e => {
            if (isTurnDrag) {
              const container = e.target.getStage().container();
              container.style.cursor = 'grab';
            } else {
              const container = e.target.getStage().container();
              container.style.cursor = 'crosshair';
            }
          }}

          onWheel={handleWheel}
          dragBoundFunc={pos => {
            //   console.log("pos",pos)
            const widthPos = widthWorkplace ? widthWorkplace : width
            const HeightPos = heightWorkplace ? heightWorkplace : height
            const offsetX = widthPos - image.width * scaleAddEquip
            const offsetY = HeightPos - image.height * scaleAddEquip
            //  console.log("stage",pos,image.width*scale,offsetX)

            if (offsetX <= 0) {
              if (pos.x <= offsetX) pos.x = offsetX;
              if (pos.x > 0) pos.x = 0;

            } else {
              if (pos.x > offsetX) pos.x = offsetX;
              if (pos.x <= 0) pos.x = 0;
            }

            if (offsetY <= 0) {
              if (pos.y <= offsetY) pos.y = offsetY;
              if (pos.y > 0) pos.y = 0;

            } else {
              if (pos.y > offsetY) pos.y = offsetY;
              if (pos.y <= 0) pos.y = 0;
            }
            return pos;
          }}>
          <Image ref={imageRef} image={image} x={0} y={0} />
          {allRoomAddresses.map(
            ({ id, coordinates, name, plumes }, index) =>
              !!coordinates?.length && (
                <Group key={id || index} onMouseMove={(e) => handleShapeMouseMove(e, `${name}, Шлейф: ${plumes[0]?.name || plumes[0]}`, coordinates)}
                  onMouseOut={handleShapeMouseOut}>
                  <Line
                    points={coordinates}
                    stroke="#1876D2"
                    fill="rgba(24,118,210,0.3)"
                    closed={true}
                    strokeWidth={3 / scaleAddEquip}
                  />
                  {/*
                 <Text
                    {...getParamsText({ coordinates, text: name, line: 0 })}
                  />
                  <Text
                    {...getParamsText({ coordinates, text: plumes[0], line: 1 })}
                  />
                 */}
                </Group>
              )
          )}
          <Group>
            {points?.map((point, index) => (
              <Circle
                key={index}
                x={point.x}
                y={point.y}
                radius={3}
                fill="red"
              />
            ))}
            {isDrawPoligon ? (
              <Line
                points={points?.map((e) => [e.x, e.y]).flat()}
                stroke="#1876D2"
                strokeWidth={3 / scaleAddEquip}
                fill="rgba(24,118,210,0.3)"
                closed={isFinished}
              />
            ) : (
              <Rect
                {...rectParams}
                fill={"rgba(24,118,210,0.3)"}
                stroke={"#1876D2"}
                strokeWidth={3 / scaleAddEquip}
              />
            )}
          </Group>
        </Layer>
        <Layer>
          <Group>
            {image?.height * scaleAddEquip > heightWorkplace &&
              <Rect
                width={25}
                height={heightWorkplace - (image.height * scaleAddEquip - heightWorkplace) / scaleAddEquip}
                fill="#1876D2"
                opacity={0.8}
                x={widthWorkplace - 15 - 20}
                y={-(transformCanvasData.y) / scaleAddEquip}
                draggable
                dragBoundFunc={(pos) => {
                  pos.x = widthWorkplace - 15 - 20;
                  const offsetY = heightWorkplace - (image.height * scaleAddEquip - heightWorkplace) / scaleAddEquip
                  if (pos.y + offsetY > heightWorkplace) pos.y = heightWorkplace - offsetY - 5
                  if (pos.y < 5) pos.y = 5
                  return pos;
                }}
                onMouseEnter={e => {
                  const container = e.target.getStage().container();
                  container.style.cursor = 'pointer';

                }}
                onMouseLeave={e => {

                  const container = e.target.getStage().container();
                  container.style.cursor = 'grab';

                }}
                onDragMove={(e) => {
                  handleScrollbarMouseMove(e, "vertical")
                }}
                onDragEnd={(e) => { handleScrollbarMouseUp(e, "vertical") }}
              />
            }
            {image?.width * scaleAddEquip > widthWorkplace &&
              <Rect
                width={widthWorkplace - (image.width * scaleAddEquip - widthWorkplace) / scaleAddEquip}
                height={25}
                fill="#1876D2"
                opacity={0.8}
                y={heightWorkplace - 20 - 15}
                x={-(transformCanvasData.x) / scaleAddEquip}
                draggable
                dragBoundFunc={(pos) => {
                  pos.y = heightWorkplace - 20 - 15;
                  const offsetX = widthWorkplace - (image.width * scaleAddEquip - widthWorkplace) / scaleAddEquip
                  if (pos.x + offsetX > widthWorkplace) pos.x = widthWorkplace - offsetX - 5
                  if (pos.x < 5) pos.x = 5
                  return pos;
                }}
                onMouseEnter={e => {
                  const container = e.target.getStage().container();
                  container.style.cursor = 'pointer';

                }}
                onMouseLeave={e => {

                  const container = e.target.getStage().container();
                  container.style.cursor = 'grab';

                }}
                onDragMove={(e) => {
                  handleScrollbarMouseMove(e, "horizontal")
                }}
                onDragEnd={(e) => { handleScrollbarMouseUp(e, "horizontal") }}
              />
            }
          </Group>

        </Layer>
        <Layer>
          {tooltipVisible && (
            <Text
              text={tooltipText}
              fontSize={14}
              padding={10}
              textFill="white"
              fill="black"
              fontStyle="bold"
              alpha={1}
              visible={tooltipVisible}
              x={tooltipPosition.x} // Устанавливаем позицию по x
              y={tooltipPosition.y} // Устанавливаем позицию по y
            />
          )}

        </Layer>
      </Stage>
    </Box>
  );
};
