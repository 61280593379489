import React, { useEffect, useState, Fragment,useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  OutlinedInput,
  InputLabel,
  MenuItem,
  ListItemText,
  ListItemButton,
  FormControl,
  Select,
  Box,
  Tooltip,
  Typography,
} from "@mui/material";

import { MyListSubheader, MyListSubheaderSearch } from "./MyListSubheader";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import selectArrowUp from "../../../image/icons/selectArrowUp.png";
import selectArrowDown from "../../../image/icons/selectArrowDown.png"

const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 13;
const SEARCH_HEIGHT = 72
const SelectGroupingSystemPPZ = ({
  options,
  label,
  onChange,
  error,
  value,
  searchValue,
  setSearchValue,
  isTableheader,
  defaultValue = 0,
  bgcolor,
  height = "",
  setValue,
  isSystemPPZ = false,
  disabled = false,
  ...rest
}) => {
  const { t } = useTranslation();

  const [updatedOptions, setUpdatedOptions] = useState([]);
  const allOptionItemsCount = useMemo(() => {
    let totalCount = 0;
    totalCount += updatedOptions?.length;
    if(isTableheader) totalCount++;
    updatedOptions?.map((parent) => {
      if (parent?.open) totalCount += parent?.items?.length;
    })
   
    return totalCount;

  }, [updatedOptions])
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: allOptionItemsCount >= 4 ? (ITEM_HEIGHT + ITEM_PADDING_TOP) * 4 + SEARCH_HEIGHT : (ITEM_HEIGHT + ITEM_PADDING_TOP) * allOptionItemsCount + SEARCH_HEIGHT,
      },
    },
  };
  const [selectedValue, setSelectedValue] = useState(defaultValue === 0 ? value : defaultValue);
  const [isOpen, setIsOpen] = React.useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
    setSearchValue("")
  };
  useEffect(() => {
    setUpdatedOptions(options);
    if (options?.length) {
      if (Number(value)) {

        const selectedItem = options
          ?.reduce((sum, { items }) => [...sum, ...items], [])
          ?.find(({ id }) => id === value);

        setSelectedValue(JSON.stringify(selectedItem) || value);
      } else {
        setSelectedValue(value || defaultValue === 0 ? value : JSON.stringify(defaultValue));
      }
    }
  }, [options, value, defaultValue]);

  const handleClick = (id) => {
    const updatedOptions = options?.length && options.map((option) => ({
      ...option,
      open: !option.open && option.id === id
    }));

    setUpdatedOptions(updatedOptions);
  };
  console.log("updatedOptionsss", updatedOptions)
  return (
    <Box sx={{ position: "relative" }} className="input_text">
      {!isTableheader && <InputLabel sx={{ mb: "8px" }}>
        {t(label)}
      </InputLabel>
      }

      <FormControl fullWidth error={!!error && !disabled} sx={{ background: bgcolor ? bgcolor : "inherit", height: height }} {...rest}>
        <Select
          value={selectedValue}
          renderValue={(selected) => {
            console.log("selectedEquip1", selected, selected == 0 || selected == "все" ? "все" : selected ? JSON.parse(selected).name : "")

            return selected ? (<Tooltip title={selected == 0 || selected == "все" ? "все" : selected ? JSON.parse(selected).name : ""}><Box sx={{ textOverflow: "ellipsis", width: "170px", whiteSpace: "nowrap", overflow: "hidden" }}>{selected == 0 || selected == "все" ? "все" : selected ? JSON.parse(selected).name : ""}</Box></Tooltip>) : "Система";
          }}
          disabled={disabled}
          input={<OutlinedInput />}
          onChange={onChange}
          MenuProps={MenuProps}
          displayEmpty={isTableheader ? true : false}
          inputProps={{ 'aria-label': 'Without label' }}
          open={isOpen}
          onOpen={handleOpen}
          onClose={handleClose}
          IconComponent={() =>
            <Box sx={{ mr: "24px", cursor: "pointer" }} onClick={() => { setIsOpen((prev) => !prev) }}>
              {isOpen ? (
                <img src={selectArrowUp} />
              ) : (
                <img src={selectArrowDown} />
              )
              }
            </Box>}
          sx={{
            ".MuiSelect-select": {
              display: "flex",
              alignItems: "center",
              height: 0,
              color: "var(--additionalGrey)",

              fontSize: "16px",
              fontWeight: '500'
            },
            maxHeight: "56px",
          }}
          {...rest}
        >
          <MyListSubheaderSearch
            setSearchValue={setSearchValue}
            searchValue={searchValue}
          />
          {isTableheader &&
            <MenuItem disabled value="" sx={{ height: ITEM_HEIGHT + ITEM_PADDING_TOP }}>
              {t(label)}
            </MenuItem>
          }
          {updatedOptions?.length ? (
            updatedOptions.map((option) => {
              const { open, items } = option || {};

              if (!items) {
                const { id, name } = option;
                return (
                  <MenuItem key={id} value={id} sx={{ height: ITEM_HEIGHT + ITEM_PADDING_TOP }}>
                    <ListItemText primary={name || ""} />
                  </MenuItem>
                );
              }
              if (items && items.length == 1) {
                const { id, name } = option;
                return (
                  <MenuItem
                    sx={{ height: ITEM_HEIGHT + ITEM_PADDING_TOP }}
                    key={id} value={JSON.stringify({ id: items[0]?.id, name: `${name}, ${items[0]?.name}` }) || ""}>
                    <ListItemText sx={{
                      paddingLeft: "10px",
                    }} primary={`${name}, ${items[0]?.name}` || ""} />
                  </MenuItem>
                );
              }

              const Items = items?.length
                ? items.map((item) => {
                  const { id, name } = item;
                  return (

                    <MenuItem
                      key={id}
                      sx={{
                        display: `${open ? "block" : "none"}`,
                        paddingLeft: "70px",
                        height: ITEM_HEIGHT + ITEM_PADDING_TOP
                      }}
                      value={JSON.stringify({ ...item, id: id, name: `${option.name}, ${name}` }) || ""}
                    >
                      <Typography>
                        {`${name}`}
                      </Typography>

                    </MenuItem>
                  );
                })
                : null;

              return [

                <MenuItem
                  key={option.id}
                  sx={{ height: ITEM_HEIGHT + ITEM_PADDING_TOP, verticalAlign: "super !important" }}
                  value={JSON.stringify({ id: option.id, name: `${option.name}`, isType: true }) || ""}
                >
                  <MyListSubheader
                    key="MyListSubheader"
                    setOptions={setUpdatedOptions}
                    options={updatedOptions}
                    isSystemPPZ={isSystemPPZ}
                    setSelectedValue={setSelectedValue}
                    itemOption={option}
                  />
                  <ListItemText primary={option.name} />
                </MenuItem>
                ,
                Items,
              ];
            })
          ) : (
            <MenuItem sx={{ height: ITEM_HEIGHT + ITEM_PADDING_TOP }} disabled={true}>
              <ListItemText primary={t("label:nothingFound")} />
            </MenuItem>
          )}
        </Select>
      </FormControl>
    </Box>
  );
};

export default SelectGroupingSystemPPZ;
