import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../constants";

export function ErrorFallback() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleLogout = () => {
    navigate(PATHS.LOGIN_ROUTE);
  };
  return (
    <div role="alert">
      <div>
        <Typography variant="h1" component="h2">
          {t("notification:error_fall_back")}
        </Typography>
      </div>
      <Button variant="contained" size="large" onClick={handleLogout}>
        {t("label:logout")}
      </Button>
    </div>
  );
}
