import React, { useState,useEffect } from "react";
import dayjs from "dayjs";
import { Box, Typography, FormControlLabel, Checkbox } from "@mui/material";
import { useWatch } from "react-hook-form";
import TaskDialog from "../TasksList/TaskDialog/TaskDialog";
import InputText from "../../components/InputText";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useAppSelector } from "../../../hooks/redux";
import SelectSimple from "../../components/SelectSimple";
import SelectSimpleSearch from "../../components/SelectSimple/SelectSimpleSearch";
import {
  useLazyGetRoomsPlumesQuery,
  useLazyGetRoomsAddressSelectionQuery
} from "../../../middleware/equipmentsApi";

export const DateFormatCell = ({ item }) => {
  const dateFormat = "DD.MM.YYYY, HH:mm";
  const content = dayjs(item).format(dateFormat);

  return <Typography className="standart_lg">{content}</Typography>;
};

export const SimpleTextCell = ({ item }) => {
  const content = item;

  return <Typography className="standart_lg">{content}</Typography>;
};
export const SelectSimpleFloor = (props) => {
  const { floorOptionsSelectByFacility } = useAppSelector(
    (state) => state.facilities
  );

  return <SelectSimple options={floorOptionsSelectByFacility} displayEmpty={true}  {...props} />;
};
export const RoomAdressesSelectGrouping = (props) => {
  const { control } = props
  const { floor } = useWatch({
    control,
  });
  const [searchValue, setSearchValue] = useState("");
  const [datarooms, setdatarooms] = useState([]);
  const [
    getRoomsAddressSelection,
    { isError: isErrorGetRoomsAddressS, isLoading: isLoadingGetRoomsAddressS },
  ] = useLazyGetRoomsAddressSelectionQuery();
  useEffect(() => {
    if(floor){
      getRoomsAddressSelection({floorInfoId:floor,searchName:searchValue}).then(
        ({ data, isSuccess }) => {
            setdatarooms(data)
        }
      );
    }
    
  }, [floor,searchValue]);
 // console.log('datarooms',props)
  return (
    <Box className="medium_container">
      <SelectSimpleSearch
        {...props}
        setSearchValue={setSearchValue}
        displayEmpty={true}
        searchValue={searchValue}
        options={datarooms}
      />
    </Box>
  );
};
export const RoomPlumesSelectGrouping = (props) => {
  const { control } = props
  const { roomAddressId } = useWatch({
    control,
  });
  const [searchValue, setSearchValue] = useState("");
  const [dataplumes, setdataplumes] = useState([]);
  const [
    getRoomsPlumesSelection,
    { isError: isErrorGetRoomsPlumes, isLoading: isLoadingGetRoomsAddressPlumes },
  ] = useLazyGetRoomsPlumesQuery();
  useEffect(() => {
    if(roomAddressId){
      getRoomsPlumesSelection({roomAddressId,plume:searchValue}).then(
        ({ data, isSuccess }) => {
            setdataplumes(data)
          
        }
      );
    }
    
  }, [roomAddressId,searchValue]);
  
  return (
    <Box className="medium_container">
      <SelectSimpleSearch
        {...props}
        setSearchValue={setSearchValue}
        displayEmpty={true}
        searchValue={searchValue}
        options={dataplumes}
      />
    </Box>
  );
};
export const TextCellWithEdit = ({ item }) => {
  const [isEditing, setIsEditing] = useState(false); // Добавлено состояние для отслеживания режима редактирования
  const [newValue, setNewValue] = useState(item); // Добавлено состояние для отслеживания измененного значения

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  const handleInputChange = (event) => {
    setNewValue(event.target.value);
  };

  /* const handleInputBlur = () => {
    setNewValue(newValue);
  }; */

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {isEditing ? (
        <InputText
          style={{
            height: "45px",
            padding: 0,
            margin: "0 5px",
          }}
          type="text"
          value={newValue}
          onChange={handleInputChange}
        />
      ) : (
        <Typography className="standart_lg" onClick={handleEditClick}>
          {newValue}
        </Typography>
      )}
      <EditOutlinedIcon onClick={handleEditClick} />
    </Box>
  );
};

export const ExecutiveBlockCell = ({ item }) => {
  const { executor, executorPosition, executorOrganization } = item || {};

  return (
    <Box>
      <Typography className="standart_lg">{executor}</Typography>
      <Typography className="additional_grey">{executorPosition}</Typography>
      <Typography className="additional_grey">
        {executorOrganization}
      </Typography>
    </Box>
  );
};

export const LinkTextCell = ({ item }) => {
  const { id, name } = item;
  const [openTaskDialog, setOpenTaskDialog] = useState(false);

  return (
    <>
      {openTaskDialog && (
        <TaskDialog
          open={openTaskDialog}
          setOpen={setOpenTaskDialog}
          taskId={id}
        />
      )}
      <Typography
        className="table-row-cell-link standart_lg"
        onClick={setOpenTaskDialog.bind(null, true)}
      >
        {name}
      </Typography>
    </>
  );
};

export const JobsBlockCell = ({ item }) => {
  const { description, checklists } = item || {};
  return (
    <Box>
      <Typography className="standart_lg">{description}</Typography>
      {checklists?.length &&
        checklists.map(({ id, description, isCompleted }) => (
          <FormControlLabel
            key={id}
            disabled
            control={<Checkbox defaultChecked={isCompleted} />}
            label={description}
            sx={{ display: "block" }}
          />
        ))}
    </Box>
  );
};
