import React from "react";
import Box from "@mui/material/Box";

import "./styles/style.css";
import { sections } from "./form-config";

import Header from "./Header";
import AccordionSection from "../../components/AccordionSection";
import PageheaderNav from "../../components/PageheaderNav";

const Acts = () => {
  const itemsNav = [
    "plume_and_address",
    "func_tests",
    "documents",
    "planning"
  ]
  return (
    <Box className="acts-container">
      <Header nameForm="acts" />
      <PageheaderNav itemsNav={itemsNav} />
      <Box className="scrolled-form scrolled-acts_panel">
        {
          sections.map(({ id, title, Component }) => (
            <AccordionSection key={id} title={title}>
              {Component && (
                <Component section={title} />
              )}
            </AccordionSection>
          ))
        }
      </Box>
    </Box>
  );
};

export default Acts;
