import React from "react";
import { t } from "i18next";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import { fileSizeCheck, fileTypeCheck } from "../../../../validation";

import InputText from "../../../components/InputText";
import InputFileSingle from "../../../components/InputFile/InputFileSingle";

export const formFields = {
  floorAmount: "floorAmount",
  floorNumber: "floorNumber",
  floors: "floors",
  comment: "comment",
  floorSchema: "floorSchema",
  undergroundFloorAmount: "undergroundFloorAmount",
  number: "number",
  address: "address",
};

export const templateFloor = {
  [formFields.floorNumber]: null,
  [formFields.comment]: "",
  [formFields.floorSchema]: null,
};
export const templateIntnumber = {
  [formFields.number]: null,
  [formFields.address]: "",
};
export const maxFloorAmount = 163;

export const FloorsConfig = [
  {
    name: formFields.floorNumber,
    type: "number",
    rules: {
      pattern: /^-?([0-9]+)?$/,
      required: true,
      integerNegative: (v) => !/\s/g.test(v) && /^-?([0-9]+)?$/g.test(v),
    },
    placeholder: "placeholder:floor_number",
    flexBasis: "35%",
    input: (props) => (
      <Box sx={{ "& .MuiFormLabel-root": { mb: "0" } }}>
        <Typography className="input-block-title">
          {t("label:floorNumber")}
        </Typography>
        <InputText {...props} />
      </Box>
    ),
  },
  {
    name: formFields.comment,
    type: "text",
    rules: {},
    flexBasis: "75%",
    placeholder: "placeholder:group_place",
    input: (props) => (
      <Box
        sx={{
          "& .MuiInputLabel-root": { marginBottom: "0", paddingBottom: "0" },
        }}
      >
        <Typography className="input-block-title">
          {t("label:object_group")}
        </Typography>
        <InputText {...props} />
      </Box>
    ),
  },
  {
    name: formFields.floorSchema,
    label: "label:floorSchema",
    type: "file",
    canDeleteFile: false,
    rules: {
      validate: {
        type: (files) => {
          const availableFileTypes = [
            "image/jpeg",
            "image/png",
            "application/pdf",
          ];
          return fileTypeCheck(
            files?.filesToAdd,
            formFields.floorSchema,
            availableFileTypes
          );
        },
        size: (files) => {
          const maxAvailableFileSize = 10; // MBs
          return fileSizeCheck(
            files?.filesToAdd,
            formFields.floorSchema,
            maxAvailableFileSize
          );
        },
      },
    },
    input: (props) => (
      <InputFileSingle {...props} accept={".png, .jpg, .jpeg, .pdf"} />
    ),
  },
];
export const IntegrationNumbersConfig = [
  {
    name: formFields.number,
    type: "number",
    placeholder: "placeholder:intergration_number",
    rules: {
      pattern: /^-?([0-9]+)?$/,
      required: true,
      integerNegative: (v) => !/\s/g.test(v) && /^-?([0-9]+)?$/g.test(v),
    },
    flexBasis: "35%",
    input: (props) => (
      <Box>
        <Typography className="input-block-title">
          {t("label:floorNumber")}
        </Typography>
        <InputText {...props} />
      </Box>
    ),
  },
  {
    name: formFields.address,
    type: "text",
    rules: {},
    flexBasis: "75%",
    placeholder: "placeholder:device_address",
    input: (props) => (
      <Box>
        <Typography className="input-block-title">
          {t("label:object_group")}
        </Typography>
        <InputText {...props} />
      </Box>
    ),
  },
];
