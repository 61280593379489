import React, { useMemo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import { useAppSelector } from "../../../../hooks/redux";
import { useAction } from "../../../../hooks/actions";

import TableBasic from "../../../components/TableBasic";
import { ModalConfirmDialog } from "../../../components/Modals/ModalConfirmDialog";
import { useLazyToSignTaskQuery } from "../../../../middleware/signingApi";
import { useLazyGetTaskQuery } from "../../../../middleware/tasksApi";

export const SignaturesTable = ({ config, data }) => {
  const { t } = useTranslation();
  const { pageNumber } = useAppSelector((state) => state.settings);
  const { info } = useAppSelector(state => state.users);
  const { changePageNumber, setIsErrorSubmit, setIsSuccessSubmit } = useAction();
  const [toSignTask, { error, isSuccess }] = useLazyToSignTaskQuery();
  const [getTask,{errorGetTask,isSuccessGetTask}] = useLazyGetTaskQuery();
  const [isSigned, setIsSigned] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const rows = useMemo(() => {
    const { signatures, currentUserSigned } = data || {};

    setIsSigned(currentUserSigned);
    return (
      signatures?.map(({ signatureDateTime, ...rest }) => ({
        signatureDateTime,
        signatureImage: { signatureDateTime, ...rest },
      })) || []
    );
    // eslint-disable-next-line
  }, [data]);

  const handleSignBtn = () => {
    setOpenConfirmModal(true);
  };

  const handleAgreeSign = () => {
    const { id: taskId } = data || {};
    toSignTask(taskId).then(({ error }) => {
      setIsSigned(!error);
      !error && getTask(taskId).then(() => setOpenConfirmModal(false));
    });
  };

  const handleDisagreeSign = () => {
    setOpenConfirmModal(false);
  };

  useEffect(() => {
    console.log("errorGetTask",errorGetTask)
    setIsErrorSubmit(errorGetTask);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorGetTask]);

  useEffect(() => {
    setIsSuccessSubmit(isSuccess);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);
  
  return (
    <Box className="signatures">
      {data?.userPhoneVerified && (
        <Box className="signatures-button_wrraper">
          <Button
            className="btn btn-outlined-primary"
            variant="outlined"
            onClick={handleSignBtn}
            disabled={isSigned}
          >
            {t(`button:${isSigned ? "signed" : "signing"}`)}
          </Button>
        </Box>
      )}
      <TableBasic
        columns={config}
        rows={rows}
        handleClickCell={() => {}}
        withSearchField={false}
        isLoading={false}
        isPagination={false}
        pageNumber={pageNumber}
        changePageNumber={changePageNumber}
      />

      <ModalConfirmDialog
        open={openConfirmModal}
        handleAgreeButton={handleAgreeSign}
        handleDisagreeButton={handleDisagreeSign}
        contentText={t("modal_content_text:confirm_sign_task")}
      />
    </Box>
  );
};
