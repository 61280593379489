import React from "react";
import { QRCodeSVG } from "qrcode.react";
import { t } from "i18next";
import { Box, Typography, Chip, Button } from "@mui/material";

import { workingCorrectlyOptions } from "./options";
import { PATHS } from '../../../constants';

import DatePickerField from "../../components/DatePickerField";
import InputText from "../../components/InputText";
import Switcher from "../../components/Switcher";
import SelectSimple from "../../components/SelectSimple";
import BasicInformationForm from "./Sections/BasicInformationForm";
import CheckboxSimple from "../../components/CheckboxSimple";
import AutomationMessagesForm from "./Sections/AutomationMessagesForm";
import {
  DateFormatCell,
  SimpleTextCell,
  ExecutiveBlockCell,
  SelectSimpleFloor,
  RoomAdressesSelectGrouping,
  RoomPlumesSelectGrouping,
  LinkTextCell,
  JobsBlockCell,
} from "./Components";
import ExecutiveUserNameForm from "./Sections/ExecutiveUserNameForm";
import FloorSchema from "./Sections/FloorSchema";
import RegulationsForm from "./Sections/RegulationsForm";
import FloorForm from "./Sections/FloorForm";

export const formFields = {
  facilityAndFireCompartment: "facilityAndFireCompartment",

  nameEquipment: "nameEquipment", // where is it in the data
  workingCorrectly: "workingCorrectly",
  installationDate: "installationDate",
  manufactureDate: "manufactureDate",
  equipmentPassportFileName: "equipmentPassportFileName",
  id: "id",
  regulations: "regulations",
  isActive: "isActive",
  TriggerCause: "triggerCause",
  code: "code",
  name: "name",
  plume:"plume",
  integrationNumber:"integrationNumber",
  number:"number",
  reactionDateTime: "reactionDateTime",
  placement:"placement",
  executiveUserName: "executiveUserName",
  kindWorksAndComments: "kindWorksAndComments",
  task: "task",
  floorSchemeFileLink:"floorSchemeFileLink",
  roomAddresses:"roomAddresses",
  equipmentsCoordinates: "equipmentsCoordinates",

  roomAddressId:"roomAddressId",
  floor:"floor",
  plume:"plume"
};
export const placementOptions = [
  {value:"Запотолочное пространство",name:"Запотолочное пространство"},
  {value:"Подпольное пространство",name:"Подпольное пространство "},
  {value:"Основное помещение",name:"Основное помещение"}
]
export const formConfigSwitcher = [{
  name: formFields.workingCorrectly,
  label: "label:workingCorrectly",
  type: "switch",
  options: workingCorrectlyOptions,
  rules: {},
  input: (props) => <Switcher {...props} />,
}];

export const formConfigQRCode = [
  {
    name: formFields.id,
    type: "text",
    rules: {},
    input: (props) =>
      props?.value && (
        <Box>
          <iframe id={"ifmcontentstoprint"} style={{ height: "0px", width: "0px", }}></iframe>
          <Box id={"divcontents"} sx={{ paddingLeft: "16px" }}>

            <Typography className="label">{t("label:qr_code")}</Typography>
            <QRCodeSVG value={"https://lk.vdpopro.agency" + PATHS.GET_EQUIPMENT_DETAILS(props?.value)} />

          </Box>
          <Button
            className="btn btn-outlined-primary"
            variant="outlined"

            onClick={(e) => {

              var content = document.getElementById("divcontents");
              var pri = document.getElementById("ifmcontentstoprint").contentWindow;
              pri.document.open();
              pri.document.write(content.innerHTML);
              pri.document.close();
              pri.focus();
              pri.print();

            }}

            sx={{ marginTop:"10px",marginLeft:"16px",height: "46px", whiteSpace: "nowrap",minWidth:"128px !important" }}
          >
            {t("button:print")}
          </Button>
        </Box>


      ),
  },
];

export const formConfigBasicInformation = [
  {
    name: formFields.name,
    label: "label:nameEquipment",
    type: "text",
    sx: { width: "100%" },
    rules: {},
  
     input: (props) => <InputText {...props} />,
   },
   /*{
    name: formFields.plume,
    label: "label:plume",
    sx: { width: "100%" },
    type: "number",
    rules: {
      validate: (value, formValues) => value == null || value?.length >= 3,
    },
    onKeyDown:(e) => {
      if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+" || e.key === "." || e.key === ",") {
        e.preventDefault()
      }
    },
    InputProps:{ type:"number" },
 
     input: (props) => <InputText {...props} />,
   },*/
   {
    name: formFields.integrationNumber,
    label: "label:equipmentIntegrationNumber",
    type: "text",
    sx: { width: "100%" },
    rules: {},

     input: (props) => <InputText {...props} />,
   },
  {
    name: formFields.manufactureDate,
    label: "label:manufactureDate",
    type: "date",
    rules: {},
   
    input: (props) => <DatePickerField {...props} />,
  },
  {
    name: formFields.installationDate,
    label: "label:installationDate",
    type: "date",
    rules: {},

    input: (props) => <DatePickerField {...props} />,
  },
  {
    name: formFields.facilityAndFireCompartment,
    title: "title:installation_location",
    label: "label:facilityAndFireCompartment",
    type: "text",
    rules: {},
    disabled: true,
    input: (props) => <InputText {...props} />,
  },
  {
    name: formFields.number,
    label: "label:number",
    type: "text",
    rules: {},
    disabled: false,
    input: (props) => <InputText {...props} />,
  },
  {
    name: formFields.placement,
    label: "label:placement",
    type: "select-number",
    options: placementOptions,
    rules: {},
    input: (props) => <SelectSimple {...props} /> ,
  },

  {
    name: formFields.floor,
    label: "label:floor",
    type: "select-number",
    displayEmpty:true,
    rules: {},
    input: (props) => <SelectSimpleFloor {...props} /> ,
  },
  {
    name: formFields.roomAddressId,
    label: "label:roomAddressName",
    type: "select-number",
    displayEmpty:true,
    rules: {},
    input: (props) => <RoomAdressesSelectGrouping {...props} /> ,
  },
  
  {
    name: formFields.plume,
    label: "label:plume",
    type: "select-number",
    displayEmpty:true,
    rules: {},
    input: (props) => <RoomPlumesSelectGrouping {...props} /> ,
  },
  {
    name: formFields.equipmentPassportFileName,
    type: "file",
    rules: {},
    canDeleteFile: false,
    preview: false,
    input: (props) =>
      props?.value && (
        <Box>
          <Typography className="label">
            {t("label:equipmentPassport")}
          </Typography>
          <Chip label={props?.value} />
        </Box>
      ),
  },
];

export const automationMessagesColumns = [
  {
    id: formFields.reactionDateTime,
    numeric: false,
    label: t("tableHeader:reactionDateTime"),
    enableSorting: false,
    clickable: true,
    textCenter: false,
    size: "350px",
    RowCell: (props) => <DateFormatCell {...props} />,
  },
  {
    id: formFields.TriggerCause,
    numeric: false,
    label: t("tableHeader:triggerCause"),
    enableSorting: false,
    clickable: true,
    textCenter: false,
    size: "250px",
    RowCell: (props) => <SimpleTextCell {...props} />,
  },
  {
    id: formFields.name,
    numeric: false,
    label: t("tableHeader:description"),
    enableSorting: false,
    clickable: true,
    textCenter: false,
    size: "350px",
    RowCell: (props) => <SimpleTextCell {...props} />,
  },

];

export const executiveUserNameColumns = [
  {
    id: formFields.executiveUserName,
    numeric: false,
    label: t("tableHeader:executiveUserName"),
    styleTableCell: {
      verticalAlign: "top",
    },
    enableSorting: false,
    clickable: true,
    textCenter: false,
    size: "250px",
    RowCell: (props) => <ExecutiveBlockCell {...props} />,
  },
  {
    id: formFields.kindWorksAndComments,
    numeric: false,
    label: t("tableHeader:kindWorksAndComments"),
    styleTableCell: {
      verticalAlign: "top",
    },
    enableSorting: false,
    clickable: true,
    textCenter: false,
    size: "350px",
    RowCell: (props) => <JobsBlockCell {...props} />,
  },
  {
    id: formFields.task,
    numeric: false,
    label: t("tableHeader:task"),
    styleTableCell: {
      verticalAlign: "top",
    },
    enableSorting: false,
    clickable: true,
    textCenter: false,
    size: "350px",
    RowCell: (props) => <LinkTextCell {...props} />,
  },
];
export const floorSchemeInfo = [
  
  {
    Component: FloorSchema,
  },
]
export const sections = [
  {
    id: 1,
    title: "basic_information",
    Component: BasicInformationForm,
    config: formConfigBasicInformation,
  },
  {
    id: 2,
    title: "placement_on_floor",
    Component: FloorForm,
    config: floorSchemeInfo,
  },
  {
    id: 2,
    title: "regulations",
    Component: RegulationsForm,
    config: [],
  },
  {
    id: 3,
    title: "jobs",
    Component: ExecutiveUserNameForm,
    config: executiveUserNameColumns,
  },
  {
    id: 3,
    title: "automation_messages",
    Component: AutomationMessagesForm,
    config: automationMessagesColumns,
  },
];

export const getDynamicFormFields = (items) => {
  if (!items) {
    return [];
  }

  return items.map((item, i) => {
    let subName = "";

    switch (item?.type) {
      case "number":
        subName = "period";
        break;
      case "date":
        subName = "date";
        break;
      default:
        break;
    }

    return {
      name: `${[formFields.regulations]}.${i}.${subName}`,
      label: item?.name,
      type: item?.type,
      disabled: true,
      input: (props) =>
        item?.type === "checkbox" ? (
          <CheckboxSimple {...props} disabled={true} value={true} className={"wrap"} />
        ) : (
          <Box sx={{ marginTop: "4px" }}>
            {item?.type === "date" ? (
              <DatePickerField {...props} value={item.value} />
            ) : (
              <Typography sx={{ margin: "15px 0px", fontWeight:"600 !important"  }}> {`${item?.name}: ${item.value}`}</Typography>
            )}
          </Box>
        ),
    };
  });
};
