import * as React from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../hooks/redux";
import { useAction } from "../../../hooks/actions";
import { ModalNoPermission } from "../Modals/ModalNoPermission";
import { ModalSimpleNotification } from "../Modals/ModalSimpleNotification";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const ErrorRequest = () => {
  const { t } = useTranslation();
  const { isErrorRequest } = useAppSelector((state) => state.settings);
  const { setIsErrorRequest } = useAction();
  const { status } = isErrorRequest || {};

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsErrorRequest(false);
  };

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar
        open={!!isErrorRequest}
        autoHideDuration={status === 401 ? 2000 : 1000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {t(
            `notification:${
              status === 401 ? "error_unauthorized" : "error_getdata"
            }`
          )}
        </Alert>
      </Snackbar>
    </Stack>
  );
};

export const ErrorSubmit = () => {
  const { t } = useTranslation();
  const { isErrorSubmit } = useAppSelector((state) => state.settings);
  const { setIsErrorSubmit } = useAction();
  const { status } = isErrorSubmit || {};
  
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsErrorSubmit(false);
  };
  
  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar
        open={!!isErrorSubmit}
        autoHideDuration={status === 401 || status === 461 ? 5000 : 1000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
        {status === 461 ?
          isErrorSubmit?.data?.Message 
          
          :
          t(
            `notification:${
              status === 401 ? "error_unauthorized" :  "error_submit"
            }`
          )
          }
        </Alert>
      </Snackbar>
    </Stack>
  );
};

export const SuccessSubmit = () => {
  const { t } = useTranslation();
  const { isSuccessSubmit } = useAppSelector((state) => state.settings);
  const { setIsSuccessSubmit } = useAction();
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsSuccessSubmit(false);
  };

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar
        open={isSuccessSubmit}
        autoHideDuration={1000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          {t("notification:success_submit")}
        </Alert>
      </Snackbar>
    </Stack>
  );
};

export const WarningRequest = () => {
  const { t } = useTranslation();
  const { isWarning } = useAppSelector((state) => state.settings);
  const { setIsWarningRequest } = useAction();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsWarningRequest(false);
  };

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar
        open={!!isWarning}
        autoHideDuration={10000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="warning" sx={{ width: "100%" }}>
          {"Нет объектов доступных для планирования"
          }
        </Alert>
      </Snackbar>
    </Stack>
  );
};

export const WarningValidationForm = ({ isWarning }) => {
  const { t } = useTranslation();

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar
        open={isWarning}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity="warning" sx={{ width: "100%" }}>
          {t("notification:warning_validation")}
        </Alert>
      </Snackbar>
    </Stack>
  );
};

export const ErrorPermissionRequest = () => {
  const { isNoPermission } = useAppSelector((state) => state.settings);
  const { setIsNoPermissionRequest } = useAction();

  const handleClose = () => {
    setIsNoPermissionRequest(false);
  };

  return <ModalNoPermission open={isNoPermission} handleClose={handleClose} />;
};

export const ErrorSimpleNotificationModal = () => {
  const { errorNotificationParams } = useAppSelector((state) => state.settings);
  const { setErrorNotificationParams } = useAction();
  const { isOpen, title, content } = errorNotificationParams || {};

  const handleClose = () => {
    setErrorNotificationParams({
      isOpen: false,
      title: "",
      content: "",
    });
  };

  return (
    <ModalSimpleNotification
      open={isOpen}
      handleClose={handleClose}
      title={title}
      content={content}
    />
  );
};
