import React from "react";
import { Box } from "@mui/material";

import SideBar from "../Sidebar";
import Header from "../Header";
import PageContent from "../PageContent";
import './style.css'

const Layout = ({ children }) => {
  return (
    <Box
      sx={{
        display: "flex",
        backgroundColor: "var(--bgLightGrey)",
        overflowX: 'hidden',
        height: '100vh'
      }}
    >
      <SideBar />
      <div className="layout">
        <Header />
        <PageContent>{children}</PageContent>
      </div>
    </Box>
  );
};

export default Layout;
