import React from "react";

const EditBtnIcon = () => {
  return (
    <svg
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.8845 1.36326C10.1113 0.901195 9.46658 0.515894 8.91499 0.281258C8.33278 0.0335982 7.75275 -0.0924988 7.13447 0.0789634C6.5162 0.250426 6.07479 0.659791 5.69023 1.17556C5.32589 1.6642 4.95365 2.33154 4.50726 3.13183L0.655988 10.0357C0.397062 10.499 0.185554 10.8774 0.0786489 11.2838C0.0633767 11.3418 0.0502393 11.4005 0.0394032 11.46C-0.0472835 11.9362 0.0234553 12.4143 0.112919 13.0189L0.137026 13.1823C0.302167 14.3048 0.438287 15.2299 0.643588 15.9315C0.858303 16.6653 1.18837 17.3004 1.83754 17.6883C2.48671 18.0762 3.18317 18.0545 3.9045 17.8801C4.5942 17.7133 5.43641 17.3727 6.45819 16.9595L6.60699 16.8994C7.15764 16.6773 7.59303 16.5017 7.94815 16.1859C8.30326 15.8701 8.53657 15.451 8.83165 14.921L12.6828 8.01713C13.1293 7.21688 13.5015 6.54956 13.7283 5.97867C13.9675 5.37609 14.0894 4.77577 13.9237 4.13586C13.758 3.49596 13.3625 3.03911 12.8642 2.64109C12.3921 2.26401 11.7473 1.87875 10.974 1.41674L10.8845 1.36326ZM10.9935 7.9601L5.39966 4.61753L1.99772 10.716C1.62711 11.3804 1.53795 11.5603 1.50421 11.7456C1.47047 11.931 1.49026 12.1321 1.60186 12.8906C1.77799 14.0875 1.89966 14.9016 2.07017 15.4843C2.2349 16.0473 2.40476 16.2458 2.58283 16.3523C2.7609 16.4587 3.012 16.5116 3.56538 16.3778C4.13823 16.2393 4.88031 15.9413 5.96989 15.5007C6.6603 15.2215 6.83852 15.1387 6.97673 15.0157C7.11495 14.8928 7.22092 14.7229 7.59153 14.0586L10.9935 7.9601ZM11.7372 6.62313C12.0165 6.11376 12.2179 5.72383 12.3496 5.39221C12.5255 4.94929 12.5313 4.71824 12.4839 4.53515C12.4365 4.35207 12.3198 4.155 11.9535 3.86244C11.5701 3.5562 11.0136 3.22177 10.184 2.72604C9.35438 2.2303 8.79631 1.89872 8.34835 1.70817C7.9204 1.52612 7.69716 1.52006 7.52026 1.56912C7.34337 1.61818 7.15296 1.73895 6.87029 2.11807C6.65865 2.40191 6.43308 2.7774 6.14651 3.28242L11.7372 6.62313Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default EditBtnIcon;
