import React, { useMemo } from "react";
import { Box } from "@mui/material";

import { useAppSelector } from "../../../../hooks/redux";
import { useAction } from "../../../../hooks/actions";

import TableBasic from "../../../components/TableBasic";

const AutomationMessagesForm = ({ config, data }) => {
  const { pageNumber } = useAppSelector((state) => state.settings);
  const { changePageNumber } = useAction();

  const rows = useMemo(() => {
    const { equipmentMessages } = data || {};

    return equipmentMessages || [];
    // eslint-disable-next-line
  }, [data]);

  return (
    <Box
      sx={{ paddingBottom: "10px" }}
    >
      <TableBasic
        columns={config}
        rows={rows}
        handleClickCell={() => {}}
        withSearchField={false}
        isLoading={false}
        isPagination={false}
        pageNumber={pageNumber}
        changePageNumber={changePageNumber}
      />
    </Box>
  );
};

export default AutomationMessagesForm;
