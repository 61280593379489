import { Box, Input, Typography, Button } from '@mui/material'
import { TreeItem2Checkbox, TreeItem2Content, TreeItem2GroupTransition, TreeItem2Icon, TreeItem2IconContainer, TreeItem2Label, TreeItem2Provider, TreeItem2Root } from '@mui/x-tree-view'
import { useTreeItem2 } from '@mui/x-tree-view/useTreeItem2/useTreeItem2'
import React from 'react';
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import s from './AddEquipmentLite.module.css';
import { getEquipmentIcon } from '../../../../helpers/getEquipmentIcon'
import { getEquipmentIconTransparent } from '../../../../helpers/getEquipmentIconTransparent'
import Draggable from '../ImageSchema/Draggable'
import { DragOverlay } from '@dnd-kit/core'
import EditIcon from '@mui/icons-material/Edit';
import AccessibleIcon from '@mui/icons-material/Accessible';
import settingsIcon from "../../../../image/icons/settingsBtnBlack.png"
import editIcon from "../../../../image/icons/editBtnBlue.png"
import trashIcon from "../../../../image/icons/trashBtn.png"
import plusIcon from "../../../../image/icons/plusBlack.png"
const CustomTreeItem = React.forwardRef(function CustomTreeItem(props, ref) {
  const { id, minScale, scale, itemId, item, label, activeId, disabled, isSystem, children, isGroup, countNonPlaced, equipIndex, countPlaced, equipmentTypeId, equipmentId, deleteEquip, canEdit, editEquip, canDelete, hasPlacement, onAddEquipment, isForEdit, onChangeName, onOpenEditReglament, isKind, placedItems, ...other } = props;

  const {
    getRootProps,
    getContentProps,
    getIconContainerProps,
    getCheckboxProps,
    getLabelProps,
    getGroupTransitionProps,
    status,
  } = useTreeItem2({ id, itemId, children, label, disabled, rootRef: ref });
  return (
    <TreeItem2Provider itemId={itemId}>
      <TreeItem2Root {...getRootProps(other)}>
        <TreeItem2Content {...getContentProps()}>

          <Box sx={{ flexGrow: 1, display: 'flex', placeItems: "center",fontWeight: '500', justifyContent: "space-between", minHeight: isSystem ? "68px" : "44px", gap: 1 }}>
            <Box sx={{ display: 'flex', gap: "5px" }}>
              {equipIndex &&
                <Typography sx={{ alignContent: "center", fontWeight: '500' }}>{`${equipIndex}. `}</Typography>
              }
              {
                isForEdit && (
                  <Input onClick={(e) => {
                    e.stopPropagation();
                  }} onChange={(e) => onChangeName(e)} value={label} />
                ) || (
                  <TreeItem2Label sx={{ alignContent: "center", }} {...getLabelProps()} />
                )
              }
            </Box>
            {
              !!item?.number && (
                <Typography sx={{ textAlign: "center",fontWeight: '500', flexBasis: '15%' }}>{item.number}</Typography>
              )
            }
            {isGroup && <Box sx={{ display: 'flex',fontWeight: '500', }}>
              <Typography sx={{ color: "green" }}>{countPlaced}</Typography>
              <Typography>/</Typography>
              <Typography sx={{ color: "red" }}>{countNonPlaced}</Typography>
            </Box>
            }
            <Box sx={{ display: 'flex', gap: "8px", alignItems: "center", }}>
              {canEdit &&
                <Box
                  component="img"
                  src={editIcon}
                  onClick={() => editEquip()}
                  sx={{ marginRight: "" }}
                />
              }
              {canDelete &&
                <Box
                  component="img"
                  src={trashIcon}
                  onClick={() => deleteEquip()}
                  sx={{ marginRight: "" }}
                />

              }

              {equipmentTypeId && (

                !hasPlacement && !placedItems.includes(equipmentId) ? (
                  scale >= minScale ? <>
                    <Draggable
                      withOverlay
                      className="schema-field-menu-items-row-icon"
                      id={itemId}
                      style={{ marginRight: "2px" }}
                      type={"fire"}
                    >
                      <img src={getEquipmentIcon(equipmentTypeId)} />
                    </Draggable>
                    <DragOverlay>
                      {activeId ? (
                        <img src={getEquipmentIcon(equipmentTypeId)} />
                      ) : null}
                    </DragOverlay>
                  </>

                    :
                    <img src={getEquipmentIcon(equipmentTypeId)} />
                )
                  :
                  <img src={getEquipmentIconTransparent(equipmentTypeId)} />

              )}

              {
                !equipmentId && (
                  <Box
                    sx={{ display: 'flex', alignItems: 'center', height: "25px", width: "11px", justifyContent: 'center', padding: '0 7px', }}
                    onClick={(e) => {
                      onAddEquipment();
                    }}
                  >
                    <Box
                      component="img"
                      src={plusIcon}
                      sx={{height:"20px"}}
                    />
                  </Box>
                )
              }
              {/*isGroup && <Box
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}
                onClick={(e) => {
                  isGroup && onOpenEditReglament();
                  e.stopPropagation();
                  e.preventDefault();
                }}
              >
                <Box
                  component="img"
                  src={settingsIcon}
                  sx={{height:"20px"}}
                />

              </Box>*/}
              {
                !equipmentId && (
                  <TreeItem2IconContainer {...getIconContainerProps()}>
                    <TreeItem2Icon slotProps={{ collapseIcon: <AccessibleIcon /> }} status={status} />
                  </TreeItem2IconContainer>
                )
              }
            </Box>
          </Box>
        </TreeItem2Content>
        {children && <TreeItem2GroupTransition {...getGroupTransitionProps()} />}
      </TreeItem2Root>
    </TreeItem2Provider>
  );
});

export default CustomTreeItem