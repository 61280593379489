import React from "react";
import { IconButton,Box } from "@mui/material";
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import EditBtnIcon from "../icons/EditBtnIcon";

const EditButton = ({ handleClick }) => {
  return (
    <IconButton sx={{ border:0, padding: "0px" }} onClick={handleClick}>
      <Box
        className="edit-button-image"
        sx={{
          width: "30px",
          height: "30px",
      
        }}
      >
        <EditBtnIcon />
      </Box>
    </IconButton>
  )
};

export default EditButton;