import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "./constants";

export const regulationsApi = createApi({
  reducerPath: "regulations/api",
  tagTypes: ['Regulations'],
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const { token } = getState().users;
      headers.set("Content-Type", "application/json");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: (build) => ({
    getRegulationsList: build.query({
      query: ({ PageNumber, filters }) => ({
        url: `api/regulations/get-list?PageNumber=${PageNumber}${filters}`,
      }),
    }),
    getRegulation: build.query({
      query: (params) => {
        return ({
        url: `api/regulations/get-regulation`,
        params
      })},
    }),
    getStandardRegulations: build.query({
      query: (params) => {
        return ({
          url: 'api/regulations/get-standard-regulations',
          params
        })
      }
    }),
    getRegulationsPeriods: build.query({
      query: ({ periodName }) => ({
        url: `api/regulations/get-periods?periodName=${periodName}`,
      }),
      transformResponse: (baseQueryReturnValue) =>
        baseQueryReturnValue.map(({ id, description }) => ({
          value: id,
          name: description,
        })),
    }),
    updateRegulations: build.mutation({
      query: (data) => ({
        url: "/api/regulations/update-regulation",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Regulations"],
    }),
  }),
});

export const { useLazyGetRegulationsListQuery,useUpdateRegulationsMutation,useGetRegulationsPeriodsQuery,useGetRegulationQuery, useLazyGetRegulationQuery, useLazyGetStandardRegulationsQuery } = regulationsApi;
