import { Box, CircularProgress } from '@mui/material';
import { SimpleTreeView, TreeItem2 } from '@mui/x-tree-view';
import { Typography, Button, Slider } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  DndContext,
  useSensor,
  useSensors,
  MouseSensor,
  TouchSensor,
  KeyboardSensor,
  DragOverlay,
} from "@dnd-kit/core";
import Droppable from "../../Equipments/ImageSchema/Droppable";
import DateRangePicker from "../../../components/DateRangePicker/DateRangePicker";
import { getDefaultSatrtDate, getDefaultEndDate } from "../../Analytics/helpers";
import {
  useGetEquipmentKindSelectElementsQuery,
  useLazyGetEquipmentGroupsQuery, useLazyGetEquipmentsCoordinatesQuery,
  useLazyGetEquipmentKindSelectElementsQuery, useDeleteEquipmentMutation, useUpdateEquipmentsCoordinatesMutation, useLazyGetEquipmentsForTreeQuery,
} from '../../../../middleware/equipmentsApi';
import { TimelineThing } from "../../TimeTable/TimelineThing";
import { useGetCalendarPlanQuery } from "../../../../middleware/calendarPlanApi";
import {
  useLazyGetFacIntNumbersQuery,
  useLazyGetFacilitySelectElementsWithSearchQuery,
  useLazyGetFloorOptionsSelectByFacilityQuery
} from "../../../../middleware/facilitiesApi";
import CustomTreeItem from './CustomTreeItem';
import { formFields } from "./form-config";
import ArrowCancelBack from "../../../components/icons/ArrowCancelBack";
import ArrowPrevNext from "../../../components/icons/ArrowPrevNext";
import PoligonIcon from "../../../components/icons/PoligonIcon";
import FormItem from "../../../components/FormItem";
import Editpen from "../../../components/icons/Editpen";
import { ModalConfirmDialog } from "../../../components/Modals/ModalConfirmDialog";
import CloseRedIconRemove from "../../../components/icons/CloseRedIconRemove";
import { SelectSimpleObject, SelectSimpleFloor } from "./Components";
import SelectSimple from "../../../components/SelectSimple";
import { useTranslation } from "react-i18next";
import {
  getRoomAddressForRequest,
  getEquipmentsCoordinatesWithRoomId,
  getRoomAddressForScheme
} from "../../../../helpers/helper";
import { useWatch } from "react-hook-form";
import { getEquipmentIcon } from '../../../../helpers/getEquipmentIcon'

import { useAppSelector } from "../../../../hooks/redux";
import { useForm } from "react-hook-form";
import useContextMenu from '../../../../hooks/useContextMenu'
import ContextMenu from '../../../components/ContextMenu'
import { useAction } from "../../../../hooks/actions";
import Header from "../../Acts/Header";
import expandArrow from "../../../../image/icons/expandTreeArrow.png"
import collapseArrow from "../../../../image/icons/collapseTreeArrow.png"
import PageheaderNav from "../../../components/PageheaderNav";

export const config = [
  {
    id: 1,
    name: formFields.object,
    label: `label:${formFields.object}`,
    type: "select-text",
    rules: {},
    width: "320px",
    sx: { padding: "3px 0px 0px 0px" },
    isCanDisabled: true,
    input: (props) => <SelectSimpleObject {...props} />,
  },


];

const CollapseIcon = () => (
  <img src={collapseArrow} alt="collapseIcon" />
);
const ExpandIcon = () => (
  <img src={expandArrow} alt="collapseIcon" />
);

const ActsPlanning = ({ }) => {
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
    register,
    unregister,
    setValue,
    watch,
    getValues,
    trigger,
    reset,
    resetField,
  } = useForm({
    mode: "onChange",
    defaultValues: {
    },
  });
  const {

    equipmentsCoordinates
  } = useWatch({
    control,
  });
  const { setIsErrorSubmit, setIsSuccessSubmit } =
    useAction();

  const [startDate, setStartDate] = useState(getDefaultSatrtDate());
  const [endDate, setEndDate] = useState(getDefaultEndDate());
  const [treeItems, setTreeItems] = useState([]);
  const [searchObject, setsearchObject] = useState("");
  const [runOnce, setrunOnce] = useState(true);
  const [searchIntNum, setsearchIntNum] = useState("");
  const [scaleAddEquip, setscaleAddEquip] = useState(1);
  const [offsetAddEquip, setoffsetAddEquip] = useState({ x: 0, y: 0 });

  const [PageNumber, setPageNumber] = useState([]);
  const [loading, setLoading] = useState([]);
  const [focusId, setFocusId] = useState('');
  const [placedItems, setPlacedItems] = useState([])
  const [selectedFloor, setselectedFloor] = useState()
  const [isFocusID, setisFocusID] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);
  const [selectedTreeItemId, setselectedTreeItemId] = useState(null);
  const [selectedequipId, setselectedequipId] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [expandedItems, setExpandedItems] = useState({});
  const [transformCanvasData, setTransformCanvasData] = useState({
    x: 0,
    y: 0
  })

  const handleExpandedItemsChange = (event, itemIds) => {
    setExpandedItems(prevState => ({
      ...prevState,
      [facilityId]: itemIds
    }));
    localStorage.setItem("currentAddEquipTreePosition", JSON.stringify(expandedItems))
  };

  const facilityId = watch(formFields.object);
  const {
    data: calendarPlan,
    error: errorCalendarPlan,
    status,
  } = useGetCalendarPlanQuery({ startDate, endDate }, { refetchOnMountOrArgChange: true });
  const [updateEquipmentCoordinates, {
    isSuccess: isSuccessUpdateEquipment,
    isLoading: isLoadingUpdateEquipment,
    isError: isErrorUpdateEquipment
  }] = useUpdateEquipmentsCoordinatesMutation();
  const [
    getfacIntNum,
    { isError: isErrorgetfacIntNum, isLoading: isLoadinggetfacIntNum },
  ] = useLazyGetFacIntNumbersQuery();
  const [getEquipmentKinds] = useLazyGetEquipmentKindSelectElementsQuery();
  const [deleteEquipment,
    {
      error: errorDelete,
      isSuccess: isSuccessDelete,
      isLoading: loadingDelete,
    },] = useDeleteEquipmentMutation();
  const [getEquipmentGroups] = useLazyGetEquipmentGroupsQuery();
  const [getEquipmentsForTree] = useLazyGetEquipmentsForTreeQuery();
  const [
    getFacilitySelectElems,
    { isError: isErrorgetFacilitySelectElems, isLoading: isLoadinggetFacilitySelectElems },
  ] = useLazyGetFacilitySelectElementsWithSearchQuery();

  const [
    getFloorOptions,
    { isError: isErrorGetFloorOptions, isLoading: isLoadingGetFloorOptions },
  ] = useLazyGetFloorOptionsSelectByFacilityQuery();


  useEffect(() => {
    setIsErrorSubmit(errorDelete);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorDelete]);

  useEffect(() => {
    setIsSuccessSubmit(isSuccessDelete);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessDelete]);
  const onChangeName = (e, treeIdx, groupId, equipmentIdx) => {
    setTreeItems(prev => {
      const newResult = [...prev]

      newResult[treeIdx].equipments[groupId][equipmentIdx].name = e.target.value

      return newResult
    })
  }

  const onToggleTree = (id, itemType, rawId) => {

    if (itemType === 'system') {
      getEquipmentKinds({ params: '', useShortTitle: true, protectionObjectId: facilityId, hasEquipmentOnly: true }).then(
        ({ data, isSuccess }) => {
          setTreeItems(data)
        }
      );
    }
    if (itemType === 'kind') {
      const systemId = rawId.split('_')[3];

      getEquipmentGroups(`protectionObjectId=${facilityId}&equipmentKindId=${systemId}&equipmentTypeId=${id}&calcCount=${true}`).then(res => {
        if (res.data) {
          setTreeItems(prev => prev.map(item => {
            return (String(item.id) === String(systemId) ? {
              ...item,
              equipmentTypes: item.equipmentTypes.map(eq => String(eq.id) === String(id) ? ({
                ...eq,
                groups: res.data
              }) : eq)
            } : item)
          }));
        }
      });
    }
    if (itemType === 'group') {
      const systemId = rawId.split('_')[5];
      const kindId = rawId.split('_')[3];
      getEquipmentsForTree({
        equipmentGroupId: id,
        pageNumber: 0,
      }).then(res => {
        if (res.data?.data) {

          const findIndex = PageNumber.findIndex(obj => obj.id === id)
          let buffArr = PageNumber
          if (findIndex !== -1) {
            buffArr[findIndex].pageNumber = 0;
            setPageNumber(prev => buffArr)
          } else {
            setPageNumber(prev => [...prev, { id: id, pageNumber: 0 }])
          }
          setTreeItems(prev => prev.map(item => {
            return (String(item.id) === String(systemId) ? {
              ...item,
              equipmentTypes: item.equipmentTypes.map(eq => {
                return String(eq.id) === String(kindId) ? ({
                  ...eq,
                  equipments: {
                    ...eq.equipments,
                    [id]: { equipData: res.data.data, hasMore: res.data.hasMoreData }
                  }
                }) : eq
              })
            } : item)
          }))
        }
      });
    }

  };

  const handleItemExpansionToggle = (event, id, isSelected) => {
    console.log("333", id, isSelected)
    if (id.includes('equipment')) {
      setFocusId(id.split('_')[3])

      console.log("ddd", id.split('_')[1])
      if (id.split('_')[1] != null && id.split('_')[1] != "null") {
        setisFocusID(true)
      }

    }
    if (isSelected && !id.includes('equipment') && !id.split('_')[0].includes('system')) {
      setFocusId('')

      const itemType = id.includes('kind') ? !id.includes('group') ? 'kind' : 'group' : "system";
      onToggleTree(id.split('_')[1], itemType, id);
    }
  };

  useEffect(() => {
    getFacilitySelectElems({ name: searchObject }).then(
      ({ data, isSuccess }) => {
        if (!!data?.length) {
          if (runOnce) {
            if (localStorage.getItem("currentAddEquipObject")) {

              const parsedCurrPlan = JSON.parse(localStorage.getItem("currentAddEquipObject"))
              setValue(formFields.object, parsedCurrPlan.object)
            } else {
              setValue(formFields.object, data[0].id)
            }
            setrunOnce(false)
          }

        }
      }
    );
  }, [searchObject])

  useEffect(() => {
    if (facilityId) {
      getfacIntNum({ protectionObjectId: facilityId, number: searchIntNum })
    }
  }, [searchIntNum])


  useEffect(() => {
    if (facilityId) {

      getEquipmentKinds({ params: '', useShortTitle: true, protectionObjectId: facilityId, hasEquipmentOnly: true }).then(
        ({ data, isSuccess }) => {
          setTreeItems(data)
        }
      );

      getfacIntNum({ protectionObjectId: facilityId, number: searchIntNum })
    }

  }, [facilityId, getFloorOptions, setValue]);



  const mouseSensor = useSensor(MouseSensor);
  const touchSensor = useSensor(TouchSensor);
  const keyboardSensor = useSensor(KeyboardSensor);
  const $container = useRef(null);
  const canvasRef = useRef(null);
  const imageRef = useRef(null);
  const sensors = useSensors(mouseSensor, touchSensor, keyboardSensor);
  const { SCHEMA_CANVAS1, openSidebar } = useAppSelector(state => state.settings);
  let wwwidth = openSidebar ? window.innerWidth - 317 : window.innerWidth - 96
  const [activeId, setActiveId] = useState('');
  const { clicked, setClicked, points, setPoints } = useContextMenu()
  const [contextItem, setContextItem] = useState('')
  const [heightContainer, setheightContainer] = useState(wwwidth >= 1813 ? window.innerHeight - 394 : window.innerHeight - 464);
  const treeWidth = $container.current?.offsetWidth * 0.35 > 450 ? $container.current?.offsetWidth * 0.35 : 450
  const [widthContainer, setwidthContainer] = useState(openSidebar ? window.innerWidth - 300 - treeWidth : window.innerWidth - treeWidth);
  const [widthWorkplace, setwidthWorkplace] = useState(openSidebar ? window.innerWidth - 625 : window.innerWidth - 315);
  const [heightWorkplace, setheightWorkplace] = useState(wwwidth >= 1813 ? window.innerHeight - 370 : window.innerHeight - 370);


  const refreshTree = async (isSystem, refreshScheme) => {
    const expandedItemsCopy = expandedItems[facilityId]

    if (isSystem) {
      getEquipmentKinds({ params: '', useShortTitle: true, protectionObjectId: facilityId, hasEquipmentOnly: true }).then(
        ({ data, isSuccess }) => {
          setTreeItems(data)
        }
      );
    }
    expandedItemsCopy?.map((item) =>
      handleItemExpansionToggle(false, item, true)
    )
  }
  const onDelete = (e) => {
    var equipmentsCoordinateFound = equipmentsCoordinates.find(el => el.id === contextItem.id);
    if (equipmentsCoordinateFound !== null) {
      equipmentsCoordinateFound.floorInfoId = null;
      equipmentsCoordinateFound.floorSchemeCoordinateX = null;
      equipmentsCoordinateFound.floorSchemeCoordinateY = null;
      equipmentsCoordinateFound.roomAddressId = null;
    }
    setValue(formFields.equipmentsCoordinates, equipmentsCoordinates.filter(el => el.id != contextItem.id));
    setContextItem('');
    setClicked(false);
    let arr = []
    arr.push(equipmentsCoordinateFound)
    updateEquipmentCoordinates(arr).then(() => {

      refreshTree(false, false)
    });

  }

  const contextMenuData = [
    {
      label: t("title:deleteEquipmentFromScheme"),
      onClick: onDelete,
      id: 1
    }
  ]

  const handleContext = (e, { id }) => {
    setContextItem({ id })
    setPoints({
      x: e.evt.pageX,
      y: e.evt.pageY
    })
    setClicked(true)

  }

  useEffect(() => {

    const treeWidth = $container.current?.offsetWidth * 0.35 > 450 ? $container.current?.offsetWidth * 0.35 * window.devicePixelRatio : 450
    console.log("$container.current?.offsetWidth * 0.35 ", treeWidth, window.innerWidth)
    const containerWidth = $container.current?.offsetWidth;
    let change = 0
    if (openSidebar)
      change += 317
    else
      change += 110
    change += treeWidth + 100 * window.devicePixelRatio

    setwidthContainer(() => (window.innerWidth - change) * window.devicePixelRatio);
    setwidthWorkplace(() => (window.innerWidth - change) * window.devicePixelRatio)
    window.addEventListener("resize", function () {
      let change = 0
      if (openSidebar)
        change += 317
      else
        change += 110
      change += treeWidth + 100 * window.devicePixelRatio
      let windowW = openSidebar ? window.innerWidth - 317 : window.innerWidth - 96
      setwidthContainer(() => (window.innerWidth - change) * window.devicePixelRatio);
      setwidthWorkplace(() => (window.innerWidth - change) * window.devicePixelRatio)
      setheightContainer(() => windowW >= 1813 ? window.innerHeight - 464 : window.innerHeight - 464)
      setheightWorkplace(() => windowW >= 1813 ? window.innerHeight - 464 : window.innerHeight - 464)
    });
  }, [openSidebar, window.devicePixelRatio]);

  const {
    width: screenRelatedWidth,
    height: screenRelatedHeight,
    scale,
  } = useMemo(() => {
    let width = 0
    let height = 0

    if (SCHEMA_CANVAS1.HEIGHT > SCHEMA_CANVAS1.WIDTH) {
      height = heightContainer
      width = heightContainer / (SCHEMA_CANVAS1.HEIGHT / SCHEMA_CANVAS1.WIDTH)
    } else {
      width = widthContainer
      height = widthContainer / (SCHEMA_CANVAS1.WIDTH / SCHEMA_CANVAS1.HEIGHT)
      if ((height > window.innerHeight - 464 && wwwidth >= 1813) || (height > window.innerHeight - 394 && wwwidth < 1813)) {
        height = heightContainer
        width = heightContainer / (SCHEMA_CANVAS1.HEIGHT / SCHEMA_CANVAS1.WIDTH)
      }
    }
    return SCHEMA_CANVAS1.WIDTH
      ? {
        width,
        height,
        scale: {
          x: width / SCHEMA_CANVAS1.WIDTH,
          y: height / SCHEMA_CANVAS1.HEIGHT,
        },
      }
      : {};
  }, [SCHEMA_CANVAS1, heightContainer, widthContainer]);

  const toggleHasRoom = (event) => {
    const dataFromId = event.active.id.split('_')
    const [sysIdx, kindIdx, gIdx, eqId, eqIdx] = [dataFromId[5], dataFromId[7], dataFromId[9], dataFromId[1], dataFromId[11]]

    setTreeItems(prev => {
      const newItems = [...prev];
      if (newItems) {
        if (newItems[sysIdx]?.equipmentTypes) {
          if (
            newItems?.[sysIdx]?.equipmentTypes?.[kindIdx]?.equipments
          ) {
            if (newItems[sysIdx]?.equipmentTypes[kindIdx]?.groups[gIdx]) {
              const groupId = newItems[sysIdx]?.equipmentTypes[kindIdx]?.groups[gIdx]?.value;
              if (
                newItems[sysIdx].equipmentTypes[kindIdx]?.equipments[groupId] &&
                newItems[sysIdx].equipmentTypes[kindIdx]?.equipments[groupId][eqIdx]
              ) {
                const updatedEquipment = {
                  ...newItems[sysIdx].equipmentTypes[kindIdx].equipments[groupId][eqIdx],
                  hasRoom: true
                };
                newItems[sysIdx].equipmentTypes[kindIdx].equipments[groupId] = [
                  ...newItems[sysIdx].equipmentTypes[kindIdx].equipments[groupId]
                ];
                newItems[sysIdx].equipmentTypes[kindIdx].equipments[groupId][eqIdx] = updatedEquipment;
              }
            }
          }
        }

      }

      return newItems;
    })
  }
  const handleChangeSliderCommitted = (event, newValue) => {
    const canvas = canvasRef.current;


    const pointTo = {
      x: widthWorkplace / 2 / scaleAddEquip - canvas.x() / scaleAddEquip,
      y: heightWorkplace / 2 / scaleAddEquip - canvas.y() / scaleAddEquip,
    };

    setscaleAddEquip(newValue);
    setoffsetAddEquip({
      x: -(pointTo.x - screenRelatedWidth / 2 / newValue) * newValue,
      y: -(pointTo.y - screenRelatedHeight / 2 / newValue) * newValue,
    });
    // stage.position({ x: -stage.x() * scaleCanvas,y: -stage.y() * scaleCanvas});

  };
  function handleDragEnd(event) {


  }
  const TreeItemsfetchData = (groupId, systemId, kindId, foundEquipment) => {
    if (loading.find((obj) => obj.id == groupId)) {
      const findObj = loading.map(item => {
        if (item.id === groupId) {
          return { ...item, loading: true };
        } else {
          return item;
        }
      });
      setLoading(prev => findObj)
    }
    setLoading(prev => [...prev, { id: groupId, loading: true }])

    setTimeout(() => {
      getEquipmentsForTree({
        equipmentGroupId: groupId,
        pageNumber: PageNumber.find((item) => item.id == groupId).pageNumber + 1,
      }).then(res => {
        if (res.data?.data) {
          const findObj = PageNumber.map(item => {
            if (item.id === groupId) {
              return { ...item, pageNumber: item.pageNumber + 1 };
            } else {
              return item;
            }
          });
          setPageNumber(prev => findObj)
          setTreeItems(prev => prev.map(item => {
            return (String(item.id) === String(systemId) ? {
              ...item,
              equipmentTypes: item.equipmentTypes.map(eq => {
                return String(eq.id) === String(kindId) ? ({
                  ...eq,
                  equipments: {
                    ...eq.equipments,
                    [groupId]: { hasMore: res.data.hasMoreData, equipData: [...foundEquipment.equipData, ...res.data.data] }
                  }
                }) : eq
              })
            } : item)
          }))
          const findObjLoad = loading.map(item => {
            if (item.id === groupId) {
              return { ...item, loading: false };
            } else {
              return item;
            }
          });
          setLoading(prev => findObjLoad)
        }
      });
    }, 1000);

  }
  const handleScroll = (e, groupId, systemId, kindId) => {
    const foundSystem = treeItems.find((item) => item.id == systemId)

    const foundKind = foundSystem != null ? foundSystem.equipmentTypes.find((item) => item.id == kindId) : null

    const foundEquipment = foundKind != null ? foundKind.equipments[groupId] : null
    const bottomScrollRange = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight;
    if ((bottomScrollRange > -1 && bottomScrollRange < 10) &&
      (!loading.find((item) => item.id == groupId) ||
        !loading.find((item) => item.id == groupId).loading) && foundEquipment?.hasMore) {
      TreeItemsfetchData(groupId, systemId, kindId, foundEquipment)
    }
  };
  const openConfirmToDeleteEquip = (equipID, equipItemId) => {
    setOpenModal(true)
    setselectedequipId(equipID)
    setselectedTreeItemId(equipItemId)
  }
  const habdleDisagreeReplace = () => {
    setOpenModal(false)
    setselectedequipId(null)
  }
  const openEditModal = (equipID) => {
    setIsModalEditOpen(true)
    setselectedequipId(equipID)
  }
  const handleSubmitLite = (data) => {

  }
  const itemsNav = [
    "plume_and_address",
    "func_tests",
    "documents",
    "planning"
  ]
  return (

    <Box ref={$container} sx={{ height: "calc( 100vh - 300px)" }}>
      <Header nameForm="planning" />
      <PageheaderNav itemsNav={itemsNav} />
      {clicked &&
        <ContextMenu
          data={contextMenuData}
          position={points}
        />
        
      }
      <Box sx={{mt:"50px"}}><Typography sx={{ fontWeight: 600, fontSize: "26px", textAlign: "center" }}> Нет оборудования для распределения</Typography></Box>
      {/* <form id="myform" onSubmit={handleSubmit(handleSubmitLite)}>
        <DndContext
          onDragStart={(event) => {
            setActiveId(event.active.id)
          }}

          onDragEnd={handleDragEnd}
          className="schema"
          sensors={sensors}
        >
          <Box>
            
            <PageheaderNav itemsNav={itemsNav} />

            <Box className={`addEquipment-wrapper-buttons`} sx={{ justifyContent: "flex-start", padding: "24px 32px" }}>

              {config.map(({ id, name, label, type, isCanDisabled, ...rest }) => (
                <FormItem
                  key={id}
                  name={name}
                  control={control}
                  label={label}
                  type={type}

                  searchObject={searchObject}
                  setsearchObject={setsearchObject}
                  input={(props) => <SelectSimple sx={{ marginRight: "20px" }} {...props} />}
                  {...rest}
                />
              ))}
              <DateRangePicker
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
              />
            </Box>
          </Box>
          <Box sx={{ display: 'flex', }}>
            <Box>
              <Typography sx={{ padding: "24px 0px 34px 0px", marginRight: "20px", fontSize: "20px", }}>Системы</Typography>
              <Box sx={{ maxHeight: 'calc(100vh - 320px)', maxWidth: "459px", overflowY: 'scroll' }}>

                <SimpleTreeView
                  expandedItems={expandedItems[facilityId]}
                  onExpandedItemsChange={handleExpandedItemsChange}
                  onItemExpansionToggle={handleItemExpansionToggle}
                  onItemSelectionToggle={handleItemExpansionToggle}
                  slots={{
                    collapseIcon: CollapseIcon,
                    expandIcon: ExpandIcon
                  }}
                >
                  {
                    !!treeItems?.length && treeItems.map((system, sysIdx) => (
                      <CustomTreeItem
                        isSystem
                        key={`system_${system.id}`}
                        label={system.title}
                        itemId={`system_${system.id}`}
                      >

                        {
                          !!system?.equipmentTypes?.length && system.equipmentTypes.map((el, index) => (
                            <CustomTreeItem
                              isKind
                              key={`kind_${el.id}_system_${system.id}`}
                              label={el.name}
                              itemId={`kind_${el.id}_system_${system.id}`}
                            >
                              {
                                el.groups && el.groups.map((g, gIdx) => (
                                  <CustomTreeItem
                                    isGroup
                                    key={g.value}
                                    countPlaced={g.countPlaced}
                                    countNonPlaced={g.countNonPlaced}
                                    label={g.name}
                                    itemId={`group_${g.value}_kindId_${el.id}_system_${system.id}`}
                                  >
                                    <Box sx={{ maxHeight: "250px", overflowY: "auto" }} onScroll={(e) => handleScroll(e, g.value, system.id, el.id)}>
                                      {el.equipments && el.equipments[g.value] && el.equipments[g.value].equipData.map((e, eqIdx) => (
                                        <React.Fragment key={`equipment_${e.id}_eqTypeId_${e.typeId}`}>
                                          <CustomTreeItem scale={scaleAddEquip} equipmentId={e.id} equipIndex={eqIdx + 1} activeId={activeId} placedItems={placedItems} item={e} hasPlacement={e.hasPlacement} equipmentTypeId={e.typeId} onChangeName={(e) => onChangeName(e, index, g.value, eqIdx)} isForEdit={e.isForEdit} key={e.id} label={e.name} itemId={`floor_${e.floorInfoId}_equipment_${e.id}_eqTypeId_${e.typeId}_sysIdx_${sysIdx}_kindIdx_${index}_gIdx_${gIdx}_eqIdx_${eqIdx}`} />

                                        </React.Fragment>
                                      )) || <CircularProgress sx={{ marginTop: 1 }} color="primary" size={24} />}
                                      {loading.find((item) => item.id == g.value)?.loading && (
                                        <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                          <CircularProgress />
                                        </Box>
                                      )}
                                    </Box>
                                  </CustomTreeItem>
                                )) || <CircularProgress sx={{ marginTop: 1 }} color="primary" size={24} />
                              }
                            </CustomTreeItem>
                          ))
                        }
                      </CustomTreeItem>
                    ))
                  }
                </SimpleTreeView>
              </Box>
            </Box>

            <Droppable>
              <Box sx={{ marginTop: "20px" }}>
                <TimelineThing calendarPlan={calendarPlan} />
              </Box>
            </Droppable>
          </Box>
        </DndContext>
      </form>
*/}
    </Box>
  );
};

export default ActsPlanning;
