import React, { useMemo, useEffect, useRef, useState } from "react";
import { Box, Typography, Tooltip, Button, IconButton } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { ArrowIconLeftBlue } from "../../../components/icons/ArrowIcon";
import Switcher from "../../../components/Switcher";
import CloseIcon from "@mui/icons-material/Close";
import dayjs from "dayjs";
import Fade from "@mui/material/Fade";
import closeBtn from "../../../../image/icons/closeInputFile.png"
export const ListEvents = ({
  handleListItemClick,
  selectedListItem,
  logsMessagess,
  openSidebar,
  toggleList,
  width,
  scale,
  height,
  StartTO,
  handleTO,
  setopenEventSide,
}) => {
  const dateFormat = "DD.MM.YYYY HH:mm";

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Box
          sx={{
            width: window.innerWidth <= 600 ? "100%" : "200px",
            marginRight: window.innerWidth <= 600 ? "0px" : "20px",
            border: "0px solid #f4f4f4",
            backgroundColor: "transparent",
          }}
        >
          <Box
            sx={{
              width: window.innerWidth <= 600 ? "100%" : "200px",
              border: "1px solid #bfbfc2",
              display: "flex",
              placeContent: "space-between",
              backgroundColor: "#f8f9fd",
              borderRadius: "16px",
              borderBottomRightRadius:"0px",
              borderBottomLeftRadius:"0px",
              height: "54px",
            
            }}
          >
            <Box sx={{ display: "flex", height: "30px", alignSelf: "center" }}>
              <Typography
                sx={{
                  textAlign: "center",
                  marginLeft: "20px",
                  placeSelf: "center",
                  fontSize: 16,
                  fontFamily: "var(--INTER)",
                }}
              >
                События
              </Typography>
              <Box
                sx={{ width: "48px", placeSelf: "center", marginLeft: "20px" }}
              >
                <Switcher
                  value={StartTO}
                  onChange={handleTO}
                  sx={{}}
                  options={[
                    { status: true, name: "" },
                    { status: false, name: "" },
                  ]}
                />
              </Box>
              <IconButton
                sx={{ marginLeft: "12px" }}
                onClick={() => setopenEventSide(false)}
              >
                <Box
                  component="img"
                  src={closeBtn}
                  sx={{  }}
                />
              </IconButton>
            </Box>
          </Box>
        </Box>
        <List
          sx={{
            width: window.innerWidth <= 600 ? "100%" : "200px",
            maxHeight: "100%",
            overflowY: "auto",
            overflowX: "hidden",
            borderRadius: "4px",
          }}
        >
          {logsMessagess && logsMessagess.length > 0 ? (
            logsMessagess?.map((item) => {
              return (
                <Tooltip
                  placement={"right"}
                  title={`Дата: ${dayjs(item?.reactionDateTime).format(dateFormat).split(" ")}`}
                >
                  <ListItem
                    sx={{
                      border:
                        selectedListItem?.id == item?.id
                          ? `3px solid black`
                          : item?.borderColor,
                      backgroundColor: item?.color,
                    }}
                    component="div"
                    disablePadding
                    onClick={handleListItemClick(
                      item,
                      item?.roomAddressId ? false : true
                    )}
                  >
                    <ListItemButton
                      onMouseEnter={(e) => {
                        if (item?.roomAddressName != "Зона не определена") {
                          e.target.style.cursor = "pointer";
                        }
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.cursor = "default";
                      }}
                    >
                      <Typography
                        sx={{
                          width: window.innerWidth <= 600 ? "90%" : "180px",
                        }}
                      >{`${item?.messageCodeName},${item?.roomAddressPlume}`}</Typography>
                    </ListItemButton>
                  </ListItem>
                </Tooltip>
              );
            })
          ) : (
            <Typography sx={{ fontSize: "24px", textAlign: "center" }}>
              Нет событий
            </Typography>
          )}
        </List>
      </div>
    </>
  );
};
