import React from "react";
import { t } from "i18next";
import { Box } from "@mui/material";
import { formFields } from "./table-config";
import { SimpleTextWithTooltipCell } from "./Components";
import { SimpleHeader } from "../TasksList/Components";
import { SearchInbuilt } from "../../components/SearchInbuilt";
import FormItem from "../../components/FormItem";
import DatePickerInbuild from "../../components/DatePickerInbuild";
import {
  SignatureCell,
  SimpleTextWithTooltipCellDetailes,
  DateFormatCell,
} from "./Components";
import { SelectSimpleObject } from "../EquipmentList/Components";
export const getColumns = ({
  control,
  columnsFromStorage,
  jobName,
  setJobName,
  searchworkScopeName,
  setsearchworkScopeName,
  searchworkScopeDetails,
  setsearchworkScopeDetails,
  equipName,
  setequipName,
  roomPlume,
  setroomPlume,
  executiveUser,
  setexecutiveUser,
  responsibleUser,
  setresponsibleUser,
}) =>
  [
    {
      id: formFields.completedAt,
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:dateAndTime"),
      size: "160px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.completedAt],
      RowCell: (props) => <DateFormatCell {...props} type="dateTime" />,
      HeaderCell: () => (
        <>
          <FormItem
            control={control}
            placeholder={"с"}
            name={`${formFields.searchParams}.CompletedDateRange.MinValue`}
            sx={{ padding: "0px 0 1px 0px" }}
            input={(props) => (
              <DatePickerInbuild
                className="select_in_built small-light"
                {...props}
              />
            )}
          />
          <FormItem
            control={control}
            placeholder={"по"}
            name={`${formFields.searchParams}.CompletedDateRange.MaxValue`}
            sx={{ padding: "0px 0 1px 0px" }}
            input={(props) => (
              <DatePickerInbuild
                className="select_in_built small-light"
                {...props}
              />
            )}
          />
        </>
      ),
    },
    {
      id: formFields.facilityName,
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:facilityName"),
      size: "164px",
      clickable: false,
      HeaderCell: () => (
        <FormItem
          control={control}
          label={t("label:object")}
          name={`${formFields.searchParams}.${formFields.FacilityId}`}
          sx={{
            padding: "13px 0 13px 16px",
          }}
          input={(props) => (
            <SelectSimpleObject
              {...props}
              IsTableheader={true}
              className="select_in_built small-light"
              sx={{ width: "100%" }}
            />
          )}
        />
      ),
      isVisible: columnsFromStorage[formFields.facilityName],
    },
    {
      id: formFields.jobName,
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:task"),
      size: "200px",
      clickable: false,
      HeaderCell: () => (
        <FormItem
          control={control}
          name={`${formFields.searchParams}.${formFields.TaskName}`}
          placeholder={t("tableHeader:task")}
          sx={{
            padding: "9px 0 9px 16px",
            margin: "0",
            width: "100%",
          }}
          input={(props) => (
            <SearchInbuilt
              className="select_in_built small-light"
              searchValue={jobName}
              changeSearchValue={setJobName}
              {...props}
            />
          )}
        />
      ),
      isVisible: columnsFromStorage[formFields.jobName],
    },
    {
      id: formFields.workScopeName,
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:kindWorks"),
      size: "228px",
      isVisible: columnsFromStorage[formFields.workScopeName],
      color: "#4339F2",
      HeaderCell: () => (
        <FormItem
          control={control}
          name={`${formFields.searchParams}.${formFields.workScopeName}`}
          sx={{
            padding: "9px 0 9px 16px",
            width: "100%",
          }}
          input={(props) => (
            <SearchInbuilt
              placeholder={t("tableHeader:kindWorks")}
              className="select_in_built small-light"
              searchValue={searchworkScopeName}
              changeSearchValue={setsearchworkScopeName}
              {...props}
            />
          )}
        />
      ),
      RowCell: (props) => <SimpleTextWithTooltipCell {...props} />,
    },
    {
      id: formFields.workScopeDetails,
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:workScopeDetails"),
      size: "228px",
      isVisible: columnsFromStorage[formFields.workScopeDetails],
      color: "#4339F2",
      HeaderCell: () => (
        <Box>
          {/* <SimpleHeader name={t("tableHeader:workScopeDetails")} /> */}
          <FormItem
            control={control}
            name={`${formFields.searchParams}.${formFields.workScopeDetails}`}
            sx={{
              padding: "9px 0 9px 16px",
              width: "100%",
            }}
            input={(props) => (
              <SearchInbuilt
                placeholder={t("tableHeader:workScopeDetails")}
                className="select_in_built small-light"
                searchValue={searchworkScopeDetails}
                changeSearchValue={setsearchworkScopeDetails}
                {...props}
              />
            )}
          />
        </Box>
      ),
      RowCell: (props) => <SimpleTextWithTooltipCellDetailes {...props} />,
    },
    {
      id: formFields.equipmentName,
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:equipment"),
      size: "440px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.equipmentName],
      HeaderCell: () => (
        <FormItem
          control={control}
          placeholder={t("tableHeader:equipment")}
          name={`${formFields.searchParams}.EquipmentNameAndNumber`}
          sx={{
            padding: "9px 0 9px 16px",
            width: "450px",
          }}
          input={(props) => (
            <SearchInbuilt
              className="select_in_built small-light"
              searchValue={equipName}
              changeSearchValue={setequipName}
              {...props}
            />
          )}
        />
      ),
    },
    {
      id: formFields.roomAddressPlume,
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:roomAddressPlume"),
      size: "300px",
      clickable: false,
      HeaderCell: () => (
        <>
          <FormItem
            control={control}
            name={`${formFields.searchParams}.RoomPlume`}
            sx={{
              padding: "9px 0 9px 16px",
              width: "300px",
            }}
            input={(props) => (
              <SearchInbuilt
                placeholder={t("tableHeader:roomAddressPlume")}
                className="select_in_built small-light"
                searchValue={roomPlume}
                changeSearchValue={setroomPlume}
                {...props}
              />
            )}
          />
        </>
      ),
      isVisible: columnsFromStorage[formFields.roomAddressPlume],
    },
    {
      id: formFields.executiveUser,
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:executive"),
      size: "280px",
      clickable: false,
      HeaderCell: () => (
        <>
          <FormItem
            control={control}
            name={`${formFields.searchParams}.ExecutiveUser`}
            sx={{
              padding: "9px 0 9px 16px",
              width: "280px",
            }}
            input={(props) => (
              <SearchInbuilt
                placeholder={t("tableHeader:executive")}
                className="select_in_built small-light"
                searchValue={executiveUser}
                changeSearchValue={setexecutiveUser}
                {...props}
              />
            )}
          />
        </>
      ),
      isVisible: columnsFromStorage[formFields.executiveUser],
    },
    {
      id: formFields.executiveUserSignature,
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:executiveUserSignature"),
      size: "160px",
      clickable: false,
      RowCell: (props) => <SignatureCell {...props} />,
      HeaderCell: () => (
        <SimpleHeader name={t("tableHeader:executiveUserSignature")} />
      ),
      isVisible: columnsFromStorage[formFields.executiveUserSignature],
    },
    {
      id: formFields.responsibleCustomer,
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:responsibleCustomer"),
      size: "250px",
      clickable: false,
      HeaderCell: () => (
        <>
          <FormItem
            control={control}
            name={`${formFields.searchParams}.ResponsibleCustomerUser`}
            sx={{
              padding: "9px 0 9px 16px",
              width: "250px",
            }}
            input={(props) => (
              <SearchInbuilt
                placeholder={t("tableHeader:responsibleCustomer")}
                className="select_in_built small-light"
                searchValue={responsibleUser}
                changeSearchValue={setresponsibleUser}
                {...props}
              />
            )}
          />
        </>
      ),
      isVisible: columnsFromStorage[formFields.responsibleCustomer],
    },
    {
      id: formFields.responsibleCustomerSignature,
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:responsibleCustomerSignature"),
      size: "135px",
      clickable: false,
      RowCell: (props) => <SignatureCell {...props} />,
      HeaderCell: () => (
        <div
          style={{
            color: "var(--header)",
            fontSize: "16px",
          }}
        >
          <SimpleHeader name={t("tableHeader:responsibleCustomerSignature")} />
        </div>
      ),
      isVisible: columnsFromStorage[formFields.responsibleCustomerSignature],
    },
    {
      id: formFields.taskNumber,
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:task"),
      size: "100px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.taskNumber],
    },
  ].filter(({ isVisible }) => isVisible);
