import React, { useMemo } from "react";
import { Box, Typography } from "@mui/material";

import { formFields, getDynamicFormFields } from "../form-config";
import { getDataToChart } from "../../../../helpers/helper";
import FormItem from "../../../components/FormItem";
import { useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import TaskStateList from "../../../components/TaskStateList/TaskStateList";

const BasicInformationForm = ({ control, errors, config, setValue, data }) => {
  const { t } = useTranslation();

  const dataToolTip = useMemo(() =>
    data ? getDataToChart({
      jobChart: data?.jobChart,
      action: `?equipmentId=${data?.id}&equipmentName=${data?.name}`,
    }) : [],
    [data],
  );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "flex-start",
          alignItems: "flex-end",
          columnGap: "15px",
        }}
      >
        {config.map(({ name, title, label, input, Component, flexBasis, ...rest }, i) => (
          Component
            ? <Component
              key={i}
              data={data}
              control={control}
              setValue={setValue}
            />
            : ((name != "plume" && name != "integrationNumber") || ((name == "integrationNumber" || name == "plume") && data?.hasIntegration)) && (
              <Box key={i} className="resizing_basic_forms">
                {!!title && <Typography className="label">{t(title)}</Typography>}
                <FormItem
                  control={control}
                  errors={errors}
                  name={name}
                  label={label}
                  input={input}
                  {...rest}
                />
              </Box>
            )
        ))}

      </Box>
      <TaskStateList
        data={dataToolTip}
        lineHeight="24px"
        height="8px"
        width="8px"
        fontWeight="500"
        isShowAlternativeName={false}
      />
    </>
  );
};

export default BasicInformationForm;
